import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, action, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { User as UserModel, UserStore } from 'store/User';
import { Button, Form, Image, Icon, Popup } from 'semantic-ui-react';
import Scrollbars from 'react-custom-scrollbars';
import defaultAvatar from 'image/default_avatar.png';
import { theme } from 'styles';
import Keypress from 'react-keypress';
import Textarea from 'react-textarea-autosize';
import ReactDOM from 'react-dom';
import Nl2Br from 'component/Nl2Br';
import AutofocusInput from 'component/AutofocusInput';
import { subscribe } from 'store/Base';


const CHAR_CODE_ENTER = 13;


function mix(color1, color2, amount=0.5) {
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);

    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);

    const rMix = Math.round(r1 * (1 - amount) + r2 * amount);
    const gMix = Math.round(g1 * (1 - amount) + g2 * amount);
    const bMix = Math.round(b1 * (1 - amount) + b2 * amount);

    return `#${rMix.toString(16)}${gMix.toString(16)}${bMix.toString(16)}`;
}


// function grayscale(color) {
//     const r = parseInt(color.substr(1, 2), 16);
//     const g = parseInt(color.substr(3, 2), 16);
//     const b = parseInt(color.substr(5, 2), 16);
//
//     const v = Math.round((r + g + b) / 3);
//
//     return `#${v.toString(16)}${v.toString(16)}${v.toString(16)}`;
// }


function darken(color, amount) {
    return mix(color, '#000000', amount);
}


// function dull(color, amount) {
//     return mix(color, grayscale(color), amount);
// }


const Container = styled.div`
    display: flex;
    height: 100%;
`;

const Main = styled.div`
    flex: 1;
    height: 100%;
`;

const Sidebar = styled.div`
    flex: 0 4.5rem;
    height: 100%;
    background-color: #5A6072;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    position: relative;
    z-index: 1899; /* Just below popups (1900) and above modals (1000) */

    display: flex;
    flex-direction: column;
    > :first-child {
        background-color: #6A7286;
        flex: 0 4.5rem;
        border-bottom 1px solid rgba(34, 36, 38, 0.15);
    }
    > :last-child {
        flex: 1;
    }
`;

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
`;

const Search = styled.div`
    margin: 0.75rem auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    > * {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 3rem;
        i {
            margin: 0 !important;
        }
        ${({ active }) => active ? `
            background-color: ${darken('#54C8FF', 0.2)};
            i {
                color: rgba(255, 255, 255, 0.8);
            }
        ` : `
            background-color: #7A8496;
            i {
                color: rgba(255, 255, 255, 0.5);
            }
        `}
    }
`;

const UserWrapper = styled.div`
    margin: 0.25rem auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border: 0.15rem solid ${({ active }) => active ? '#2ECC40' : '#7A8496'};
    cursor: pointer;
    > * {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
    position: relative;
`;

const MessagesUnread = styled.div`
    width: 1.2rem;
    height: 1.2rem;
    background-color: #FF695E;
    color: #FFF;
    position: absolute;
    top: -0.35rem;
    right: -0.35rem;
    font-size: 60%;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    line-height: 1.2rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
`;

const StyledImage = styled.img`
    object-fit: cover;
`;

const StyledAnonymous = styled.div`
    background-color: #7A8496;
    font-weight: bold;
    font-size: 110%;
    text-align: center;
    line-height: 2.7rem;
    color: #FFF;
`;

const ChatContainer = styled.div`
    position: fixed;
    z-index: 1899; /* Just below popups (1900) and above modals (1000) */
    right: 5rem;
    bottom: 0;
    max-width: calc(100% - 4.5rem);
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
`;

const ChatWindow = styled.div`
    width: 20rem;
    background-color: #FFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom-width: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin: 0.5rem 0.5rem 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
`;

const ChatHeader = styled.div`
    font-weight: bold;
    font-size: 110%;
    img {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-right: 0.5rem !important;
        object-fit: cover;
    }
    i {
        cursor: pointer;
        float: right;
        color: rgba(0, 0, 0, 0.3);
        &:hover {
            color: rgba(0, 0, 0, 0.5);
        }
    }
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    padding: 0.5rem;
`;

const ChatBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;

const ChatWarning = styled.div`
    padding: 0.25rem 0.5rem;
    font-size: 80%;
    color: #FFF;
    background-color: #FF695E;
    text-align: center;
`;

const ChatMessage = styled.div`
    display: inline-block;
    max-width: calc(100% - 2rem);
    padding: 0.5rem;
    margin: 0.1rem 0.25rem;
    border-radius: 0.5rem;
    position: relative;
    word-wrap: break-word;
    ${({ temp }) => temp ? `
        opacity: 0.5;
    ` : ``}
    ${({ own }) => own ? `
        background-color: #EEE !important;
        align-self: flex-start;
    ` : `
        background-color: ${theme.tintedPrimaryColor} !important;
        align-self: flex-end;
    `};
    ${({ first }) => first ? `
        margin-top: 0.25rem;
    ` : ``}
    ${({ last, own }) => last ? `
        margin-bottom: 0.25rem;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border: 0.5rem solid transparent;
            border-bottom: 0;
            margin-bottom: -0.5rem;
        }
        ${own ? `
            &:after {
                left: 0.75rem;
                border-top-color: #EEE;
                border-left: 0;
            }
        ` : `
            &:after {
                right: 0.75rem;
                border-top-color: ${theme.tintedPrimaryColor};
                border-right: 0;
            }
        `}
    ` : `
    `}
    > i.icon {
        font-size: 0.7rem !important;
        color: rgba(0, 0, 0, 0.25) !important;
        margin: 0 !important;
        position: absolute;
        left: -1.2rem;
        top: calc(50% - 0.35rem);
    }
`;

const ChatInfo = styled.div`
    padding: 0.75rem;
    text-align: center;
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
`;

const SendForm = styled(Form)`
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
    textarea {
        padding: calc(0.6428649em - 1px) !important;
        min-height: unset !important;
        max-height: unset !important;
        resize: none !important;
        margin-right: 0.5rem !important;
    }
    button {
        margin: 0 !important;
    }
`;


@observer
class User extends Component {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel).isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { user, onClick } = this.props;

        let avatar;
        if (user.avatar) {
            avatar = (
                <StyledImage src={user.avatar} />
            );
        } else {
            avatar = (
                <StyledAnonymous>
                    {user.firstName.charAt(0).toUpperCase()}
                    {user.lastName.charAt(0).toUpperCase()}
                </StyledAnonymous>
            );
        }

        return (
            <Popup position="left center" trigger={
                <UserWrapper active={user.active} onClick={onClick}>
                    {avatar}
                    {user.messagesUnread > 0 && (
                        <MessagesUnread>{user.messagesUnread}</MessagesUnread>
                    )}
                </UserWrapper>
            }>
                {user.fullName}
            </Popup>
        );
    }
}


@observer
class Chat extends Component {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel).isRequired,
        onClose: PropTypes.func.isRequired,
        onSend: PropTypes.func.isRequired,
    };

    @observable message = '';

    componentDidMount() {
        this.autoScrollReaction = reaction(
            () => this.props.user.messages.length,
            this.autoScroll.bind(this),
        );
        this.autoScroll();
    }

    componentWillUnmount() {
        this.autoScrollReaction();
    }

    autoScroll() {
        setTimeout(() => {
            const node = (
                ReactDOM.findDOMNode(this)
                .getElementsByClassName('chat-messages')[0]
                .childNodes[0]
            );
            node.scrollTop = node.scrollHeight - node.clientHeight;
        }, 0);
    }

    renderMessage({ body, own, temp, read }, i) {
        const { user } = this.props;
        return (
            <ChatMessage
                key={i} own={own} temp={temp}
                first={
                    i === 0 ||
                    user.messages[i - 1].own !== own
                }
                last={
                    i === user.messages.length - 1 ||
                    user.messages[i + 1].own !== own
                }
            >
                <Nl2Br text={body} />
                {(!own && !read) && (
                    <Popup
                        trigger={<Icon name="eye slash" />}
                        content={t('activeUsers.chat.notRead')}
                    />
                )}
            </ChatMessage>
        );
    }


    @action send() {
        const { onSend } = this.props;

        if (this.message !== '') {
            onSend(this.message);
        }
        this.message = '';
    }

    handleKeyPress(e) {
        if (e.charCode === CHAR_CODE_ENTER && !e.shiftKey) {
            e.preventDefault();
            this.send();
        }
    }

    render() {
        const { user, onClose } = this.props;

        return (
            <ChatWindow>
                <ChatHeader>
                    <Image src={user.avatar || defaultAvatar} avatar />
                    {user.fullName}
                    <Icon name="delete" onClick={onClose} />
                </ChatHeader>
                {!user.active && (
                    <ChatWarning>
                        <Icon name="warning sign" />
                        {t('activeUsers.chat.unavailable', { name: user.fullName })}
                    </ChatWarning>
                )}
                <Scrollbars className="chat-messages" autoHide={true} autoHeight autoHeightMin="0" autoHeightMax="20rem">
                    <ChatBody>
                        {user.messages.map(this.renderMessage.bind(this))}
                        {user.messages.length === 0 && (
                            <ChatInfo>{t('activeUsers.chat.noMessages')}</ChatInfo>
                        )}
                    </ChatBody>
                </Scrollbars>
                <SendForm>
                    <AutofocusInput
                        inputAs={Textarea}
                        refProp="inputRef"
                        placeholder={t('activeUsers.chat.newMessage')}
                        minRows={1} maxRows={5}
                        value={this.message}
                        onChange={(e) => this.message = e.target.value}
                        onKeyPress={this.handleKeyPress.bind(this)}
                    />
                    <Popup
                        content={t('activeUsers.chat.sendMessage')}
                        trigger={<Button
                            primary icon="send"
                            onClick={this.send.bind(this)}
                        />}
                    />
                </SendForm>
            </ChatWindow>
        );
    }
}


@observer
export default class ActiveUsers extends Component {
    static propTypes = {
        currentUser: PropTypes.instanceOf(UserModel).isRequired,
        children: PropTypes.node.isRequired,
    };

    @observable chats = [];

    @observable userStore = new UserStore({
        relations: ['messagesSent', 'messagesReceived'],
        limit: false,
    });

    componentDidMount() {
        this.fetchUsersReaction = reaction(
            () => this.props.currentUser.id,
            (id) => {
                // Fetch users
                if (id === null) {
                    this.userStore.clear();
                } else {
                    this.userStore.params['.id:not'] = id;
                    this.fetch();
                }
                // Subscribe to incoming messages
                if (this.subscriptionMessageNew) {
                    this.subscriptionMessageNew.unsubscribe();
                }
                this.subscriptionMessageNew = subscribe(
                    { target: 'message-new', receiver: id },
                    this.handleMessageNew.bind(this),
                );
                // Subscribe to outgoing messages being marked as read
                if (this.subscriptionMessageRead) {
                    this.subscriptionMessageRead.unsubscribe();
                }
                this.subscriptionMessageRead = subscribe(
                    { target: 'message-read', sender: id },
                    this.handleMessageRead.bind(this),
                );
                // Subscribe to active changes
                if (this.subscriptionUserActiveChange) {
                    this.subscriptionUserActiveChange.unsubscribe();
                }
                this.subscriptionUserActiveChange = subscribe(
                    { target: 'user-active-change' },
                    this.handleUserActiveChange.bind(this),
                );
            },
        );
    }

    fetch() {
        return this.userStore.fetch();
    }

    @action handleMessageNew({ data }) {
        const sender = this.userStore.get(data.sender);
        const message = sender.messagesSent.add(data);
        if (this.chats.includes(sender)) {
            message.markRead();
        }
    }

    handleMessageRead({ data }) {
        const receiver = this.userStore.get(data.receiver);
        receiver.messagesReceived.get(data.id).read = true;
    }

    handleUserActiveChange({ data }) {
        const user = this.userStore.get(data.user);
        if (user) {
            user.active = data.active;
        }
    }

    componentWillUnmount() {
        this.fetchUsersReaction();
        if (this.subscriptionMessageNew) {
            this.subscriptionMessageNew.unsubscribe();
        }
        if (this.subscriptionMessageRead) {
            this.subscriptionMessageRead.unsubscribe();
        }
        if (this.subscriptionUserActiveChange) {
            this.subscriptionUserActiveChange.unsubscribe();
        }
    }

    @observable filtering = false;
    @observable filter = '';

    onFilterOpen() {
        this.filtering = true;
    }

    onFilterClose() {
        this.filtering = false;
        this.filter = '';
    }

    onFilterChange(e, { value }) {
        this.filter = value;
    }

    onFilterEnter() {
        if (
            this.filteredUsers.length > 0 &&
            !this.chats.includes(this.filteredUsers[0])
        ) {
            this.chats.push(this.filteredUsers[0]);
        }
        this.onFilterClose();
    }

    @computed get filteredUsers() {
        if (this.filter === '') {
            return this.userStore.models;
        } else {
            return this.userStore.filter(({ fullName }) => (
                fullName.toLowerCase().includes(this.filter.toLowerCase())
            ));
        }
    }

    compUsers(l, r) {
        // Compare messages unread
        if (l.messagesUnread > 0) {
            if (r.messagesUnread === 0) {
                return -1
            }
        } else if (r.messagesUnread > 0) {
            return 1;
        }
        // Compare active
        if (l.active) {
            if (!r.active) {
                return -1
            }
        } else if (r.active) {
            return 1;
        }
        // Nothing to differntiate
        return 0;
    }

    @computed get sortedUsers() {
        const res = this.filteredUsers.slice();
        res.sort(this.compUsers);
        return res;
    }

    renderUser(user, i) {
        let onClick;
        if (this.chats.includes(user)) {
            onClick = () => {
                this.chats = this.chats.filter((chat) => chat !== user);
            };
        } else {
            onClick = action(() => {
                this.chats.push(user);
                if (user.messagesUnread > 0) {
                    // eslint-disable-next-line no-unused-vars
                    for (const message of user.messagesSent.models) {
                        if (!message.read) {
                            message.markRead();
                        }
                    }
                }
            });
        }

        return (
            <User
                key={user.id}
                user={user}
                onClick={onClick}
            />
        );
    }

    renderChat(user, i) {
        const { currentUser } = this.props;

        const onSend = action((body) => {
            const message = user.messagesReceived.add({ body });
            message._receiverId = user.id;
            message._temp = true;

            message
                .save()
                .then(() => message._temp = false)
                .catch(() => user.messagesReceived.remove(message));
        });
        const onClose = () => {
            this.chats = this.chats.filter((chat) => chat !== user);
        };

        return (
            <Chat
                key={user.id}
                user={user}
                currentUser={currentUser}
                onSend={onSend}
                onClose={onClose}
            />
        );
    }

    render() {
        const { currentUser, children } = this.props;

        if (currentUser.isNew) {
            return children;
        }

        return (
            <Container>
                <Main>{children}</Main>
                <ChatContainer>
                    {this.chats.map(this.renderChat.bind(this))}
                </ChatContainer>
                <Sidebar>
                    <div>
                        <Popup
                            on="click"
                            position="left center"
                            trigger={
                                <Search active={this.filtering}>
                                    <div><Icon name="search" size="large" /></div>
                                </Search>
                            }
                            open={this.filtering}
                            onOpen={this.onFilterOpen.bind(this)}
                            onClose={this.onFilterClose.bind(this)}
                        >
                            <AutofocusInput
                                value={this.filter}
                                onChange={this.onFilterChange.bind(this)}
                                placeholder={t('activeUsers.search.placeHolder')}
                                onKeyPress={Keypress('enter', this.onFilterEnter.bind(this))}
                            />
                        </Popup>
                    </div>
                    <div>
                        <Scrollbars autoHide={true}>
                            <UserContainer>
                                {this.sortedUsers.map(this.renderUser.bind(this))}
                            </UserContainer>
                        </Scrollbars>
                    </div>
                </Sidebar>
            </Container>
        );
    }
}
