import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import TrippyBackground from 'component/TrippyBackground';
import { Body, ContentContainer, Sidebar, Form, Link, Button, FormField, TextInput, Col, Row, Heading } from 're-cy-cle';

@observer
export default class Login extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
        usernameField: PropTypes.string.isRequired,
    };

    static defaultProps = {
        usernameField: 'username',
    };

    @observable errorMsg = '';
    @observable input = { password: '', [this.props.usernameField]: '' };

    

    componentDidMount() {
        this.refUsername.focus();
    }

    handleSubmit = () => {
        const { usernameField } = this.props;

        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, { usernameField: usernameField })
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };

    handleChangeInput = (name, value) => {
        this.input[name] = value;
    };

    render() {
        const { usernameField } = this.props;

        return (
            <Body>
                <ContentContainer>
                    {this.renderContent()}
                    <Sidebar>
                        <Row middle="xs">
                            <Col xs>
                                <Heading>{t('user.login.title')}</Heading>
                            </Col>
                            <Col>
                                <Link to="/login/forgot">
                                    {t('user.login.forgotPasswordLink')}
                                </Link>
                            </Col>
                        </Row>
                        <Form noValidate onSubmit={this.handleSubmit}>
                            <FormField label={t('user.field.email.label')}>
                                <TextInput
                                    name={usernameField}
                                    type="email"
                                    onChange={this.handleChangeInput}
                                    value={this.input[usernameField]}
                                    ref={c => (this.refUsername = c)}
                                />
                            </FormField>
                            <FormField label={t('user.field.password.label')}>
                                <TextInput
                                    name="password"
                                    type="password"
                                    onChange={this.handleChangeInput}
                                    value={this.input.password}
                                />
                            </FormField>
                            <FormField
                                error={this.errorMsg ? [this.errorMsg] : null}
                            >
                                <Button type="submit" fullWidth>
                                    {t('user.login.loginButton')}
                                </Button>
                            </FormField>
                        </Form>
                    </Sidebar>
                </ContentContainer>
            </Body>
        );
    }

    renderContent() {
        return <TrippyBackground />;
    }
}
