import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Entity } from './Entity';
import { Translation } from './Translation';

export class TranslationOverride extends Model {
    static backendResourceName = 'translation_override';

    @observable id = null;
    @observable textNl = '';
    @observable textFr = '';
    @observable textDe = '';
    @observable textUk = '';

    relations() {
        return {
            entity: Entity,
            translation: Translation,
        };
    }
}

export class TranslationOverrideStore extends Store {
    Model = TranslationOverride;
    static backendResourceName = 'translation_override';
}
