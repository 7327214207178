import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from './User';

export class Authentication extends Model {
    static backendResourceName = 'authentication';

    @observable id = null;

    @observable status = 'no_user';
    @observable userAgent = '';
    @observable ipAddress = '';
    @observable code = null;
    @observable verificationExpiresAt = null;
    @observable codeExpiresAt = null;

    relations() {
        return {
            user: User,
        };
    }

    casts() {
        return {
            verificationExpiresAt: Casts.datetime,
            codeExpiresAt: Casts.datetime,
        };
    }
}

export class AuthenticationStore extends Store {
    Model = Authentication;
    static backendResourceName = 'authentication';

    verifyCode(code) {
        return this.api.post(`${this.url()}verify/`, { code });
    }

    resetCode() {
        return this.api.post(`${this.url()}reset_code/`);
    }
}
