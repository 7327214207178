import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { UserStore } from './User';

export class Token extends Model {
    static backendResourceName = 'token';

    @observable id = null;
    @observable token = '';

    relations() {
        return {
            users: UserStore,
        };
    }
}

export class TokenStore extends Store {
    Model = Token;
    static backendResourceName = 'token';
}
