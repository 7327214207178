import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import TrippyBackground from 'component/TrippyBackground';
import { Body, ContentContainer, Sidebar, Button, FormField, TextInput, Col, Row, Heading } from 're-cy-cle';
import { AuthenticationStore } from 'store/Authentication';

@observer
export default class TwoFactorAuth extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
    };

    @observable code = '';

    handleCodeChange = (name, value) => this.code = value;

    handleSubmit = () => {
        const { viewStore } = this.props;
        return (
            new AuthenticationStore()
            .verifyCode(this.code)
            .then(() => viewStore.fetchBootstrap())
        );
    };

    handleReset = () => {
        const { viewStore } = this.props;
        return (
            new AuthenticationStore()
            .resetCode()
            .then(() => viewStore.fetchBootstrap())
        );
    };

    handleCancel = () => {
        const { viewStore } = this.props;
        return viewStore.performLogout();
    };

    render() {
        const { viewStore } = this.props;

        return (
            <Body>
                <ContentContainer>
                    {this.renderContent()}
                    <Sidebar>
                        <Row middle="xs">
                            <Col xs>
                                <Heading>{t('user.twoFactorAuth.title')}</Heading>
                            </Col>
                            <Col>
                                <Button fullWidth onClick={this.handleReset}>
                                    {t('user.twoFactorAuth.resend')}
                                </Button>
                            </Col>
                        </Row>
                        <p>{
                            viewStore.auth.status === 'waiting'
                            ? t('user.twoFactorAuth.explanation')
                            : t('user.twoFactorAuth.explanationFailed')
                        }</p>
                        {viewStore.auth.status === 'waiting' && (
                            <FormField label={t('user.twoFactorAuth.code')}>
                                <TextInput
                                    type="text"
                                    value={this.code}
                                    onChange={this.handleCodeChange}
                                />
                            </FormField>
                        )}
                        <Row>
                            {viewStore.auth.status === 'waiting' && (
                                <Col xs>
                                    <Button fullWidth onClick={this.handleSubmit}>
                                        {t('form.submitButton')}
                                    </Button>
                                </Col>
                            )}
                            <Col xs>
                                <Button fullWidth onClick={this.handleCancel}>
                                    {t('form.cancelButton')}
                                </Button>
                            </Col>
                        </Row>
                    </Sidebar>
                </ContentContainer>
            </Body>
        );
    }

    renderContent() {
        return <TrippyBackground />;
    }
}
