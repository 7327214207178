import { IS_STAGE, IS_UAT } from 'helpers';
import { tint } from 'polished';

export const COLOR_PRIMARY_PROD = '#2D3481';
export const COLOR_PRIMARY_STAGE = '#a73535';
export const COLOR_PRIMARY_UAT = '#c86700';

export const COLOR_PRIMARY = (
    IS_STAGE
    ? COLOR_PRIMARY_STAGE
    : IS_UAT
    ? COLOR_PRIMARY_UAT
    : COLOR_PRIMARY_PROD
);

export const COLOR_RED = '#dc0818';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)';
export const COLOR_WARNING = '#ffc107';

// Color spec as provided by Rene
// #E50003 -> rood voor save buttons etc (niet heel vaak terug laten komen)
// #2D3481 -> basis kleur
// #8783D1 -> basis kleur buttons e.d.
// #F1F1F1 -> grijze vlakken
// #959595 -> grijze buttons (bijv. cancel oid) ..
// #2D3481 op 20% voor blauwe vlakken

// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export const theme = {
    primaryColor: COLOR_PRIMARY,
    tintedPrimaryColor: tint(0.15, COLOR_PRIMARY),

    buttonColor: '#8783D1',

    // Same as re-cy-cle. Copy pasted here, so I can easily access these colors in tht tests.
    dangerColor: '#dc0818',
    warningColor: COLOR_WARNING,
};
