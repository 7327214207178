import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';


export default class AutofocusInput extends Component {
    static propTypes = {
        inputAs: PropTypes.any.isRequired,
        refProp: PropTypes.string.isRequired,
    };
    static defaultProps = {
        inputAs: Input,
        refProp: 'ref',
    };

    componentDidMount() {
        this.input.focus();
    }

    render() {
        const { inputAs: Input, refProp, ...props } = this.props;
        props[refProp] = (ref) => this.input = ref;
        return <Input {...props} />;
    }
}
