import { observable, computed } from 'mobx';
import { Model, Store } from 'store/Base';
import { UserStore } from './User';
import { TranslationOverrideStore } from './TranslationOverride';
import { ExactIntegration } from './ExactIntegration';
import { snakeToCamel } from 'helpers';

export class Entity extends Model {
    static backendResourceName = 'entity';

    @observable id = null;
    @observable name = '';
    @observable logo = null;
    @observable _logo = null;
    @observable header = null;
    @observable _header = null;
    @observable footer = null;
    @observable _footer = null;
    @observable termsOfDeliveryNl = null;
    @observable _termsOfDeliveryNl = null;
    @observable termsOfDeliveryUk = null;
    @observable _termsOfDeliveryUk = null;
    @observable termsOfDeliveryFr = null;
    @observable _termsOfDeliveryFr = null;
    @observable termsOfDeliveryDe = null;
    @observable _termsOfDeliveryDe = null;
    @observable termsAndConditionsNl = null;
    @observable _termsAndConditionsNl = null;
    @observable termsAndConditionsUk = null;
    @observable _termsAndConditionsUk = null;
    @observable termsAndConditionsFr = null;
    @observable _termsAndConditionsFr = null;
    @observable termsAndConditionsDe = null;
    @observable _termsAndConditionsDe = null;
    @observable deliverySpecificationsNl = null;
    @observable _deliverySpecificationsNl = null;
    @observable deliverySpecificationsUk = null;
    @observable _deliverySpecificationsUk = null;
    @observable deliverySpecificationsFr = null;
    @observable _deliverySpecificationsFr = null;
    @observable deliverySpecificationsDe = null;
    @observable _deliverySpecificationsDe = null;
    @observable deleted = false;

    @observable exactAccountCodeProd = 0;
    @observable exactAccountCodeTest = 0;
    @observable vatCode = 0;
    @observable vatPermille = 210;
    @observable vatPercent = 21;

    saveFile(fname) {
        const field = `_${snakeToCamel(fname)}`;

        if (!this[field]) {
            return Promise.resolve();
        }

        const data = new FormData();
        data.append(fname, this[field], this[field].name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}${fname}/`, data, { headers }).then(response => {
            this[field] = null;
            this.parse(response.data);
        });
    }

    @computed get convertVatPercentPermile() {
        return this.vatPercent*10
    }

    saveLogo() {
        return this.saveFile('logo');
    }

    saveHeader() {
        return this.saveFile('header');
    }

    saveFooter() {
        return this.saveFile('footer');
    }

    relations() {
        return {
            users: UserStore,
            translationOverrides: TranslationOverrideStore,
            integration: ExactIntegration,
        };
    }
}

export class EntityStore extends Store {
    Model = Entity;
    static backendResourceName = 'entity';
}
