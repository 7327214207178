import { observable } from 'mobx';
import { Model, Store } from './Base';
import { TranslationOverrideStore } from './TranslationOverride';

export const COMMUNICATION_LANGUAGES = ['nl', 'fr', 'uk', 'de'];

export class Translation extends Model {
    static backendResourceName = 'translation';

    @observable id = null;
    @observable key = '';
    @observable textNl = '';
    @observable textFr = '';
    @observable textDe = '';
    @observable textUk = '';

    relations() {
        return {
            translationOverrides: TranslationOverrideStore,
        };
    }
}

export class TranslationStore extends Store {
    Model = Translation;
    static backendResourceName = 'translation';
}
