import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { snakeToCamel, camelToSnake } from 'helpers';

export const TYPE_ICONS = {
    exact: 'exact',
  }

  export const TYPE_COLORS = {
    exact: '#ff0000',
  }

export class ExactIntegration extends Model {
  static backendResourceName = 'exact_integration'
  static omitFields = ['active']

  @observable id = null
  @observable country = 'nl'
  @observable division = ''
  @observable active = false
  @observable name = ''



  @computed get active() {
    return this[`${snakeToCamel(this.type)}Integration`].active
  }



  toBackendAll(...args) {
    const relations = this.__activeCurrentRelations
    this.__activeCurrentRelations = this.__activeCurrentRelations.filter((rel) => (
      !rel.endsWith('Integration') ||
      camelToSnake(rel.slice(0, -'Integration'.length)) === this.type
    ))
    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = relations
    }
  }
}

export class ExactIntegrationStore extends Store {
  static backendResourceName = 'exact_integration'
  Model = ExactIntegration
}
