import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, NavItemExternal, Logo } from 're-cy-cle';
import { Popup, Header, Modal, Icon } from 'semantic-ui-react';
import { Button as SpiderButton } from 'spider/semantic-ui/Button';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.jpg';
import PrankLogo from 'image/thedickster.png';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { AuthenticationStore } from 'store/Authentication';
import moment from 'moment';
import styled from 'styled-components';

const MyLogo = () => (
    <Logo>
        {moment().format('DD-MM') === '01-04'
            ? <img src={PrankLogo} alt="The Dickster" height="50" />
            : <img src={ImgLogo} alt="BOS" height="35" />}
    </Logo>
);

const StyledNav = styled.div`
    position: relative;
    display: flex;
    cursor: pointer;

    > i {
        margin-left: 2.5em !important;
        margin-top: 1.1em !important;
    }
    > a {
        margin-left: 0.3em;
        padding-left: 0;
    }
`;


@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderOrders = () => {
        return (
            <React.Fragment>
                <NavItem
                    title={t('nav.order.dashboard')}
                    to="/orders/dashboard/overview"
                    activePath="/orders/dashboard/"
                />
                <NavItem
                    title={t('nav.order.projects')}
                    to="/orders/project/overview"
                    activePath="/orders/project/"
                />
                <NavItem
                    title={t('nav.order.activities')}
                    to="/orders/activity/overview"
                />
                <NavItem
                    title={t('nav.order.products')}
                    to="/orders/product/overview"
                />
                <NavItem
                    title={t('nav.order.quotations')}
                    to="/orders/quotation/overview"
                />
                <NavItem
                    title={t('nav.order.downPayments')}
                    to="/orders/down-payment/overview"
                />
                <NavItem
                    title={t('nav.order.invoices')}
                    to="/orders/invoice/overview"
                />
            </React.Fragment>
        );
    };

    renderArtwork = () => {
        return (
            <div style={{ height: '50px' }}/>
        );
    };

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.productTypes')}
                    to="/assets/product-type/overview"
                    activePath="/assets/product-type/"
                />
                <NavItem
                    title={t('nav.assets.componentTypes')}
                    to="/assets/component-type/overview"
                    activePath="/assets/component-type/"
                />
                <NavItem
                    title={t('nav.assets.componentAvailability')}
                    to="/assets/component-availability"
                />
                <NavItem
                    title={t('nav.assets.componentOrders')}
                    to="/assets/component-order/overview"
                    activePath="/assets/component-order/"
                />
                <NavItem
                    title={t('nav.assets.components')}
                    to="/assets/component/overview"
                    activePath="/assets/component/"
                />
                <NavItem
                    title={t('nav.assets.vehicle')}
                    to="/assets/vehicle/overview"
                    activePath="/assets/vehicle/"
                />
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.entities')}
                    to="/assets/entity/overview"
                    activePath="/assets/entity/"
                />
                <NavItem
                    title={t('nav.assets.constants')}
                    to="/assets/constant/overview"
                    activePath="/assets/constant/"
                />
                <NavItem
                    title={t('nav.assets.extras')}
                    to="/assets/extra/overview"
                    activePath="/assets/extra/"
                />
                <NavItem
                    title={t('nav.assets.branches')}
                    to="/assets/branch/overview"
                    activePath="/assets/branch/"
                />
                <NavItem
                    title={t('nav.assets.translations')}
                    to="/assets/translation/overview"
                    activePath="/assets/translation/"
                />
                <NavItem
                    title={t('nav.assets.startLocations')}
                    to="/assets/start-location/overview"
                    activePath="/assets/start-location/"
                />
                <NavItem
                    title={t('nav.assets.noteTypes')}
                    to="/assets/note-type/overview"
                    activePath="/assets/note-type/"
                />
                <NavItem
                    title={t('nav.assets.integrations')}
                    to="/assets/integrations/overview"
                    activePath="/assets/integrations/"
                />
            </NavMenu>
        );
    };

    renderPlanning = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.planning.route')}
                    to="/planning/route/overview"
                    activePath="/planning/route/"
                />
                <NavItem
                    title={t('nav.planning.work')}
                    to="/planning/work/overview"
                    activePath="/planning/work/"
                />
                <NavItem
                    title={t('nav.planning.comment')}
                    to="/planning/comment/overview"
                    activePath="/planning/comment/"
                />
            </NavMenu>
        );
    };

    renderSales = () => {
        return (
            <NavMenu>
                <StyledNav>
                    <Icon name="building" color="white" />
                    <NavItem
                        title={t('nav.sales.customers')}
                        to="/sales/customer/overview"
                        activePath="/sales/customer" />
                </StyledNav>
                <StyledNav>
                    <Icon name="comments" color="white" />
                    <NavItem
                        title={t('nav.sales.tasks')}
                        to="/sales/task/overview"
                        activePath="/sales/task" />
                </StyledNav>
                <StyledNav>
                    <Icon name="address card" color="white" />
                    <NavItem
                        title={t('nav.sales.contacts')}
                        to="/sales/contact/overview"
                        activePath="/sales/contact" />
                </StyledNav>
                <StyledNav>
                    <Icon name="folder open" color="white" />
                    <NavItem
                        title={t('nav.sales.source')}
                        to="/sales/source/overview"
                        activePath="/sales/source" />
                </StyledNav>
                <StyledNav>
                    <Icon name="images" color="white" />
                    <NavItem
                        title={t('nav.sales.results')}
                        to="/sales/results/overview"
                        activePath="/sales/results" />
                </StyledNav>
            </NavMenu>
        );
    };

    renderProduction= () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.production.printing')}
                    to="/production/printing"
                />
                <NavItem
                    title={t('nav.production.workshop.trotter')}
                    to="/production/workshop/trotter"
                />
                <NavItem
                    title={t('nav.production.workshop.frame')}
                    to="/production/workshop/frame"
                />
            </NavMenu>
        );
    };

    handleAuthReset = () => {
        const { store } = this.props;
        return (
            new AuthenticationStore()
            .resetCode()
            .then(() => store.fetchBootstrap())
        );
    };

    renderTimeLeft = () => {
        const { store } = this.props;

        const timeLeft = moment.duration(
            store.auth.verificationExpiresAt
            .diff(moment())
        );

        return timeLeft.asHours() < 1 && (
            <Popup hoverable trigger={
                <Icon
                    name="warning sign" color="red" size="large"
                    style={{ marginLeft: '0.5em' }}
                />
            }>
                {t('user.twoFactorAuth.expiring', { minutes: Math.ceil(timeLeft.asMinutes()) })}
                <div style={{ textAlign: 'center', paddingTop: '0.5em' }}>
                    <SpiderButton
                        primary icon="refresh" size="small"
                        content={t('form.resetButton')}
                        onClick={this.handleAuthReset}
                    />
                </div>
            </Popup>
        );
    };


    renderAccountMenu = () => {
        const {store} = this.props;
        const {version, branch} = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {" "}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                        {store.auth.status === 'verified' && this.renderTimeLeft()}
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                {/* <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment> */}
            </NavMenu>
        );
    };

    render() {
        const {currentUser} = this.props.store;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.assets.users')}
                            to="/assets/user/overview"
                            activePath="/assets/user/"
                        />
                        <NavItem
                            title={t('nav.assets.entities')}
                            to="/assets/entity/overview"
                            activePath="/assets/entity/"
                        />
                    </NavMenu>

                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </MenuRow>
            </TopMenu>
        ) : (currentUser.groupNames === ['production_view']) ? (
                <TopMenu>
                    <MenuRow>
                        <MyLogo />
                    </MenuRow>
                    <MenuRow>
                        <Route path="/production/" render={this.renderProduction} />
                    </MenuRow>
                </TopMenu>
        ) : (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                        <NavMenu>
                            <NavItem
                                title={t('nav.main.sales')}
                                to="/sales/customer/overview"
                                activePath="/sales/"
                            />
                            <NavItem
                                title={t('nav.main.orders')}
                                to="/orders/project/overview"
                                activePath="/orders/"
                            />
                            <NavItem
                                title={t('nav.main.artwork')}
                                to="/artwork/board"
                                activePath="/artwork/"
                            />
                            {(this.hasPermission('planning.manage_route') || this.hasPermission('planning.view_route'))&& (
                                <NavItem
                                    title={t('nav.main.planning')}
                                    to="/planning/route/overview"
                                    activePath="/planning/"
                                />
                            )}
                            {this.hasPermission('orders.manage_workbatch') && (
                                <NavItem
                                    title={t('nav.main.production')}
                                    to="/production/printing"
                                    activePath="/production/"
                                />
                            )}
                            {this.hasPermission('cyrm.manage_branch') && (
                                <NavItem
                                    title={t('nav.main.assets')}
                                    to="/assets/product-type/overview"
                                    activePath="/assets/"
                                />
                            )}
                            <NavItemExternal
                                title="Wiki"
                                className="nav-item"
                                href="/wiki/"
                                target="_blank"
                            />
                        </NavMenu>
                        {this.renderAccountMenu()}
                    </MenuRow>
                    <MenuRow>
                        <Route path="/assets/" render={this.renderAssets} />
                        <Route path="/orders/" render={this.renderOrders} />
                        <Route path="/account" render={this.renderAccount} />
                        <Route path="/sales/" render={this.renderSales} />
                        <Route path="/planning/" render={this.renderPlanning} />
                        <Route path="/production/" render={this.renderProduction} />
                        <Route path="/artwork/" render={this.renderArtwork} />
                    </MenuRow>
                </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
