import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from "./User";
import moment from 'moment';

export class ChatMessage extends Model {
    static backendResourceName = 'chat_message';

    @observable id = null;
    @observable body = '';
    @observable createdAt = moment();
    @observable read = false;

    @observable _receiverId = undefined;
    @observable _temp = false;

    relations() {
        return {
            sender: User,
            receiver: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }

    markRead() {
        if (!this.read) {
            this.read = true;
            const res = this.api.patch(this.url, { read: true });
            res.catch(() => this.read = false);
            return res;
        }
    }

    toBackend(options = {}) {
        const res = super.toBackend(options);
        res.receiver = res.receiver || this._receiverId;
        return res;
    }
}

export class ChatMessageStore extends Store {
    Model = ChatMessage;
    static backendResourceName = 'chat_message';
}
