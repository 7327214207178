import PropTypes from 'prop-types';
import React, {Component} from 'react';
import View from 'spider/store/View';

import {Route as BaseRoute, Switch, Redirect} from 'react-router-dom';
import Load from './Loadable';
import * as Sentry from '@sentry/react';


const RouteEdit = Load(() => import("../screen/Route/Edit"));
const RouteOverview = Load(() => import("../screen/Route/Overview"));
const WorkOverview = Load(() => import("../screen/Work/Overview"));

const CommentOverview = Load(() => import("../screen/Comment/Overview"));

const ProductTypeOverview = Load(() => import('../screen/ProductType/Overview'));
const ProductTypeEdit = Load(() => import('../screen/ProductType/Edit'));
const ConstantOverview = Load(() => import('../screen/Constant/Overview'));
const ComponentTypeOverview = Load(() => import('../screen/ComponentType/Overview'));
const ComponentTypeAvailability = Load(() => import('../screen/ComponentType/Availability'));
const ComponentTypeEdit = Load(() => import('../screen/ComponentType/Edit'));
const ComponentOverview = Load(() => import('../screen/Component/Overview'));
const ComponentEdit = Load(() => import('../screen/Component/Edit'));
const ComponentOrderOverview = Load(() => import('../screen/ComponentOrder/Overview'));
const ComponentOrderEdit = Load(() => import('../screen/ComponentOrder/Edit'));
// const ExtraPriceOverview = Load(() => import('../screen/ExtraPrice/Overview'));
const ExtraOverview = Load(() => import('../screen/Extra/Overview'));
const TranslationOverview = Load(() => import('../screen/Translation/Overview'));

const ProjectOverview = Load(() => import('../screen/Project/Overview'));
const ActivityOverview = Load(() => import('../screen/Activity/Overview'));
const ProductOverview = Load(() => import('../screen/Product/Overview'));
const QuotationOverview = Load(() => import('../screen/Quotation/Overview'));
const DownPaymentOverview = Load(() => import('../screen/DownPayment/Overview'));
const InvoiceOverview = Load(() => import('../screen/Invoice/Overview'));

const ProjectEdit = Load(() => import('../screen/Project/Edit'));
const OrderEdit = Load(() => import ('../screen/Order/Edit'));

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const LoginForgot = Load(() => import('../screen/LoginForgot'));
const ResetPassword = Load(() => import('../screen/ResetPassword'));

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const CustomerOverview = Load(() => import('../screen/Customer/Overview'));
const CustomerEdit = Load(() => import('../screen/Customer/Edit'));

const ContactOverview = Load(() => import('../screen/Contact/Overview'));
const ContactEdit = Load(() => import('../screen/Contact/Edit'));

const BranchOverview = Load(() => import('../screen/Branch/Overview'));
const BranchEdit = Load(() => import('../screen/Branch/Edit'));

const SourceOverview = Load(() => import('../screen/Source/Overview'));
const SourceEdit = Load(() => import('../screen/Source/Edit'));

const ResultsOverview = Load(() => import('../screen/Results/Overview'));

const VehicleOverview = Load(() => import('../screen/Vehicle/Overview'));
const VehicleEdit = Load(() => import('../screen/Vehicle/Edit'));

const EntityOverview = Load(() => import('../screen/Entity/Overview'));
const EntityEdit = Load(() => import('../screen/Entity/Edit'));

const PrintOverview =  Load(() => import('../screen/Production/Print/Overview'));
const WorkshopOverview =  Load(() => import('../screen/Production/Workshop/Overview'));

const ArtworkBoard =  Load(() => import('../screen/Artwork/Board'));

const TaskOverview = Load(() => import('../screen/Task/Overview'));
const TaskEdit = Load(() => import('../screen/Task/Edit'));

const AccountManagerDashboard = Load(() => import('../screen/AccountManagerDashboard/Overview'));
const NotFound = Load(() => import('../container/NotFound'));

const StartLocationOverview = Load(() => import('../screen/StartLocation/Overview'));
const IntegrationOverview = Load(() => import('../screen/Integration/Overview'));

const NoteTypeOverview = Load(() => import('../screen/NoteType/Overview'));
const NoteTypeEdit = Load(() => import('../screen/NoteType/Edit'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const {currentUser} = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/product-type/overview" render={this.route(ProductTypeOverview)}/>
                <Route path="/assets/product-type/:id/edit/:tabSlug?" render={this.route(ProductTypeEdit)}/>
                <Route path="/assets/product-type/add" render={this.route(ProductTypeEdit)}/>

                <Route path="/assets/component/overview" render={this.route(ComponentOverview)}/>
                <Route path="/assets/component/:id/edit" render={this.route(ComponentEdit)}/>
                <Route path="/assets/component/add" render={this.route(ComponentEdit)}/>

                <Route path="/assets/component-order/overview" render={this.route(ComponentOrderOverview)}/>
                <Route path="/assets/component-order/:id/edit" render={this.route(ComponentOrderEdit)}/>
                <Route path="/assets/component-order/add" render={this.route(ComponentOrderEdit)}/>

                <Route path="/assets/component-type/overview" render={this.route(ComponentTypeOverview)}/>
                <Route path="/assets/component-availability" render={this.route(ComponentTypeAvailability)}/>
                <Route path="/assets/component-type/:id/edit" render={this.route(ComponentTypeEdit)}/>
                <Route path="/assets/component-type/add" render={this.route(ComponentTypeEdit)}/>
                <Route path="/assets/constant/overview" render={this.route(ConstantOverview)}/>

                <Route path="/assets/extra/overview" render={this.route(ExtraOverview)} />

                <Route path="/assets/translation/overview" render={this.route(TranslationOverview)} />

                <Route path="/orders/project/overview" render={this.route(ProjectOverview)} />
                <Route path="/orders/project/:id/edit/:tabSlug?" render={this.route(ProjectEdit)} />
                <Route path="/orders/project/add" render={this.route(ProjectEdit)} />
                <Route path="/orders/project/:projectId/order/:id/edit/:tabSlug?" render={this.route(OrderEdit)} />
                <Route path="/orders/project/:projectId/order/add" render={this.route(OrderEdit)} />

                <Route path="/orders/activity/overview" render={this.route(ActivityOverview)}/>
                <Route path="/orders/product/overview" render={this.route(ProductOverview)} />
                <Route path="/orders/quotation/overview" render={this.route(QuotationOverview)}/>
                <Route path="/orders/down-payment/overview" render={this.route(DownPaymentOverview)}/>
                <Route path="/orders/invoice/overview" render={this.route(InvoiceOverview)}/>

                <Route path="/orders/dashboard/overview" render={this.route(AccountManagerDashboard)}/>


                <Route path="/assets/entity/overview" render={this.route(EntityOverview)}/>
                <Route path="/assets/entity/:id/edit" render={this.route(EntityEdit)}/>
                <Route path="/assets/entity/add" render={this.route(EntityEdit)}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>

                <Route path="/assets/user/add" render={this.route(UserEdit)} />

                <Route path="/assets/vehicle/overview" render={this.route(VehicleOverview)}/>
                <Route path="/assets/vehicle/:id/edit" render={this.route(VehicleEdit)}/>
                <Route path="/assets/vehicle/add" render={this.route(VehicleEdit)}/>

                <Route path="/sales/customer/overview" render={this.route(CustomerOverview)}/>
                <Route path="/sales/customer/:id/edit/:tabSlug?" render={this.route(CustomerEdit)}/>
                <Route path="/sales/customer/add" render={this.route(CustomerEdit)}/>

                <Route path="/sales/task/overview" render={this.route(TaskOverview)}/>
                <Route path="/sales/task/:id/edit/" render={this.route(TaskEdit)}/>
                <Route path="/sales/task/add" render={this.route(TaskEdit)}/>

                <Route path="/sales/contact/overview" render={this.route(ContactOverview)}/>
                <Route path="/sales/contact/:id/edit" render={this.route(ContactEdit)}/>
                <Route path="/sales/contact/add" render={this.route(ContactEdit)}/>

                <Route path="/assets/branch/overview" render={this.route(BranchOverview)}/>
                <Route path="/assets/branch/:id/edit" render={this.route(BranchEdit)}/>
                <Route path="/assets/branch/add" render={this.route(BranchEdit)}/>

                <Route path="/sales/source/overview" render={this.route(SourceOverview)}/>
                <Route path="/sales/source/:id/edit" render={this.route(SourceEdit)}/>
                <Route path="/sales/source/add" render={this.route(SourceEdit)}/>

                <Route path="/sales/results/overview" render={this.route(ResultsOverview)}/>

                <Route path="/planning/route/add" render={this.route(RouteEdit)}/>
                <Route path="/planning/route/:id/edit" render={this.route(RouteEdit)}/>
                <Route path="/planning/route/overview" render={this.route(RouteOverview)}/>
                <Route path="/planning/comment/overview" render={this.route(CommentOverview)}/>
                <Route path="/planning/work/overview" render={this.route(WorkOverview)}/>

                <Route path="/artwork/board" render={this.route(ArtworkBoard)}/>

                <Route path="/production/printing" render={this.route(PrintOverview)}/>
                <Route path="/production/workshop/:workshop" render={this.route(WorkshopOverview)}/>

                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>


                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>

                <Route path="/assets/start-location/overview" render={this.route(StartLocationOverview)}/>
                <Route path="/assets/integrations/overview" render={this.route(IntegrationOverview)}/>
                <Route path="/assets/integrations/exact/ok" render={this.route(IntegrationOverview)} />
                <Route path="/assets/integrations/exact/error/:error" render={this.route(IntegrationOverview)} />

                <Route path="/assets/note-type/overview" render={this.route(NoteTypeOverview)}/>
                <Route path="/assets/note-type/:id/edit" render={this.route(NoteTypeEdit)}/>
                <Route path="/assets/note-type/add" render={this.route(NoteTypeEdit)}/>

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
