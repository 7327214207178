import styled from 'styled-components';
import LoginImage from 'image/login_image.jpg';


export default styled.div`
    flex: 1;
    display: flex;

    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
`;
