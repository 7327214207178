export default {
    accordionEdit: {
        new: 'New',
    },
    tooltips: {
        edit: 'Edit',
        delete: 'Delete',
        masquerade: 'Masquerade',
        view: 'View',
        send: 'Send',
        select: 'Select',
        restore: 'Restore',
        close: 'Close',
    },
    operations : {
        binary : {
            and: 'and',
            or: 'or'
        },
        unary: {
            not: 'not'
        }
    },
    enableDebug: 'Debug: Disabled',
    disableDebug: 'Debug: Enabled',
    production: {
        overview: {
            date: 'Date',
            activityDeadline: 'Activity Deadline',
            stepDeadline: 'Step Deadline',
            project: 'Project',
            order: 'Order',
            canvas: {
                rip: 'Rip canvas',
                print: 'Print canvas',
                finish: 'Finish canvas'
            }
        }
    },
    nav: {
        main: {
            artwork: 'DTP',
            mockup: 'Mockup',
            orders: 'Sales',
            planning: 'Planning',
            production: 'Production',
            construction: 'Construction',
            assets: 'Assets',
            sales: 'CRM',
        },
        assets: {
            products: 'Products',
            componentTypes: 'Component types',
            componentAvailability: 'Component availability',
            componentOrders: 'Component orders',
            components: 'Components',
            cars: 'Cars',
            printers: 'Printers',
            users: 'Employees',
            globalValues: 'Global Values',
            globalFiles: 'Global Files',
            entities: 'Entities',
            productTypes: 'Product types',
            vehicle: 'Vehicles',
            constants: 'Constants',
            extras: 'Extras',
            branches: 'Branches',
            translations: 'Translations',
            startLocations: 'Start Locations',
            noteTypes: 'Note Types',
            integrations: 'Integrations',
        },
        planning: {
            route: 'Routes',
            comment: 'Comments',
            work: 'Jobs',
        },
        production: {
            printing: 'Print & Finishing',
            workshop: {
                trotter: 'Trotter Workshop',
                frame: 'Frame Workshop',
            },
        },
        artwork: {
            overview: 'Overview',
        },
        order: {
            dashboard: 'Dashboard',
            projects: 'Projects',
            activities: 'Activities',
            artwork: 'Artwork',
            products: 'Products',
            quotations: 'Quotations',
            downPayments: 'Down Payments',
            invoices: 'Invoices',
        },
        sales: {
            customers: 'Customers',
            contacts: 'Contacts',
            source: 'Sources',
            tasks: 'Notes',
            results: 'Results',
        },
        account: {
            account: 'Account',
        },
    },
    exactIntegration: {
        overview: {
            authButton: 'Activate Exact Integration',
            authResult: {
                ok: 'Exact integration succesfully activated.',
                error: {
                invalid_request: 'Invalid request sent to Exact.',
                invalid_client: 'Invalid client sent to Exact.',
                invalid_grant: 'Invalid grant sent to Exact.',
                invalid_scope: 'Invalid scope sent to Exact. ',
                unauthorized_client: 'Unauthorized client sent to Exact.',
                unsupported_grant_type: 'Unsupported grant type sent to Exact.',
                },
            },
        },
        create: { title: 'Add Exact Integration'},
        edit: { title: 'Edit Exact Integration'},
        field: {
            type: {
            label: 'Type',
            value: {
                exact: 'Exact',
            },
            },
            name: { label: 'Name' },
            country: { label: 'Country' },
            division: { label: 'Division' }
        },
        integration: {
            overview: {
                title: 'Integrations',
                empty: 'No integrations found.',
                addButton: 'Integration',
                inactive: 'This integration is not active.',
            },
            create: {
                title: 'Add Integration',
            },
            edit: {
                title: 'Edit Integration',
            },
        },
        audit: {
            accessLog: 'Access Logs',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        }
    },
    printing: {
        overview: {
            title: 'Print & Finish'
        },
        waitingOnArtwork: 'Waiting on artwork.',
    },
    performance: {
        field: {
            plannedStep: {
                label: 'Step',
            },
            engineer: {
                label: 'Engineer',
            },
            createdAt: {
                label: 'Performed at'
            },
        }
    },
    planning: {
        customer: {
            panelTitles: {
                customerCode: 'Customer code',
            },
        },
        activityFinalize: {
            currentActivityLabel: 'Current activity',
            previousActivityLabel: 'Previous activity',
        },
    },
    assembly: {
        overview: {
            title: {
                trotter: 'Trotter Workshop',
                frame: 'Frame Workshop',
            },
        },
    },
    taskPostponeDeadline: {
        field: {
            addDeadline: {
                label: 'Add Deadline'
            },
            postponeTo: {
                label: 'Postpone To'
            },
            postponeFrom: {
                label: 'Postpone From'
            },
            postponeReason: {
                label: 'Reason for postpone'
            },
            addDeadlineReason: {
                label: 'Reason for adding deadline'
            },
        },
    },
    taskDocument: {
        field: {
            document: {
                label: 'Document ID: {{ number }}',
            },
        },
    },
    task: {
        edit: {
            comments: 'Comments',
            newComment: 'New Comment',
            sendComment: 'Send Comment (CTRL + ENTER)',
            title: 'Note {{id}}: {{task.subject}}',
        },
        add: {
            title: '[unsaved] {{task.subject}}',
        },
        editModal: {
            title: 'Edit Note',
        },
        overview: {
            title: 'Notes',
            filter: {
                accountManager: 'Account Manager',
                assignee: 'Assigned To',
            }
        },
        create: {
            title: 'Aanmaken'
        },
        addNoteModal: {
            title: 'Add note',
            addNewButton: 'Save',
            label: 'Add Note',
        },
        noteContent: {
            content: 'Submitted by: {{submitter}} ',
            contentDate: ' On: {{date}}',
            description: 'Description :',
            comments: 'Comments :',
        },
        addButton: {
            upload: 'Upload',
            uploadAnother: 'Upload Another',
        },
        field: {
            documentTitle: {
                label: 'Documents',
            },
            document: {
                label: 'Add document',
            },
            submittedBy: {
                label: 'Submitter',
            },
            submittedAt: {
                label: 'Submitted At',
            },
            customer: {
                label: 'Customer'
            },
            subject: {
                label: 'Subject'
            },
            body: {
                label: 'Description'
            },
            assignee: {
                label: 'Assigned To'
            },
            deadline: {
                label: 'Deadline',
                postpone: 'Postpone Deadline',
                addDeadline: 'Add Deadline',
            },
            postponeFrom: {
                label: 'Postpone From',
            },
            postponeTo: {
                label: 'Postpone To',
            },
            postponeReason: {
                label: 'Reason for postpone',
            },
            noteType: {
                label: 'Note Type'
            },
            taskStatus: {
                label: 'Note Status'
            },
            tags: {
                label: 'Tags'
            },
            comments: {
                label: 'Comments',
            },
            id: {
                label: 'TID'
            },
            status: {
                label: 'Status',
                value: {
                    open: 'Open',
                    done: 'Done'
                }
            },
            project: { label: 'Project' },
            order: { label: 'Order' },
            activity: { label: 'Activity' },
            quotation: { label: 'Quotation' },
            invoice: { label: 'Invoice' },
            contact: { label: 'Contact' },
            deleted: { label: 'Deleted' },
        }
    },
    comment: {
        overview: {
            done: 'Finish',
            title: 'Comments',
            onlyNonCompleted: 'Only non completed',
            activity: 'Activity',
            work: 'Job',
        },
        add: {
            title: 'Add comment',
        },
        edit: {
            title: 'Edit comment',
        },
        field: {
            id: {
                label: 'ID',
            },
            text: {
                label: 'Text',
            },
            image: {
                label: 'Image',
            },
            route: {
                label: 'Route'
            },
            order: {
                label: 'Order'
            },
            contact: {
                label: 'Contact'
            }
        }
    },
    result: {
        overview: {
            title: 'Results Gallery',
            filter: {
                customer: 'Customer',
                limit: 'Images per page',
            }
        },
        edit: {
            title: 'Result {{id}}',
        },
        field: {
            id: {
                label: 'ID',
            },
            text: {
                label: 'Text',
            },
            image: {
                label: 'Image',
            },
            description: {
                label: 'Description',
            },
            date: {
                label: 'Date',
            },
            work: {
                label: 'Work',
            },
            customer: {
                label: 'Customer',
            },
        }
    },
    common: {
        delete: {
            error: 'Kon niet verwijderen!',
            success: 'Item is verwijderd',
        },
        filter: {
            deleted: 'Include deleted',
        },
    },
    customerAddress: {
        overview: {
            title: 'Addresses',
        },
        addCustomerAddressModal: {
            title: 'Add customer address',
            addNewButton: 'Save & add address',
        },
        field: {
            id: {
                label: 'ID'
            },
            type: { label: 'Type' },
            isVisiting: { label: 'Visiting address' },
            visitingEmail: { label: 'Visiting address | Email' },
            visitingContact: { label: 'Visiting address | Contact' },
            isQuotation: { label: 'Quotation address' },
            quotationEmail: { label: 'Quotation address | Email' },
            quotationContact: { label: 'Quotation address | Contact' },
            isInvoicing: { label: 'Invoicing address' },
            invoicingEmail: { label: 'Invoicing address | Email' },
            invoicingContact: { label: 'Invoicing address | Contact' },
            description: {
                label: 'Description'
            },
            country: {
                label: 'Country',
                value: {
                    NL: 'Netherlands',
                    BE: 'Belgium',
                    DE: 'Germany',
                    LX: 'Luxembourg',
                    EN: 'England',
                    FR: 'France'
                },
            },
            city: {
                label: 'City'
            },
            contact: {
                label: 'Contact'
            },
            freeField: {
                label: 'Free Field'
            },
            street: {
                label: 'Street'
            },
            number: {
                label: 'Number'
            },
            suffix: {
                label: 'Suffix'
            },
            postalCode: {
                label: 'Postal Code'
            },
        },
    },
    modifier: {
        field: {
            kind: {
                label: 'Kind',
                value: {
                    euro: '€',
                    permille: '%', // Permille modifier is shown as percentage in the frontend
                },
            },
            description: {
                label: 'Description',
            },
            amount: {
                label: 'Amount',
            },
            afterQuotation: {
                info: 'This extra was added after the quotation was approved.',
            },
        },
    },
    extraPrice: {
        overview: {
            title: 'Extra prices',
        },
        field: {
            extra: { label: 'Extra' },
            price: { label: 'Price' },
        },
    },
    quotationSuccessRate: {
        field: {
            id: {
                label: 'ID',
            },
            rate: {
                label: 'Rate',
            },
            user: {
                label: 'Created By',
            },
            createdAt: {
                label: 'Created At',
            },
        },
    },
    quotation: {
        overview: {
            title: 'Quotations',
            csvDownload: 'Download CSV',
        },
        downloadPdf: 'Download PDF',
        viewPdf: 'View PDF',
        needsApproval: 'Because of the discounts given this quotation will need approval before being sent.',
        sendQuotationModal: {
            title: 'Preview & Send Quotation',
            triggerButton: 'Preview & Send',
            priceChanged: 'There have been updates to the pricing of this product resulting in the following price:',
            missingComponentsHeader: 'Not enough components available for activities in quote.',
            missingComponent: 'Missing {{count}} component of type {{component}}.',
            missingComponent_plural: 'Missing {{count}} components of type {{component}}.',
            tab: {
                items: { label: 'Items' },
                details: { label: 'Details' },
                extraDesigns: { label: 'Designs' },
                options: { label: 'Options' },
                attachments: { label: 'Attachments' },
            },
        },
        quotationSuccessRateModal: {
            title: 'Success Rate',
            triggerButton: 'Success Rate',
        },
        viewQuotationModal: {
            title: 'View Quotation',
        },
        activityIds: 'Activity IDs',
        orderIds: 'Order IDs',
        field: {
            id: {
                label: 'QID',
            },
            note: {
                label: 'Note',
            },
            extraDesigns: {
                label: 'Extra Designs',
            },
            linkedDesigns: {
                label: 'Linked designs',
            },
            daysValid: {
                label: 'Days Valid',
            },
            emailTo: {
                label: 'Email To',
            },
            emailCc: {
                label: 'Email CC',
            },
            emailSubject: {
                label: 'Email Subject',
            },
            successRate: {
                label: 'Success Rate',
            },
            emailBody: {
                label: 'Email Text',
            },
            salutation: {
                label: 'Salutation',
            },
            greeting: {
                label: 'Greeting',
            },
            preface: {
                label: 'Preface',
            },
            language: {
                label: 'Language',
                value: {
                    nl: 'Dutch',
                    fr: 'French',
                    uk: 'English',
                    de: 'German',
                },
            },
            status: {
                label: 'Status',
                value: {
                    draft: 'Draft',
                    sent: 'Sent',
                    approved: 'Approved',
                    rejected: 'Rejected',
                    'needs approval': 'Needs Review',
                },
            },
            total: {
                label: 'Total',
            },
            sentAt: {
                label: 'Send Date',
            },
            order: {
                label: 'Order',
            },
            project: {
                label: 'Project',
            },
            customer: {
                label: 'Customer',
            },
            accountManager: {
                label: 'Account Manager',
            },
            branch: {
                label: 'Branch',
            },
            items: {
                count: 'Item Count',
            },
            expired: {
                label: 'Expired',
            },
            rejections: {
                label: 'Rejections',
            },
            downPayment: {
                label: 'Down Payment',
                none: 'No Down Payment',
                required: 'Down Payment required',
                someRequired: 'Down payment required for some option',
                someRequired_plural: 'Down payment required for some options',
            },
            customerSource: {
                label: 'Customer Source',
                value: {
                    project: 'Project',
                    invoice: 'Invoice',
                },
            }
        }
    },
    invoice: {
        activityIds: 'Activity IDs',
        orderIds: 'Order IDs',
        sendReminderModal: {
            title: 'Send Reminder',
            field: {
                to: { label: 'To' },
                cc: { label: 'CC' },
                subject: { label: 'Subject' },
                body: { label: 'Body' },
            },
        },
        creditButton: 'Credit Invoice',
        creditConfirm: 'Are you sure you want to credit this invoice?',
        overview: {
            title: 'Invoices',
        },
        viewPdf: 'View PDF',
        downloadPdf: 'Download PDF',
        sendInvoiceModal: {
            title: 'Preview & Send Invoice',
            triggerButton: 'Preview & Send',
            tab: {
                items: { label: 'Items' },
                details: { label: 'Details' },
                attachments: { label: 'Attachments' },
            },
            finalizeAndSendButton: 'Finalize & Send',
            finalizeButton: 'Finalize',
        },
        sendPartialInvoiceModal: {
            title: 'Preview & Send Partial Invoice',
        },
        sendCreditInvoiceModal: {
            title: 'Preview & Send Credit Invoice',
        },
        partialInvoiceModal: {
            exactError: 'Exact Sync Error',
            triggerButton: 'Partial Invoices',
            title: 'Partial Invoices',
            amountError: {
                aboveTitle: 'Total amount too high',
                belowTitle: 'Total amount too low',
                content: 'The partial invoices have a combined total of {{actual}}, while the combined total should be {{expected}}.',
            },
            recurring: {
                triggerButton: 'Schedule Recurring',
                field: {
                    startDate: { label: 'Start Date' },
                    unit: {
                        label: 'Unit',
                        value: {
                            week: 'Week',
                            month: 'Month',
                            quarter: 'Quarter',
                            year: 'Year',
                        },
                    },
                    amount: { label: 'Amount' },
                },
            },
            schedule: 'Schedule',
            unschedule: 'Unschedule',
            scheduleAll: 'Schedule All',
            unscheduleAll: 'Unschedule All',
        },
        viewPartialInvoiceModal: {
            title: 'View Invoice',
            creditTitle: 'View Credit',
        },
        viewInvoiceModal: {
            title: 'View Invoice',
        },
        field: {
            id: {
                label: 'INID',
            },
            note: { label: 'Note'},
            emailFrom: { label: 'Email From' },
            emailTo: { label: 'Email To' },
            emailCc: { label: 'Email CC' },
            emailSubject: {
                label: 'Email Subject',
            },
            emailBody: {
                label: 'Email Text',
            },
            salutation: {
                label: 'Salutation',
            },
            greeting: {
                label: 'Greeting',
            },
            language: {
                label: 'Langauge',
                value: {
                    nl: 'Dutch',
                    uk: 'English',
                    fr: 'French',
                    de: 'German',
                },
            },
            status: {
                label: 'Status',
                value: {
                    draft: 'Draft',
                    finalized: 'Finalized',
                    partially_sent: 'Partially Sent',
                    'needs approval': 'Needs Approval',
                    'needs approval send': 'Needs Approval (Send)',
                    sent: 'Sent',
                    credited: 'Credited',
                    approved: 'Approved',
                    rejected: 'Rejected',
                },
            },
            total: {
                label: 'Total',
            },
            sentAt: {
                label: 'Send Date',
            },
            order: {
                label: 'Order',
            },
            project: {
                label: 'Project',
            },
            customer: {
                label: 'Customer',
            },
            accountManager: {
                label: 'Account Manager',
            },
            branch: {
                label: 'Branch',
            },
            invoiceCustomers: { label: 'Invoice Customers' },
            invoiceNumbers: { label: 'Invoice Numbers' },
            items: {
                count: 'Item Count',
            },
            invoicePdfLayout: {
                label: 'Invoice PDF layout',
                value: {
                    project: 'Project layout',
                    original: 'Original layout (simplified)',
                },
            },
        }
    },
    customer: {
        overview: {
            title: 'Customers',
            add: 'Add customer',
            mainContacts: 'Main Contacts',
        },
        edit: {
            title: 'Customer {{id}}: {{name}}',
            kvkFill: 'KvK',
            basicInfo: 'Basic Info',
            existing: 'Existing',
            new: 'New',
            selectContact: 'Select Contact',
            addProject: 'Add project',
        },
        add: {
            title: '[unsaved] {{name}}',
        },
        kvkModal: {
            title: 'Search KvK',
            kvkNumber: 'KVK Number',
            name: 'Name',
            city: 'City',
            noResults: 'No results found yet...',
        },
        customerStatusChange: {
            lastUpdated: 'Last Updated',
            updated: 'Updated',
            statusUpdate: '{{update}}: {{date}}, {{statusFrom}} to {{statusTo}} by {{user}}'
        },
        addContactModal: {
            title: 'Select Contact to add to this Customer',
            addSelectedButton: 'Add selected ({{count}}) contacts',
            addNewButton: 'Save',
            similarHeader: 'Similar Contacts',
            noSimilarContacts: 'No similar contacts found.',
            moreSimilarContacts: 'Showing {{count}} out of {{total}} similar contacts.',
            moreCustomers: ' and {{count}} more',
        },
        addCustomerAddressModal: {
            title: 'Add customer address',
            saveAndAddButton: 'Save & add address',
            saveButton: 'Save address',
        },
        mainContacts : {
            function: 'Function',
            email: 'Email',
            phoneNumber: 'Phone number'
        },
        field: {
            id: {
                label: 'CID'
            },
            type: {
                label: 'Type'
            },
            language: {
                label: 'Language',
                value: {
                    nl: 'Dutch',
                    fr: 'French',
                    uk: 'English',
                    de: 'German',
                },
            },
            name: {
                label: 'Name'
            },
            description: {
                label: 'Description'
            },
            country: {
                label: 'Country'
            },
            freeField: {
                label: 'Free Field'
            },
            street: {
                label: 'Street'
            },
            number: {
                label: 'Number'
            },
            suffix: {
                label: 'Suffix'
            },
            postalCode: {
                label: 'Postal Code'
            },
            status: {
                label: 'Status',
                value: {
                    lead: 'Lead',
                    prospect: 'Prospect',
                    suspect: 'Suspect',
                    customer: 'Customer',
                    lost: 'Lost',
                    invoice_customer: 'Invoice Customer',
                },
            },
            mainPhonenumber: {
                label: 'Main Phone Number'
            },
            creationDate: {
                label: 'Created At'
            },
            branch: {
                label: 'Branch'
            },
            tags: {
                label: 'Tags'
            },
            accountManager: {
                label: 'Account Manager'
            },
            entity: {
                label: 'Entity',
            },
            source: {
                label: 'Source'
            },
            vatNumber: { label: 'VAT Number' },
            kvkNumber: { label: 'KvK Number' },
            vatShifted: { label: 'VAT Shifted' },
            amountDue: {label: 'Amount Due'},
            amountOverdue: {label: 'Amount Overdue'},
            totalInvoiceAmountCurrentYear: {label: 'Total invoice this year'},
            downPaymentRequiredOverride: {
                label: 'Down Payment Required Above',
                override: 'Override',
            },
            note: { label: 'Notes' },
        },
    },
    customerContact: {
        deleteButton: 'Remove customer contact',
        selectButton: 'Select customer contact',
        field: {
            id: {
                label: 'ID',
            },
            customer: {
                label: 'Customer',
            },
            type: {
                label: 'Type',
                value: {
                    current: 'Current',
                    old: 'Old',
                    main: 'Main',
                },
            },
            isDecisionmaker: { label: 'Decisionmaker' },
            description: { label: 'Description' },
        },
    },
    tag: {
        overview: {
            title: 'Tags'
        }
    },
    contact: {
        add: {
            select: 'Select contact',
            add: 'Add contact'
        },
        edit: {
            tile: 'Edit Contact',
            showAddCustomerContactButton: 'Add Customer Contact',
            showAddAddressButton: 'Add Address',
        },
        overview: {
            title: 'Contacts',
            edit: 'Edit contact'
        },
        field:{
            id: {
                label: 'ID'
            },
            note: {
                label: 'Note',
            },
            name: {
                label: 'Name'
            },
            function: {
                label: 'Function'
            },
            email: {
                label: 'Email'
            },
            phonenumber: {
                label: 'Phone Number'
            },
            company: {
                label: 'Company'
            },
            info: { label: 'Info' },
            initials: { label: 'Initials' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            customers: { label: 'Customers' },
        }
    },
    note: {
        sendButtonTitle: 'Shortcut: ctrl + enter',
        sendButton: 'Save',
        addContactButton: 'Add Contact',
        add: {
            add: 'Add note'
        },
        overview: {
            title: 'Notes'
        },
        field: {
            note: {
                label: 'Note',
                placeholder: 'Add a note',
            },
        },
    },
    design: {
        count: '{{count}} Design',
        count_plural: '{{count}} Designs',
        addButton: 'Add design',
        historyModal: {
            title: 'Design History',
            noImage: 'No Image',
            changes: {
                image: 'Changed image',
                status: 'Changed status',
            },
        },
        field: {
            id: { label: 'DID' },
            assignedTo: {
                label: 'Assigned To',
            },
            status: {
                label: 'Status',
            },
            type: {
                label: 'Type',
                value: {
                    internal: 'Internal',
                    external: 'External',
                },
            },
            priority: {
                label: 'Priority',
                value: {
                    low: 'Low',
                    normal: 'Normal',
                    high: 'High',
                },
            },
            description: {
                placeholder: 'Enter description...',
                label: 'Description',
            },
            width: { label: 'Width' },
            height: { label: 'Height' },
        },
        overview: {
            title: 'Notes'
        }
    },
    branch: {
        addButton: 'Add branch',
        create: {
            title: 'Create branch',
        },
        edit: {
            title: 'Edit branch'
        },
        overview: {
            title: 'Branches',
            add: 'Create Branch'
        },
        field: {
            name: {
                label: 'Branch name'
            },
            id: {
                label: 'ID'
            },
            defaultQuality: {
                label: 'Default Quality',
                value: {
                    normal: 'Normal',
                    premium: 'Premium',
                    premium_plus: 'Premium Plus',
                },
            },
            maximumQuotationDiscountAmount: {
                label: 'Maximum quotation discount amount'
            },
            maximumQuotationDiscountPercentage: {
                label: 'Maximum quotation discount percentage'
            },
            downPaymentRequired: {
                label: 'Down Payment Required Above',
            },
        }
    },
    authentication: {
        field: {
            status: {
                label: 'Status',
                value: {
                    expired: 'Expired',
                    waiting: 'Waiting',
                    verified: 'Verified',
                },
            },
            userAgent: { label: 'User Agent' },
            ipAddress: { label: 'IP Address' },
            code: { label: 'Code' },
            expiresAt: { label: 'Expires At' },
        },
    },
    products: {
        overview: {
            header: {
                id: 'ID',
                name: 'Name',
            },
        },
        edit: {
            header: {
                config: 'Configuration',
                pricing: 'Pricing',
                process: 'Process',
                production: 'Production',
                construction: 'Construction',
                deconstruction: 'Deconstruction',
                disassembly: 'Disassembly',
            },
            step: {
                header: {
                    configStep: 'Step Configuration',
                    configCond: 'Condition Configuration',
                    consumes: 'Consumes',
                    produces: 'Produces',
                },
                inventory: {
                    component: 'Component',
                    target: 'Target',
                    quantity: '#',
                },
            },
        },
    },
    entity: {
        addButton: 'Add entity',
        field: {
            id: {label: 'ID'},
            name: {label: 'Name'},
            logo: { label: 'Logo' },
            header: { label: 'Header' },
            footer: { label: 'Footer' },
            deleted: { label: 'Archived' },
            translationOverrides: { label: 'Translation Overrides' },
            termsOfDeliveryNl: { label: 'Terms of Delivery NL' },
            termsOfDeliveryUk: { label: 'Terms of Delivery UK' },
            termsOfDeliveryFr: { label: 'Terms of Delivery FR' },
            termsOfDeliveryDe: { label: 'Terms of Delivery DE' },
            termsAndConditionsNl: { label: 'Terms and Conditions NL' },
            termsAndConditionsUk: { label: 'Terms and Conditions UK' },
            termsAndConditionsFr: { label: 'Terms and Conditions FR' },
            termsAndConditionsDe: { label: 'Terms and Conditions DE' },
            deliverySpecificationsNl: { label: 'Delivery Specifications NL' },
            deliverySpecificationsUk: { label: 'Delivery Specifications UK' },
            deliverySpecificationsFr: { label: 'Delivery Specifications FR' },
            deliverySpecificationsDe: { label: 'Delivery Specifications DE' },
            exactAccountCodeProd: {label: 'Exact Account code'},
            exactAccountCodeTest: {label: 'Exact Account code (test)'},
            vatCode: {label: 'VAT code'},
            vatPercent: {label: 'VAT Percentage'},
            integration: {label: 'Integration'},
        },
        overview: {
            title: 'Entities',
            add: 'Add Entity',
        },
        edit: {
            title: 'Edit Entity',
        },
        create: {
            title: 'Create Entity',
        }
    },
    vehicle: {
        addButton: 'Add vehicle',
        overview: {
            title: 'Vehicles'
        },
        edit: {
            title: 'Edit vehicle'
        },
        create: {
            title: 'Add vehicle'
        },
        field: {
            name: {
                label: 'Name'
            },
            type: {
                label: 'Type'
            },
            licence: {
                label: 'Licence plate'
            },
            deleted: { label: 'Archived' },
        },
        type: {
            van: 'Van',
            truck: 'Truck',
            'cherry picker': 'Cherry Picker',
            forklift: 'Forklift',
        }
    },
    productType: {
        addButton: 'Add product type',
        overview: {
            title: 'Product types',
        },
        expr: {
            kind: {
                const: 'Constant',
                call: 'Call',
                prop: 'Property',
                func: 'Function',
            },
            type: {
                text: 'Text',
                number: 'Number',
                bool: 'Bool',
                null: 'Null',
                config: 'Config',
                placeholder: 'Value Type'
            },
            params: 'Parameters',
            body: 'Body',
        },
        edit: {
            title: 'Edit Product type',
            display: {
                label: 'Displays',
            },
            requirements: {
                label: 'Components',
            },
            constants: {
                label: 'Constants',
            },
            props: {
                label: 'Show',
            },
            computedValues: {
                label: 'Computed Values',
            },
            config: {
                allowOther: 'Allow other',
                label: 'Config',
                kind: {
                    input: 'Input',
                    choice: 'Choice',
                    const: 'Constant',
                },
                deleteProperty: 'Delete Property',
                unit: 'Unit',
                unitModal: {
                    title: 'Change Unit',
                },
            },
            pricing: {
                label: 'Pricing',
                type: 'Activity Pricing',
                types: {
                    placePricing: 'Place Pricing',
                    movePricing: 'Move Pricing',
                    canvasSwapPricing: 'Canvas Swap Pricing',
                    stickerPricing: 'Sticker Pricing',
                    extendPricing: 'Extend Pricing',
                },
            },
            steps: {
                label: 'Steps',
                description: 'Description',
                batch: 'Batch',
                categories: {
                    canvas_prep: { label: 'Canvas Prep' },
                    assembly: { label: 'Assembly' },
                    construction: { label: 'Construction' },
                    deconstruction: { label: 'Deconstruction' },
                    disassembly: { label: 'Disassembly' },
                    canvas_swap: { label: 'Canvas Swap' },
                    damage_site_survey: { label: 'Damage Site Survey' },
                    measure_site_survey: { label: 'Measure Site Survey' },
                    technical_site_survey: { label: 'Technical Site Survey' },
                    commercial_site_survey: { label: 'Commercial Site Survey' },
                    repair: { label: 'Repair' },
                    repair_workshop: { label: 'Repair Workshop' },
                    sticker: { label: 'Sticker' },
                    planning: { label: 'Planning' },
                    sales: { label: 'Sales' },
                },
                add: 'Add a step',
            },
        },
        add: {
            title: 'Add Product Type',
        },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
            canvasPrepBeforeAssembly: { label: 'Canvas Prep before Assembly' },
            assemblyDuration: { label: 'Assembly Duration' },
            constructionDuration: { label: 'Construction Duration' },
            deconstructionDuration: { label: 'Deconstruction Duration' },
            canvasSwapDuration: { label: 'Canvas Swap Duration' },
            damageSiteSurveyDuration: { label: 'Damage Site Survey Duration' },
            measureSiteSurveyDuration: { label: 'Measure Site Survey Duration' },
            technicalSiteSurveyDuration: { label: 'Technical Site Survey Duration' },
            commercialSiteSurveyDuration: { label: 'Commercial Site Survey Duration' },
            stickerDuration: { label: 'Sticker Duration' },
            material: { label: 'Material' },
            commercialName: { label: 'Commercial Name' },
            options: { label: 'Options' },
            durationSuffix: { label: 'Duration Suffix' },
            workshop: {
                label: 'Workshop',
                trotter: 'Trotter Workshop',
                frame: 'Frame Workshop',
            },
            placeLedger: { label: 'Place Ledger' },
            moveLedger: { label: 'Move Ledger' },
            canvasSwapLedger: { label: 'Canvas Swap Ledger' },
            repairLedger: { label: 'Repair Ledger' },
            stickerLedger: { label: 'Sticker Ledger' },
            chargeLedger: { label: 'Charge Ledger' },
            extendLedger: { label: 'Extend Ledger' },
        },
    },
    requirementType: {
        field: {
            componentType: { label: 'Component type' },
            quantity: { label: 'Quantity' },
        },
    },
    constant: {
        addButton: 'Add constant',
        overview: {
            title: 'Constants',
        },
        field: {
            name: { label: 'Name' },
            value: { label: 'Value' },
            locked: { label: 'Locked' },
        },
    },
    displayType: {
        field: {
            width: { label: 'Width' },
            height: { label: 'Height' },
            quantity: { label: 'Quantity' },
            must_split: { label: 'Must Split' },
        },
    },
    product: {
        deleteProtected: 'Cannot delete, since some activities have been quoted',
        field: {
            id: { label: 'PDID' },
            name: { label: 'Name' },
            productType: { label: 'Type' },
            config: { label: 'Config' },
            order: { label: 'Order'},
            material: { label: 'Material' },
            quality: { label: 'Quality' },
            size: { label: 'Size' },
            placedAt: { label: 'Placed At' },
            instructions: { label: 'Product Instructions' },
            durationSuffixNl: { label: 'Duration Suffix NL' },
            durationSuffixUk: { label: 'Duration Suffix UK' },
            durationSuffixFr: { label: 'Duration Suffix FR' },
            durationSuffixDe: { label: 'Duration Suffix DE' },
            optionsNl: { label: 'Options NL' },
            optionsUk: { label: 'Options UK' },
            optionsFr: { label: 'Options FR' },
            optionsDe: { label: 'Options DE' },
        },
        add: {
            other: 'Other...',
        },
        historyModal: {
            title: 'History',
        },
        copyModal: {
            title: 'Copy product',
            quantity: 'Number of copies',
            copyDoneMessage: 'Copies added',
        },
        textsModal: {
            title: 'Product Texts',
        },
        overview: {
            title: 'Products',
            date: 'Date',
            map: {
                loading: 'Loading',
            },
        },
    },
    instruction: {
        historyModal: {
            title: 'Instruction History',
            noImage: 'No Image',
            changes: {
                description: 'Changed description',
                image: 'Changed image',
                important: 'Changed important',
                categories: 'Changed categories',
            },
        },
        sharedWarning: 'This instruction is shared with {{count}} other activity.',
        sharedWarning_plural: 'This instruction is shared with {{count}} other activities.',
        historyButton: 'View Change History',
        pinButton: 'Pin to Product',
        unpinButton: 'Unpin from Product',
        linkButton: 'Select for Linking',
        unlinkButton: 'Unlink Instruction',
        deleteButton: 'Delete Instruction',
        cards: {
            linkButton: 'Link Selected Instruction to Activity',
            addButton: 'Add Instruction to Activity',
        },
        chooseInstructionModal: {
            title: 'Choose Instruction to link',
            linkButton: 'Link'
        },
        chooseProductsModal: {
            title: 'Choose Products',
            linkButton: 'Link',
        },
        field: {
            id: { label: 'ID' },
            description: { label: 'Description' },
            important: { label: 'Highlight (for engineer)' },
        },
    },
    instructionChange: {
        field: {
            ordering: { label: '#' },
            createdBy: { label: 'Author' },
            createdAt: { label: 'Changed At' },
        },
    },
    designChange: {
        field: {
            ordering: { label: '#' },
            createdBy: { label: 'Author' },
            createdAt: { label: 'Changed At' },
        },
    },
    designStatusChange: {
        field: {
            newStatus: { history: 'Changed status to "{{status}}"' },
        },
    },
    activities: {
        activityExportWeekly: {
            title: 'Weekly activity export'
        },
        activityExport: {
            title: 'Activity export'
        },
        activityExportAll: {
            title: 'Export all activities'
        }
    },
    activity: {
        incompatibleDisplays: 'The displays on the activities you are trying to copy are not compatible with P{{product.id}}.',
        replacePlace: 'Are you sure you want to replace the place activity of P{{product.id}}?',
        replaceRemove: 'Are you sure you want to replace the remove activity of P{{product.id}}?',
        info: 'Activity {{index}}',
        cancel: {
            includesFinalizedRoutesPlanner: 'One or more of the selected activities is in a finalized route. Are you sure?',
            includesFinalizedRoutesAccountManager: 'One or more of the selected activities is/are in a finalized route. Planner role is required to cancel!',
            includesRoutes: 'One or more of the selected activities is in a route which is not yet finalized. Are you sure?',
        },
        edit: {
            confirmChangeLocation: 'Do you really want to change the location of this activity?',
        },
        overview: {
            title: 'Activities',
            product: 'Product',
            order: 'Order',
            contact: 'Invoice Contact',
            project: 'Project',
            customer: 'Customer',
        },
        design: {
            worker: 'Platform worker',
            bulldozer: 'Bulldozer'
        },
        field: {
            orderProcessor: {
                label: 'OP',
            },
            id: {
                label: 'AID'
            },
            hasDefaultTimeWindows: {
                label: 'Time window?'
            },
            orderReference: {
                label: 'Order reference'
            },
            createdAt: {
                label: 'Created'
            },
            customer: {
                label: 'Customer'
            },
            minTimeWindow: {
                label: 'Min Time Window'
            },
            maxTimeWindow: {
                label: 'Max Time Window'
            },
            minDate: {
                label: 'Min Date'
            },
            maxDate: {
                label: 'Max Date'
            },
            approvedWindow: { label: 'Planner Approved' },
            hardDeadline: {
                label: 'Deadline'
            },
            constructionDuration: {
                label: 'Construction Duration',
            },
            finalized: {
                label: 'Finalized'
            },
            document: {
                label: 'Document'
            },
            deconstructionDuration: {
                label: 'Deconstruction Duration',
            },
            canvasSwapDuration: {
                label: 'Canvas Swap Duration',
            },
            stickerDuration: {
                label: 'Sticker Duration',
            },
            repairDuration: {
                label: 'Repair Duration',
            },
            damageSiteSurveyDuration: {
                label: 'Damage Site Survey Duration',
            },
            measureSiteSurveyDuration: {
                label: 'Measure Site Survey Duration',
            },
            technicalSiteSurveyDuration: {
                label: 'Technical Site Survey Duration',
            },
            commercialSiteSurveyDuration: {
                label: 'Commercial Site Survey Duration',
            },
            status: {
                label: 'Status',
                prefixedLabel: 'Activity Status',
                value: {
                    draft: 'Draft',
                    quoted: 'Quoted',
                    quoted_sent: 'Quoted (sent)',
                    to_be_planned: 'To be Planned',
                    to_be_invoiced: 'To be Invoiced',
                    invoiced: 'Invoiced',
                    invoiced_sent: 'Invoiced (sent)',
                    finalized: 'Finalized',
                    planned: 'Planned',
                    executed: 'Executed',
                    approved: 'Approved',
                    cancelled: 'Cancelled',
                    done: 'Done',
                    planned_finalized: 'Planned (finalized)',
                    invoiced_cancelled: 'Cancelled (invoiced)',
                    invoiced_sent_cancelled: 'Cancelled (invoiced & sent)',
                },
            },
            type: {
                label: 'Type',
                value: {
                    place: 'Place',
                    move: 'Move',
                    remove: 'Remove',
                    site_survey: 'Site Survey',
                    canvas_swap: 'Canvas Swap',
                    repair: 'Repair',
                    extend: 'Extend',
                    sticker: 'Sticker',
                    import: 'Import',
                    charge: 'Charge',
                    prepaid_canvas_swap: 'Prepaid Canvas Swap',
                    prepaid_move: 'Prepaid Move',
                    export: 'Export',
                },
            },
            manHours: {
                label: "Manhours"
            },
            instructions: {
                label: 'Instructions',
            },
            requestedPerformAt: {
                label: 'Requested perform at',
            },
            accountManager: {
                label: 'AM'
            },
            placeAt: {
                label: 'Place At',
                lat: 'Lat',
                lng: 'Lng',
            },
            invoiced: {
                label: 'Invoiced'
            },
            duration: {
                label: 'Placement Period'
            },
            window: {
                label: 'Window'
            },
            remarks: {
                label: 'Remarks'
            },
            account_manager:{
                label: 'AM'
            },
            order_processor:{
                label: 'OP'
            },
            route: {
                label: 'Route'
            },
            eta: {
                label: 'ETA',
            },
            project: {
                label: 'Project'
            },
            product: {
                label: 'Product'
            },
            location: {
                label: 'Locatie'
            },
            extras: {
                label: 'Extras',
                values: {
                    internal_platform: 'Internal Platform',
                    external_platform: 'External Platform',
                    internal_crane: 'Internal Crane',
                    external_crane: 'External Crane',
                    internal_forklift: 'Internal Forklift',
                    external_forklift: 'External Forklift',
                    internal_rolling_scaffold: 'Internal Scaffold',
                    external_rolling_scaffold: 'External Scaffold',
                    ten_meter_ladder: '10m Ladder',
                    pile_driver: 'Pile Driver',
                    trotter_lift: 'Trotter Lift',
                    road_plates: 'Road Plates',
                    facade_installation: 'Facade Installation',
                    viewing: 'Viewing',
                    dtp_work: 'DTP Work',
                    stock_photo: 'Stock Photo',
                },
            },
            hasInstructions: { label: 'Has Instructions' },
            hasPrepressedArtwork: { label: 'Has Artwork' },
            productionFinished: { label: 'Production Finished' },
        },
    },
    repairActivity: {
        field: {
            workshopRequired: { label: 'Workshop Required' },
            onSiteRepair: { label: 'On Site Repair' },
            canvasRepair: { label: 'Canvas Repair' },
            stormDamage: { label: 'Storm Damage' },
            invoiceExempt: {
                label: 'Invoice Exempt',
                tooltip: 'This repair activity was manually approved and thus cannot be invoiced.',
            },
        },
    },
    placeActivity: {
        field: {
            city: {
                label: 'City',
            },
            street: {
                label: 'Street',
            },
            streetNumber: {
                label: 'Number',
            },
            durationUnit: {
                label: 'Unit',
                value: {
                    months: 'Months',
                    days: 'Days',
                },
            },
            duration: {
                label: 'Duration',
            },
            manualWindow: {
                label: 'Manual Window',
                manual: 'Manual',
                automatic: 'Automatic',
            },
            preInvoice: { label: 'Pre Invoice' },
        },
    },
    canvasSwapActivity: {
        field: {
            manualWindow: { label: 'Manual Window' },
        },
    },
    importActivity: {
        field: {
            city: {
                label: 'City',
            },
            street: {
                label: 'Street',
            },
            streetNumber: {
                label: 'Number',
            },
            durationUnit: {
                label: 'Unit',
                value: {
                    months: 'Months',
                    days: 'Days',
                },
            },
            duration: {
                label: 'Duration',
            },
            exportActivity: { label: 'Export Activity' },
        },
    },
    moveActivity: {
        field: {
            city: {
                label: 'City',
            },
            street: {
                label: 'Street',
            },
            streetNumber: {
                label: 'Number',
            },
        },
    },
    extendActivity: {
        field: {
            durationUnit: {
                label: 'Duration Unit',
            },
            duration: {
                label: 'Duration',
            },
            invoiceExempt: {
                label: 'Invoice Exempt',
                tooltip: 'This repair activity was manually approved and thus cannot be invoiced.',
            },
        },
    },
    removeActivity: {
        field: {
            earlyRemove: { label: 'Early' },
        },
    },
    siteSurveyActivity: {
        field: {
            kind: {
                label: 'Kind',
                value: {
                    damage: 'Damage',
                    measure: 'Measure',
                    technical: 'Technical',
                    commercial: 'Commercial',
                },
            },
            invoiceExempt: {
                label: 'Invoice Exempt',
                tooltip: 'This site survey activity was manually approved and thus cannot be invoiced.',
            },
        },
    },
    stickerActivity: {
        panelName: 'Sticker',
        field: {
            width: { label: 'Width' },
            height: { label: 'Height' },
            manualWindow: { label: 'Manual Window' },
            invoiceExempt: {
                label: 'Invoice Exempt',
                tooltip: 'This sticker activity was manually approved and thus cannot be invoiced.',
            },
        },
    },
    step: {
        field: {
            category: {
                label: 'Category',
            },
        },
    },
    instructionStep: {
        addButton: 'Add Instruction Step',
        edit: {
            title: 'Edit Instruction Step'
        },
        field: {
            description: {
                label: 'Description'
            }
        }
    },
    conditionStep: {
        addButton: 'Add Condition Step',
        edit: {
            title: 'Edit Condition Step'
        }
    },
    loadStep: {
        addButton: 'Add Load Step',
        edit: {
            title: 'Edit Load Step'
        }
    },
    unloadStep: {
        addButton: 'Add Unload Step',
    },
    work: {
        overview: {
            title: 'Jobs',
        },
        details: {
            title: 'R{{route.id}} A{{work.activity.id}} J{{work.id}}',
            navigateButton: 'Start Navigation: {{city}}',
            startButton: 'Start Job',
            finishButton: 'Done!',
            footer: {
                workDetails: 'Details',
                workMap: 'Location',
                workComments: 'Comments',
                workResults: 'Results',
            },
        },
        activity: {
            earlyRemove: 'Early remove'
        },
        field: {
            id: {
                label: 'JID'
            },
            category: {
                label: 'Category'
            },
            region: {
                label: 'Region',
                value: 'Region {{region}}',
            },
            dimensions: {
                label: 'Dimensions'
            },
            quality: {
                label: 'Print Quality'
            },
            print_remarks: {
                label: 'Print & Crop remarks'
            },
            workshop_remarks: {
                label: "Workshop remarks"
            },
            lighting: {
                label: 'Lighting'
            },
            steps: {
                label: 'Steps'
            },
            city: {
                label: 'City'
            },
            duration: {
                label: 'Work'
            },
            activity: {
                label: 'Activity',
            },
            activityType: {
                label: 'Activity Type',
            },
            route: {
                label: 'Route',
            },
            hasInstructions: {
                no: 'No instructions for activity'
            },
            isArtworkDone: {
                no: 'Artwork is not yet done'
            },
            hasOpenDownPayment: {
                label: 'Has open down payment',
                yes: 'Down Payment has not been paid yet',
            },
        },
        stats: {
            todo: 'Area todo this week',
            done: 'Area done this week',
        },
    },
    order: {
        addButton: 'Add order',
        addProductButton: 'Add product',
        addResultButton: 'Add result',
        field: {
            id: { label: 'OID' },
            orderProcessorOverride: {
                label: 'Order Processor',
                inherit: 'Inherit from project',
            },
            products: {
                label: 'Products',
            },
            reference: {
                label: 'Reference',
            },
            poNumber: {
                label: 'PO Number',
            },
            activities: {
                label: 'Activities',
            },
            todos: {
                label: 'Todo\'s',
            },
            project: {
                label: 'Project',
                warningNew: 'This is a new project.',
            },
            permitPerson: {
                label: 'Permit Requester',
            },
            projectContactOverride: {
                label: 'Quotation Contact',
                inherit: 'Inherit from project',
                warning: 'This order overrides the project contact.'
            },
            invoiceCustomerOverride: {
                label: 'Invoice Customer',
                inherit: 'Inherit from project',
                warning: 'This order overrides the project customer.'
            },
            invoiceContactOverride: {
                label: 'Invoice Contact',
                inherit: 'Inherit from project',
                warning: 'This order overrides the project contact.'
            },
            permitContact: { label: 'Permit Contact' },
            permitUser: { label: 'Permit User' },
        },
        overview: {
            title: 'Orders',
        },
        edit: {
            uploadLocations: 'Locations CSV',
            attachments: {
                add: 'Add attachment',
            },
            options: {
                label: 'Option {{reference}}',
                items: '{{count}} item',
                items_plural: '{{count}} items',
                placeholder: 'Description...',
                add: 'Add option',
            },
            filter: {
                activityId: 'Activity ID',
                productId: 'Product ID',
                minDate: 'Min Date',
                maxDate: 'Max Date',
            },
            searchPermitRequester: 'Search permit requester',
            copyActivitiesFromProductButton: 'Copy activities to {{count}} selected products',
            bulkActionButton: {
                choose: 'Choose Bulk Action ({{count}})',

                copyActivities: 'Copy Activities ({{count}})',

                addToOrderQuotation: 'Add to Quotation ({{count}})',
                removeFromOrderQuotation: 'Remove from Quotation ({{count}})',

                addToProjectQuotation: 'Add to Project Quotation ({{count}})',
                removeFromProjectQuotation: 'Remove from Project Quotation ({{count}})',

                addToOrderInvoice: 'Add to Invoice ({{count}})',
                removeFromOrderInvoice: 'Remove from Invoice ({{count}})',

                addToProjectInvoice: 'Add to Project Invoice ({{count}})',
                removeFromProjectInvoice: 'Remove from Project Invoice ({{count}})',

                approveActivities: 'Approve Activities ({{count}})',
                cancelActivities: 'Cancel Activities ({{count}})',

                linkInstructions: 'Link instructions ({{count}})',
                finalize: 'Finalize',
            },
            selectButton: {
                text: 'Select Activities',
                select: 'Select {{name}} ({{count}})',
                unselect: 'Unselect {{name}} ({{count}})',
                item: {
                    all: 'All',
                    draft: 'Draft',
                    toBeInvoiced: 'To be Invoiced',
                },
            },
            quotation: {
                rejected: 'Rejected',
                rejectAndDraft: 'Reject & Draft',
                accepted: 'Accepted',
                approveDiscount: 'Approve Discount',
                rejectDiscount: 'Reject Discount',
                needsReview: 'Needs Review',
            },
            invoice: {
                accepted: 'Accepted',
                approveDiscount: 'Approve Discount',
                rejectDiscount: 'Reject Discount',
                needsReview: 'Needs Review',
            },
            categoryOrder: 'Order',
            title: 'Project {{project.id}}: {{project.name}} - Order {{order.id}}',
            addProductButton: 'Add product',
            addStickerButton: 'Add sticker',
            addModifierButton: 'Add modifier',
            addExtraDesignButton: 'Add extra design',
            addProduct: {
                title: 'Add {{name}}',
                nextButton: 'Next',
                step1: {
                    title: '1. Choose product',
                },
                step2: {
                    title: '2. Configure',
                },
                genericError: 'Something went wrong.',
            },
            addResult: {
                title: 'Add result',
                text: 'Select (multiple) image',
                image: 'Add image',
            },
            productTab: 'Products',
            inactiveproductTab: 'Inactive Products',
            quotationTab: 'Quotations',
            invoiceTab: 'Invoices',
            resultTab: 'Results',
            productSegment: {
                active: 'Active Products ({{count}})',
                done: 'Done Products ({{count}})',
                cancelled: 'Cancelled Products ({{count}})',
            },
        },
        add: {
            title: 'Project {{project.id}}: {{project.name}} - New Order',
        },
        searchProject: {
            title: 'Search Project',
        },
    },
    route: {
        add: {
            title: 'Add Route',
            aid: 'AID',
            jid: 'JID',
            start: 'Start',
            end: 'End'
        },
        details: {
            title: 'Route {{id}} details'
        },
        addWorks: {
            addSelectedButton: 'Add selected ({{count}}) jobs',
            jobId: 'JID',
            activityId: 'AID',
        },
        edit: {
            title: 'Edit Route {{id}}',
            emptyWorks: 'No jobs have been added yet...',
            addWorkButton: 'Add jobs',
            finalizeButton: 'Finalize',
            onlyAvailable: 'Only available',
            onlyRouteDate: 'Only for route date',
            googleApiLimitHeader: 'Route Calculation Disabled',
            googleApiLimitContent: 'This route contains too many jobs to be calculated.',
            optimizeButton: 'Optimize',
            optimizeInfeasible: 'Optimization of this route is not feasible with the existing constraints.',
            showOtherRoutes: 'Show other routes',
            addWorkModal: {
                title: 'Add Jobs',
            },
        },
        statistic: {
            km: 'KM',
            workday: 'Total workday',
            travelTime: 'Travel time',
            workTime: 'Work time'
        },
        overview: {
            title: 'Routes',
            time: 'Time',
            distance: 'Distance',
            filter: {
                activityId: 'Activity ID',
                productId: 'Product ID',
                customerName: 'Customer Name',
            },
        },
        field: {
            date: {
                label: 'Date',
                prefixedLabel: 'Route Date',
            },
            mainEngineer: {
                label: 'Main Engineer'
            },
            otherEngineers: {
                label: 'Other engineers'
            },
            mainVehicle: {
                label: 'Main vehicle'
            },
            otherVehicles: {
                label: 'Other vehicles'
            },
            id: {
                label: 'RID'
            },
            remaining: {
                label: 'Remaining'
            },
            time: {
                label: 'Time'
            },
            completed: {
                label: 'Completed'
            },
            status: {
                label: 'Status',
                value: {
                    waiting: 'Waiting',
                    ready_to_go: 'Ready To Go',
                    in_progress: 'In Progress',
                    done: 'Done',
                },
                waitingReason: {
                    dep: 'J{{work.id}} is still waiting on J{{dep.id}}.',
                    noInstructions: 'J{{work.id}} has no instructions.',
                    constraint: 'J{{work.id}}: {{constraint}}',
                    openDownPayment: 'J{{work.id}}: down payment has not been paid yet',
                },
            },
            project: {
                label: 'Project'
            },
            activity: {
                label: 'Activity'
            },
            finalized: {
                label: 'Finalized'
            },
            startLocation: {
                label: 'Start Location',
            },
        }
    },
    project: {
        overview: {
            title: 'Projects',
            filter: {
                activityStatus: 'Activity Status',
                activityType: 'Activity Type',
                accountManager: 'Account Manager',
                orderProcessor: 'Order Processor',
                canvasSwapTotalBalance: { label: 'Has Canvas Swap Activity Balance' },
                moveTotalBalance: { label: 'Has Move Activity Balance' },
            },
            merge: {
                projects: '{{count}} project',
                projects_plural: '{{count}} projects',
                orders: '{{count}} order',
                orders_plural: '{{count}} orders',
                mergeButton: {
                    start: 'Merge',
                    done: 'Merge',
                    cancel: 'Cancel',
                },
                mergeModal: {
                    title: 'Select Merge Target',
                    error: 'Some errors occurred during the merge.',
                    newProjectButton: 'New Merge Target',
                },
                newProjectModal: {
                    title: 'New Merge Target',
                },
            },
        },
        edit: {
            title: 'Project {{id}}: {{name}}',
        },
        add: {
            title: '[unsaved] {{name}}',
        },
        field: {
            id: { label: 'PJID' },
            name: { label: 'Name' },
            customer: { label: 'Customer' },
            branchOverride: {
                label: 'Branch',
                inherit: 'Inherit branch',
                warning: 'This project overrides the customer\'s branch.',
            },
            accountManagerOverride: {
                label: 'Account Manager',
                inherit: 'Inherit account manager',
                warning: 'This project overrides the customer\'s account manager.',
            },
            source: { label: 'Source' },
            orders: {
                label: 'Orders',
            },
            design: {
                label: 'Design',
            },
            designs: {
                label: 'Designs',
            },
            contact: {
                label: 'Contact'
            },
            poNumber: {
                label: 'PO Number',
            },
            invoiceCustomerOverride: {
                label: 'Invoice Customer',
                inherit: 'Inherit from project',
                warning: 'This order overrides the project customer.'
            },
            invoiceContactOverride: {
                label: 'Invoice Contact',
                inherit: 'Inherit from project',
                warning: 'This order overrides the project contact.'
            },
            activity: {
                requirements: 'Requirements'
            },
            branch: { label: 'Branch' },
            entity: { label: 'Entity' },
            quotations: { label: 'Quotations' },
            invoices: { label: 'Invoices' },
            canvasSwapTotalBalance: { label: 'Canvas Swap Activity Total Balance' },
            moveTotalBalance: { label: 'Move Activity Total Balance' },
        },
    },
    noteType: {
        addButton: 'Add note type',
        field: {
            name: { label: 'Name' },
            statusOptions: { label: 'Status Options' },
            addStatus: { label: 'Add Statuses?'},
            statusName: { label: 'Status Name' },
        },
        overview: {
            title: 'Note Types',
        },
        edit: {
            title: 'Edit Note Type',
        },
        create: {
            title: 'Create Note Type',
        },
    },
    componentType: {
        addButton: 'Add component type',
        field: {
            name: { label: 'Name' },
            managed: { label: 'Managed' },
            availability: {
                available: { label: 'Available' },
                expected_return: { label: 'Expected Return' },
                draft: { label: 'Draft' },
                quoted: { label: 'Quoted' },
                approved: { label: 'Approved' },
                placed: { label: 'Placed' },
                scrapped: { label: 'Scrapped' },
                sold: { label: 'Sold' },
                defective: { label: 'Defective' },
            },
        },
        overview: {
            title: 'Component types',
        },
        edit: {
            title: 'Edit Component type',
        },
        create: {
            title: 'Create Component type',
        },
        availabilityModal: {
            title: 'Component availability',
        },
        availabilityButton: 'Availability',
    },
    componentOrder: {
        addButton: 'Add component order',
        field: {
            componentType: { label: 'Component type' },
            quantity: { label: 'Quantity' },
            startDate: { label: 'Start Date' },
        },
        overview: {
            title: 'Component orders',
        },
        edit: {
            title: 'Edit component order',
        },
        create: {
            title: 'Create component order',
        },
        fillModal: {
            title: 'Fill component order',
            description: 'Please enter one identifier per line.',
            fillNotification: 'Created {{n}} components.',
            fillDone: 'Order completely filled.',
        },
    },
    component: {
        addButton: 'Add component',
        field: {
            componentType: { label: 'Component type' },
            identifier: { label: 'Identifier' },
            startDate: { label: 'Start Date' },
        },
        overview: {
            title: 'Components',
        },
        edit: {
            title: 'Edit Component',
        },
        create: {
            title: 'Create Component',
        },
    },
    plannedStep: {
        type: {
            batch: { label: 'Batch' },
            rip: { label: 'Rip' },
            print: { label: 'Print' },
            finish: { label: 'Finish' },
            load: { label: 'Load' },
            unload: { label: 'Unload' },
            instruction: { label: 'Instruction' },
            scan: { label: 'Scan' },
        },
        perform: {
            title: 'Perform Step',
            engineer: 'Engineer...',
        },
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: "We’re very sorry, but the application has crashed.",
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    startError: {
        maintenance: 'Server maintenance is being performed right now.',
        line1: 'Our server has failed us. We’re very sorry.',
        line2: 'A notification has been sent to us with the details of this issue.'
    },
    display: {
        field: {
            name: { label: 'Name' },
            width: { label: 'Width' },
            height: { label: 'Height' },
        },
        splitDisplays: {
            title: 'Split Displays',
        },
        splitDisplay: {
            title: 'Split Display',
            panel: '{{display.name}} - panel {{number}}',
        },
    },
    panel: {
        chooseDesignModal: {
            title: 'Choose design for display ({{project.customer.name}} / {{project.name}})',
        },
        field: {
            display: { label: 'Display' },
            name: { label: 'Name' },
            width: { label: 'Width' },
            height: { label: 'Height' },
        },
    },
    tooltip: {
        delete: 'Delete',
        reset: 'Reset',
        hardDeadline: 'Hard Deadline',
        select: 'Select',
    },
    panelAllocation: {
        field: {
            id: {
                label: 'ID',
            },
            downloadLink: {
                label: 'Download Link',
            },
        },
    },
    workBatch: {
        field: {
            area: { label: 'Area' },
        },
        download: {
            title: 'Download Print Files',
            printForm: 'Print Form',
        },
    },
    constantOverride: {
        overview: {
            title: 'Constant Overrides',
        },
        field: {
            constant: { label: 'Constant' },
            value: { label: 'Value' },
        },
    },

    user: {
        twoFactorAuth: {
            title: 'Confirm',
            code: 'Code',
            resend: 'Resend',
            explanation: 'A confirmation code has been sent to your registered phone number.',
            explanationFailed: 'An attempt to send a confirmation code to your registed phone number was made but failed.',
            expiring: 'Your authentication will expire in {{ minutes }} minutes.',
        },
        overview: {
            title: 'Employees',
            fullName: 'Name',
            id: 'ID',
            addButton: 'Add Employee',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            signature: { label: 'Signature' },
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            phoneNumber: { label: 'Phone Number', },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            isActive: {
                label: 'Is Active User',
                options: {
                    yes: 'True',
                    no: 'False',
                    all: 'Both',
                }
            },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Super User',
                    admin: 'Admin',
                    account_manager: 'Account Manager',
                    order_processor: 'Order Processor',
                    junior_engineer: 'Junior Engineer',
                    engineer: 'Engineer',
                    senior_engineer: 'Senior Engineer',
                    designer: 'Designer',
                    planner: 'Planner',
                    empty: 'No Roles',
                    printer_operator: 'Printer operator',
                    production_employee: 'Production employee',
                    production_view: 'Production view',
                    vehicle: 'Vehicle',
                    sales_manager: 'Sales Manager',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: "Language"
            },
            entity: {
                label: 'Entity',
            },
            authentications: {
                label: 'Authentications',
            },
            deleted: { label: 'Archived' },
            linkedSalesManager: { label: 'Sales Manager' },
            maximumQuotationDiscountAmount: {
                label: 'Maxium quotation discount amount'
            },
            maximumQuotationDiscountPermille: {
                label: 'Maxium quotation discount percentage'
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}', },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. You can only request a forgotten password if you are logged out.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. You can only reset your password if you are logged out.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Change value of general setting',
        },
        overview: {
            title: 'General settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'File name',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Original User',
            },
            masqueradingAsUser: {
                label: 'Masked as a user',
            },
            ipAddress: {
                label: 'IP-address',
            },
            method: {
                label: 'HTTP-method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status Code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },

    form: {
        search: 'Search',
        saveButton: 'Save',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        idSearch: 'Search for IDs',
        pdfLoading: 'Loading PDF',
        pdfError: 'Failed loading PDF',
        discardChanges: 'You have unsaved changes. Continuing will discard your changes.',
        all: 'All',
        none: 'None',
        fillButton: 'Fill',
        backButton: 'Back',
        resetButton: 'Reset',
        repeat: 'Repeat',
        updateButton: 'Update',
        submitButton: 'Submit',
        viewButton: 'View',
        uploadButton: 'Upload',
        yes: 'Yes',
        no: 'No',
        either: 'Either',
        up: 'Up',
        down: 'Down',
        hide: 'Hide',
        show: 'Show',
        edit: 'Edit',
        delete: 'Delete',
        confirmButton: 'Confirm',
        refreshButton: 'Refresh',
        nextButton: 'Next',
        doneButton: 'Done',
        selectButton: 'Select',
        enabledWhenExisiting: 'Only available after saving',
        copyButton: 'Copy',
        pasteButton: 'Paste',
        zipButton: 'Zip',
        saveAndNewButton: 'Save & new',
        saveLoadingButton: 'Saving…',
        sendButton: 'Send',
        csvButton: 'CSV',
        editButton: 'Edit',
        selectAllButton: 'Select all',
        addButton: 'Add',
        newButton: 'New',
        searchModalButton: 'Pick',
        deleteButton: 'Delete',
        clearButton: 'Clear',
        cancelButton: 'Cancel',
        deletedWaivers: 'Deleted waivers:',
        closeButton: 'Close',
        unfinalizeButton: 'Unfinalize',
        searchLabel: 'Search',
        applyButton: 'Apply',
        continueButton: 'Continue',
        confirmQuestion: 'Are you sure?',
        selectPlaceholder: 'Select…',
        dateRangePlaceholder: 'Select date…',
        unfinalizeConfirmation: 'Are you sure you want to unfinalize {{name}}?',
        deleteConfirmation: 'Are you sure you want to delete {{name}}?',
        restoreConfirmation: 'Are you sure you want to restore {{name}}?',
        typeToSearchText: 'Type to search…',
        copyToClipboardTooltip: 'Copy to clipboard',
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            requestScanSuccess: 'Request scan successfully',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
            mailUpdateSentNotification:
                'Email update sent to customer successfully',
            mailUpdateNoTemplateNotification:
                "Can't send update e-mail without a template for this customer",
        },
        validationErrors: {
            blank: 'Field is required',
            null: 'Field is required',
            max_digits: 'Maximum field value exceeded',
            max_whole_digits: 'Maximum field value exceeded',
            max_value: 'Maximum value exceeded',
            invalid: 'Invalid value',
            reset_code_invalid: 'Invalid reset code',
            password_too_common: 'Password is too common',
            invalid_token: 'Invalid token',
            existing_invoices: 'There are existing invoices with these settings',
            invoice_number_duplicate: 'Invoice with this Invoice number already exists. Automatically changed the Invoice number and saved it again.',
            unique: 'Field is not unique',
            unique_together: 'Field is not unique',
            unknown: 'Unknown error in field', // Fallback translation, this should never happen.
            date_mismatch: 'End date may not lie before start date',
            routing_error:
                'Could not calculate route between this activity and previous one',
            geo: {
                'failed-error': 'Unknown error with finding location',
                'failed-no-results': 'Could not find location',
                'failed-multiple-results': 'Found too many locations',
                'failed-no-housenumber': 'Did not find specific house number',
                not_precise: 'Found location, but not precise',
            },
        },
        startDate: 'Min Date',
        endDate: 'Max Date',
        fileType: {
            none: 'No file',
            image: 'Image',
            pdf: 'PDF',
            xls: 'XLS',
            any: '',
        },
    },
    docs: {
        button: 'Docs',
        title: 'Documentation',
        func: 'Functions',
        search: 'Search docs...',
    },

    home: {
        title: 'Dashboard',
        works: 'Jobs',
        extras: 'Extras',
        requirements: 'Components',
    },
    artwork: {
        detailsModal: {
            title: 'Details ({{project.customer.name}} / {{project.name}})',
        },
        board: {
            title: 'DTP',
            filter: {
                customer: 'Customer',
                taskType: 'Task Type',
                hideStatuses: 'Hidden Statuses',
                taskCreator: 'Task Creator',
            },
            basicInfo: 'Basic Info',
            comments: 'Comments',
            column: {
                todo: { label: 'To Do' },
                in_progress: { label: 'In Progress' },
                validation: { label: 'Validation' },
                on_hold: { label: 'On Hold' },
                done: { label: 'Done' },
            },
            label: {
                prepress: 'Prepress',
                design: 'Design',
                check: 'Check',
                rework: 'Rework',
            },
            invalidStatusMessage: 'This status is not allowed for this artwork.',
            groupButton: 'Group Designs ({{count}})',
        },
    },
    source: {
        overview: {
            title: 'Source'
        },
        create: {
            title: 'Create source'
        },
        edit: {
            title: 'Edit source'
        },
        field: {
            id: {
                label: 'id'
            },
            name: {
                label: 'Name'
            }
        }
    },
    activeUsers: {
        chat: {
            newMessage: 'New Message',
            sendMessage: 'Send Message (CTRL + ENTER)',
            noMessages: 'No messages sent yet.',
            unavailable: '{{name}} is currently not available.',
            notRead: 'Not read yet.',
        },
        search: {
            placeHolder: 'Search on Name',
        },
    },
    kvk: {
        field: {
            name: { label: 'Name' },
            kvkNumber: { label: 'KvK Number' },
            vatNumber: { label: 'VAT Number' },
            websites: { label: 'Websites' },
        },
    },
    translation: {
        overview: {
            title: 'Translations',
        },
        field: {
            key: { label: 'Key' },
            textNl: { label: 'Text NL' },
            textFr: { label: 'Text FR' },
            textDe: { label: 'Text DE' },
            textUk: { label: 'Text UK' },
        },
    },
    translationOverride: {
        field: {
            translation: { label: 'Translation' },
            textNl: { label: 'Text NL' },
            textFr: { label: 'Text FR' },
            textDe: { label: 'Text DE' },
            textUk: { label: 'Text UK' },
        },
    },
    dashboard: {
        title: 'Dashboard',
        filter: {
            window: {
                label: 'Window',
                value: {
                    day: 'Day',
                    week: 'Week',
                    month: 'Month',
                    year: 'Year',
                    all: 'All',
                },
            },
            accountManager: { label: 'Account Manager' },
            customer: { label: 'Customer' },
        },
    },
    dates: {
        month: {
            '0': 'January',
            '1': 'February',
            '2': 'March',
            '3': 'April',
            '4': 'May',
            '5': 'June',
            '6': 'July',
            '7': 'August',
            '8': 'September',
            '9': 'October',
            '10': 'November',
            '11': 'December',
        },
        weekDayShort: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        minDate: {
            placeholder: 'Min Date',
        },
        maxDate: {
            placeholder: 'Max Date',
        },
    },
    componentAvailability: {
        overview: {
            title: 'Component availability',
            window: 'Window',
        },
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        period: {
            pm: 'PM',
            am: 'AM',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    extra: {
        field: {
            nameNl: { label: 'Name NL' },
            nameUk: { label: 'Name UK' },
            nameFr: { label: 'Name FR' },
            nameDe: { label: 'Name DE' },
            defaultPrice: { label: 'Default Price' },
            ledger: { label: 'Ledger' },
        },
        overview: {
            title: 'Extras',
        },
        addButton: 'Add Extra',
    },
    partialInvoice: {
        creditButton: 'Credit Partial Invoice',
        creditConfirm: 'Are you sure you want to credit this partial invoice?',
        reminderButton: 'Send Reminder of Partial Invoice',
        field: {
            status: {
                label: 'Status',
                value: {
                    draft: 'Draft',
                    scheduled: 'Scheduled',
                    send: 'Sent',
                    credit: 'Credited',
                },
            },
            customerOverride: {
                label: 'Customer',
                warning: 'This partial invoice overrides the customer.',
                inherit: 'Inherit from order',
            },
            amount: { label: 'Amount' },
            scheduledDate: { label: 'Send Date', },
            exactInvoiceNumber: { label: 'Invoice Number' },
            creditExactInvoiceNumber: { label: 'Credit Invoice Number' },
            emailFrom: { label: 'Email From' },
            emailTo: { label: 'Email To' },
            emailCc: { label: 'Email CC' },
            creditEmailFrom: { label: 'Email From' },
            creditEmailTo: { label: 'Email To' },
            creditEmailCc: { label: 'Email CC' },
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
    chargeActivity: {
        field: {
            price: { label: 'Price' },
        },
    },
    startLocation: {
        field: {
            name: { label: 'Name' },
            default: { label: 'Default' },
            location: { label: 'Location' },
        },
        create: {
            title: 'Create start location',
        },
        edit: {
            title: 'Edit start location',
        },
        overview: {
            title: 'Start Locations',
            addButton: 'Add start location',
        },
    },
    downPayment: {
        add: {
            title: 'Add Down Payment',
        },
        edit: {
            title: 'Edit Down Payment',
            markPaid: 'Paid',
        },
        view: {
            title: 'View Down Payment',
        },
        overview: {
            title: 'Down Payments',
        },
        field: {
            status: {
                label: 'Status',
                value: {
                    draft: 'Draft',
                    sent: 'Sent',
                    paid: 'Paid',
                },
            },
            emailFrom: { label: 'Email From' },
            emailTo: { label: 'Email To' },
            emailCc: { label: 'Email CC' },
            emailSubject: { label: 'Email Subject' },
            emailBody: { label: 'Email Text' },
            salutation: { label: 'Salutation' },
            greeting: { label: 'Greeting' },
            total: { label: 'Total' },
            quotation: { label: 'Quotation' },
            amount: { label: 'Amount' },
            exactInvoiceNumber: { label: 'Invoice Number' },
            sentAt: { label: 'Sent Date' },
            invoicePdfLayout: {
                label: 'Down Payment PDF layout',
                value: {
                    project: 'Project layout',
                    original: 'Original layout (simplified)',
                },
            },
        },
    },
    batchStep: {
        addButton: 'Add Batch Step',
    },
    prepaidCanvasSwapActivity: {
        field: {
            quantity: { label: 'Quantity' },
            balance: { value: '{{balance}}/{{quantity}} left' },
        },
    },
    prepaidMoveActivity: {
        field: {
            quantity: { label: 'Quantity' },
            balance: { value: '{{balance}}/{{quantity}} left' },
        },
    },
    exportActivity: {
        field: {
            order: { label: 'Order' },
            importActivity: { label: 'Import Activity' },
        },
    },
    prepress: {
        field: {
            assignedTo: { label: 'Assigned To' },
            downloadLink: { label: 'Download Link', },
            priority: { label: 'Priority' },
            status: { label: 'Status' },
            activities: {
                value: '{{count}} activity',
                value_plural: '{{count}} activities',
                more: ' and {{count}} more',
            },
        },
    },
};
