import { observable, computed, action } from 'mobx';
import { get } from 'lodash';
import { Socket, generateUrl } from 'spine-high-templar';
import axios from 'axios';
import i18next from 'i18next';
import { t } from 'i18n';


export class ClosableSocket extends Socket {
    _closed = false;

    initialize(...args) {
        if (this._closed) {
            return undefined;
        }
        return super.initialize(...args);
    }

    send(...args) {
        if (this._closed) {
            return Promise.reject();
        }
        return super.send(...args);
    }

    get closed() {
        return this._closed;
    }

    _initiatePingInterval() {
        this._stopPingInterval();
        super._initiatePingInterval();
    }

    close() {
        this._closed = true;
        this._stopPingInterval();
        this.instance.close();
        this.instance = null;
    }
}


export default class ViewStore {
    @observable api = null;
    @observable currentUser = null;
    @observable bootstrapCode = null;
    @observable notifications = [];
    @observable.ref currentModal = null;
    @observable commitId = null;
    @observable socketUrl = null;
    @observable isMasqueraded = null;
    @observable theme = {};

    @computed
    get isAuthenticated() {
        return this.currentUser.id !== null;
    }

    @action
    setModal(modal) {
        this.currentModal = modal;
    }

    constructor({ api, user, socketUrl = null, fetchBootstrap = true }) {
        this.api = api;
        this.currentUser = user;
        this.socketUrl = socketUrl;
        this.api.onRequestError = this.handleRequestError;

        if (fetchBootstrap) {
            this.fetchBootstrap();
        }
    }

    handleBuildInfoPublish = e => {
        this.checkAppVersion(e.data.commit_id);
    };

    handleBuildInfoReconnect = e => {
        this.api.get('/bootstrap/').then(res => {
            this.checkAppVersion(res.build_info.commit_id);
        });
    };

    checkAppVersion(newCommitId) {
        if (newCommitId !== this.commitId) {
            // Randomize new version notification so that users won't refresh
            // all at the same time and kill the server.
            setTimeout(() => {
                this.showNotification({
                    key: 'newAppVersion',
                    dismissAfter: false,
                    dismissible: false,
                    onClick: () => document.location.reload(),
                    message: t('form.notifications.newAppVersion'),
                });
            }, Math.round(Math.random() * 60000));
        }
    }

    handleRequestError = err => {
        // Thanks Zaico for this typo...
        const supressError = get(err, 'response.config.supressError', false);

        if (typeof supressError === 'function' ? supressError(err) : supressError) {
            return;
        }

        if (err instanceof axios.Cancel) {
            return;
        }

        // Try to show http status code to user
        const status = get(err, 'response.status', err.message);
        let message = '';
        switch (status) {
            case 400:
                message = t('form.notifications.saveValError');
                break;
            case 403:
                message = t('form.notifications.saveAuthError');
                break;
            default:
                message = t('form.notifications.saveError', { status });
                break;
        }
        this.showNotification({
            key: 'requestError',
            error: true,
            dismissAfter: 4000,
            message,
            icon: 'exclamation triangle',
        });
    };

    setupSocket() {
        if (!this.socketUrl) {
            return;
        }
        this.destroySocket();

        this.api.socket = new ClosableSocket({
            url: generateUrl(this.socketUrl),
        });
        this.api.socket.subscribe({
            onPublish: this.handleBuildInfoPublish,
            // onReconnect: this.handleBuildInfoReconnect,
            room: { '*PUBLIC*': 'build_info' },
        });
    }

    destroySocket() {
        if (this.api.socket) {
            this.api.socket.close();
            this.api.socket = null;
        }
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({ data: res.user });
    }

    @action
    handleBootstrap(res) {
        this.bootstrapCode = 200;

        if (res.build_info) {
            this.commitId = res.build_info.commit_id;
        }

        if (res.user) {
            this.parseCurrentUserFromBootstrap(res);
            this.setupSocket();
        } else {
            this.currentUser.clear();
            this.destroySocket();
        }

        this.api.csrfToken = res.csrf_token;
        this.isMasqueraded = res.is_masqueraded;
        this.theme = res.theme;

        if (res.language) {
            i18next.changeLanguage(res.language);
        }

        return res;
    }

    @action
    fetchBootstrap() {
        this.bootstrapCode = null;
        // You can see here that we use `action()` twice. `action()` is kind of a transaction (events will be fired only when it's done)
        // Technically we wouldn't need the @action in this case (since you only change stuff in the Promise).
        return this.api
            .get(
                '/bootstrap/',
                this.currentUser.api.buildFetchModelParams(this.currentUser)
            )
            .then(this.handleBootstrap.bind(this))
            .catch(err => {
                this.bootstrapCode = get(err, 'response.status', 500);
                throw err;
            });
    }

    @action
    performLogin(username, password, options = { usernameField: 'username' }) {
        const data = { password };

        data[options.usernameField] = username;

        return this.api
            .post('/user/login/', data)
            .then(() => this.fetchBootstrap());
    }

    @action
    performLogout() {
        return this.api
            .post('/user/logout/')
            .then(() => this.fetchBootstrap());
    }

    /**
     * Show a notification to the user. Msg:
     *
     * {
     *   id / key: 'some key', // Unique id, only show 1 notification for multiple keys,
     *   type: 'error',
     *   dismissAfter: 1000, // Auto dismiss notification after x number of milliseconds.
     *   sticky / dismissible: false, // These keep around, for example for a new version message.
     *   header: 'This message!',
     *   icon: 'exclamation triangle',
     *   content / message: 'Some content',
     * }
     */
    @action
    showNotification(msg) {
        // Notifications with the same key have the same contents, so we don't want to display them twice.
        // Existing ones are removed so the notification stays longer on the screen.

        // TODO:
        // - convert to store / model
        // - handle dismissAfter
        // - handle sticky
        const existingMsg = this.notifications.find(a => a.key === msg.key);
        if (existingMsg) {
            this.notifications.remove(existingMsg);
        }
        this.notifications.push(msg);
    }

    @action
    showSaveNotification() {
        this.showNotification({
            key: 'requestSave',
            dismissAfter: 4000,
            message: t('form.notifications.saveSuccess'),
        });
    }

    @action
    showDeleteNotification() {
        this.notifications.clear();
        this.showNotification({
            key: 'requestDelete',
            dismissAfter: 4000,
            message: t('form.notifications.deleteSuccess'),
        });
    }

    showGlobalValidationErrors(model, options = {}) {
        let globalErrors;
        if (options.showAll) {
            globalErrors = Object.values(model.backendValidationErrors);
        } else {
            globalErrors = model.getAllFlattenedWildcardErrors();
        }
        if (globalErrors) {
            globalErrors.forEach(error => {
                this.showNotification({
                    key: `requestSaveValError-${error}`,
                    dismissAfter: options.dismissAfter || false,
                    message: t(`form.validationErrors.${error}`),
                    type: 'error',
                });
            });
        }
    }
}
