export default {
    accordionEdit: {
        new: 'Nieuw',
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        restore: 'Terugzetten',
        view: 'Bekijken',
        select: 'Selecteren',
        close: 'Sluiten',
        send: 'Versturen',
    },
    operations : {
        binary : {
            and: 'en',
            or: 'of'
        },
        unary: {
            not: 'niet'
        }
    },
    enableDebug: 'Debug: Uitgeschakeld',
    disableDebug: 'Debug: Ingeschakeld',
    production: {
        overview: {
            date: 'Datum',
            activityDeadline: 'Deadline activiteit',
            stepDeadline: 'Deadline stap',
            project: 'Project',
            order: 'Order',
            canvas: {
                rip: 'Rip',
                print: 'Print canvas',
                finish: 'Afwerking canvas'
            }
        }
    },
    nav: {
        main: {
            artwork: 'DTP',
            mockup: 'Mockup',
            orders: 'Verkoop',
            planning: 'Planning',
            production: 'Productie',
            construction: 'Installatie',
            assets: 'Middelen',
            sales: 'CRM',
        },
        assets: {
            products: 'Producten',
            componentTypes: 'Component types',
            componentAvailability: 'Beschikbaarheid componenten',
            componentOrders: 'Orders componenten',
            components: 'Componenten',
            cars: 'Cars',
            printers: 'Printers',
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            entities: 'Entiteiten',
            productTypes: 'Product types',
            vehicle: 'Voertuigen',
            constants: 'Constanten',
            extras: 'Extras',
            branches: 'Branches',
            translations: 'Vertalingen',
            startLocations: 'Startlocaties',
            noteTypes: 'Soort Notitie',
            integrations: 'Integraties',
        },
        planning: {
            route: 'Routes',
            comment: 'Opmerkingen',
            work: 'Klussen',
        },
        production: {
            printing: 'Printen & afwerken',
            workshop: {
                trotter: 'Trotter werkplaats',
                frame: 'Frame werkplaats',
            },
        },
        artwork: {
            overview: 'Overzicht',
        },
        order: {
            dashboard: 'Dashboard',
            projects: 'Projecten',
            activities: 'Activiteiten',
            artwork: 'Grafisch bestand',
            products: 'Producten',
            quotations: 'Offertes',
            downPayments: 'Aanbetalingen',
            invoices: 'Facturen',
        },
        sales: {
            customers: 'Klanten',
            contacts: 'Contacten',
            source: 'Bronnen',
            tasks: 'Notities',
            results: 'Resultaten',
        },
        account: {
            account: 'Account',
        },
    },
    exactIntegration: {
        overview: {
            authButton: 'Activeer Exact Integratie',
            authResult: {
                ok: 'Exact integratie met succes geactiveerd.',
                error: {
                invalid_request: 'Ongeldig verzoek naar Exact verstuurd.',
                invalid_client: 'Niet geldige klant naar Exact verstuurd.',
                invalid_grant: 'Ongeldige toezegging naar Exact verstuurd.',
                invalid_scope: 'Ongeldig domein naar Exact verstuurd. ',
                unauthorized_client: 'Niet geautorizeerde klant naar Exact verstuurd.',
                unsupported_grant_type: 'Niet ondersteunde toezegging naar Exact verstuurd.',
                },
            },
        },
        create: { title: 'Exact Integratie toevoegen'},
        edit: { title: 'Exact Integratie aanpassen'},
        field: {
            type: {
            label: 'Type',
            value: {
                exact: 'Exact',
            },
            },
            name: { label: 'Naam' },
            country: { label: 'Land' },
            division: { label: 'Divisie' }
        },
        integration: {
            overview: {
                title: 'Integraties',
                empty: 'Geen integraties gevonden.',
                addButton: 'Integratie toevoegen',
                inactive: 'Deze integratie is niet actief.',
            },
            create: {
                title: 'Integratie toevoegen',
            },
            edit: {
                title: 'Integratie aanpassen',
            },
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        }
    },
    printing: {
        overview: {
            title: 'Printen & afwerken'
        },
        waitingOnArtwork: 'Wachtend op grafisch bestand',
    },
    performance: {
        field: {
            plannedStep: {
                label: 'Stap',
            },
            engineer: {
                label: 'Engineer',
            },
            createdAt: {
                label: 'Gedaan op'
            },
        }
    },
    planning: {
        customer: {
            panelTitles: {
                customerCode: 'Klantcode',
            },
        },
        activityFinalize: {
            currentActivityLabel: 'Huidige activiteit',
            previousActivityLabel: 'Vorige activiteit',
        },
    },
    assembly: {
        overview: {
            title: {
                trotter: 'Trotter werkplaats',
                frame: 'Frame werkplaats',
            },
        },
    },
    taskPostponeDeadline: {
        field: {
            addDeadline: {
                label: 'Deadline Toevoegen'
            },
            postponeTo: {
                label: 'Uitstellen Tot'
            },
            postponeFrom: {
                label: 'Uitstellen Van'
            },
            postponeReason: {
                label: 'Reden voor uitstel'
            },
            addDeadlineReason: {
                label: 'Reden voor het toevoegen van deadline'
            },
        },
    },
    taskDocument: {
        field: {
            document: {
                label: 'Document ID:{{ number }}',
            },
        },
    },
    task: {
        edit: {
            comments: 'Opmerkingen',
            newComment: 'Nieuwe opmerking',
            sendComment: 'Verstuur opmerking (CTRL + ENTER)',
            title: 'Notitie {{id}}: {{task.subject}}',
        },
        add: {
            title: '[unsaved] {{task.subject}}',
        },
        editModal: {
            title: 'Notitie bewerken',
        },
        overview: {
            title: 'Notities',
            filter: {
                accountManager: 'Account Manager',
                assignee: 'Toegewezen aan',
            }
        },
        create: {
            title: 'Aanmaken'
        },
        addNoteModal: {
            title: 'Notitie toevoegen',
            addNewButton: 'Opslaan',
            label: 'Notitie toevoegen'
        },
        noteContent: {
            content: 'Submitted by: {{submitter}} ',
            contentDate: ' On: {{date}}',
            description: 'Omschrijving :',
            comments: 'Opmerkingen :',
        },
        addButton: {
            upload: 'Uploaden',
            uploadAnother: 'Upload Een Andere',
        },
        field: {
            documentTitle: {
                label: 'Documenten',
            },
            document: {
                label: 'Document toevoegen',
            },
            submittedBy: {
                label: 'Indiener',
            },
            submittedAt: {
                label: 'Ingediend op',
            },
            customer: {
                label: 'Customer'
            },
            subject: {
                label: 'Onderwerp'
            },
            body: {
                label: 'Omschrijving'
            },
            assignee: {
                label: 'Toegewezen aan'
            },
            deadline: {
                label: 'Deadline',
                postpone: 'Deadline Uitstellen',
                addDeadline: 'Deadline Toevoegen',
            },
            postponeFrom: {
                label: 'Uitstellen Van',
            },
            postponeTo: {
                label: 'Uitstellen Tot',
            },
            postponeReason: {
                label: 'Reden voor uitstel',
            },
            noteType: {
                label: 'Soort Notitie'
            },
            taskStatus: {
                label: 'Notitie Status'
            },
            tags: {
                label: 'Tags'
            },
            comments: {
                label: 'Commentaar',
            },
            id: {
                label: 'TID'
            },
            status: {
                label: 'Status',
                value: {
                    open: 'Open',
                    done: 'Afgerond'
                }
            },
            project: { label: 'Project' },
            order: { label: 'Order' },
            activity: { label: 'Activiteit' },
            quotation: { label: 'Offerte' },
            invoice: { label: 'Factuur' },
            contact: { label: 'Contact' },
            deleted: { label: 'Verwijderd' },
        }
    },
    comment: {
        overview: {
            done: 'Afgerond',
            title: 'Opmerkingen',
            onlyNonCompleted: 'Alleen niet afgeronde opmerkingen',
            activity: 'Activiteit',
            work: 'Klus',
        },
        add: {
            title: 'Opmerking toevoegen',
        },
        edit: {
            title: 'Opmerking bewerken',
        },
        field: {
            id: {
                label: 'ID',
            },
            text: {
                label: 'Tekst',
            },
            image: {
                label: 'Afbeelding',
            },
            route: {
                label: 'Route'
            },
            order: {
                label: 'Order'
            },
            contact: {
                label: 'Contact'
            }
        }
    },
    result: {
        overview: {
            title: 'Resultaten Galerij',
            filter: {
                customer: 'Customer',
                limit: 'Afbeeldingen per pagina',
            }
        },
        edit: {
            title: 'Result {{id}}',
        },
        field: {
            id: {
                label: 'ID',
            },
            text: {
                label: 'Tekst',
            },
            image: {
                label: 'Afbeelding',
            },
            description: {
                label: 'Beschrijving',
            },
            date: {
                label: 'Datum',
            },
            work: {
                label: 'Werk',
            },
            customer: {
                label: 'Customer',
            },
        }
    },
    common: {
        delete: {
            error: 'Kon niet verwijderen!',
            success: 'Item is verwijderd',
        },
        filter: {
            deleted: 'Inclusief verwijderd',
        },
    },
    customerAddress: {
        overview: {
            title: 'Adressen',
        },
        addCustomerAddressModal: {
            title: 'Adres toevoegen',
            addNewButton: 'Sla op en voeg toe',
        },
        field: {
            id: {
                label: 'ID'
            },
            type: { label: 'Type' },
            isVisiting: { label: 'Bezoekaddres' },
            visitingEmail: { label: 'Bezoekadres | E-mail' },
            visitingContact: { label: 'Bezoekadres | Contact' },
            isQuotation: { label: 'Offerteadres' },
            quotationEmail: { label: 'Offerteadres | E-mail' },
            quotationContact: { label: 'Offerteadres | Contact' },
            isInvoicing: { label: 'Factuuradres' },
            invoicingEmail: { label: 'Factuuradres | E-mail' },
            invoicingContact: { label: 'Factuuradres | Contact' },
            description: {
                label: 'Beschrijving'
            },
            country: {
                label: 'Land',
                value: {
                    NL: 'Nederland',
                    BE: 'België',
                    DE: 'Duitsland',
                    LX: 'Luxemburg',
                    EN: 'England',
                    FR: 'Frankrijk'
                },
            },
            city: {
                label: 'Plaats'
            },
            contact: {
                label: 'Contact'
            },
            freeField: {
                label: 'Vrij veld'
            },
            street: {
                label: 'Straat'
            },
            number: {
                label: 'Huisnummer'
            },
            suffix: {
                label: 'Toevoeging'
            },
            postalCode: {
                label: 'Postcode'
            },
        },
    },
    modifier: {
        field: {
            kind: {
                label: 'Soort',
                value: {
                    euro: 'â‚¬',
                    permille: '%', // Permille modifier is shown as percentage in the frontend
                },
            },
            description: {
                label: 'Beschrijving',
            },
            amount: {
                label: 'Aantal',
            },
            afterQuotation: {
                info: 'Dit extra item werd toegevoegd nadat de offerte is goedgekeurd.',
            },
        },
    },
    extraPrice: {
        overview: {
            title: 'Extra prijzen',
        },
        field: {
            extra: { label: 'Extra' },
            price: { label: 'Prijs' },
        },
    },
    quotationSuccessRate: {
        field: {
            id: {
                label: 'ID',
            },
            rate: {
                label: 'Percentage',
            },
            user: {
                label: 'Gebruiker',
            },
            createdAt: {
                label: 'Aangemaakt op',
            },
        },
    },
    quotation: {
        overview: {
            title: 'Offerte',
            csvDownload: 'CSV downloaden',
        },
        downloadPdf: 'PDF downloaden',
        viewPdf: 'PDF bekijken',
        needsApproval: 'Wegens de gegeven kortingen zal deze offerte moeten worden goedgekeurd alvorens te worden verzonden.',
        sendQuotationModal: {
            title: 'Bekijk, bewerk & verstuur offerte',
            triggerButton: 'Bekijk & verstuur',
            priceChanged: 'De prijzen van dit product zijn aangepast, wat resulteert in de volgende prijs:',
            missingComponentsHeader: 'Niet genoeg onderdelen beschikbaar voor de activiteiten in offerte.',
            missingComponent: '{{count}} ontbrekende componenten van het type {{component}}.',
            missingComponent_plural: '{{count}} ontbrekende componenten van het type {{component}}.',
            tab: {
                items: { label: 'Items' },
                details: { label: 'Details' },
                extraDesigns: { label: 'Designs' },
                options: { label: 'Opties' },
                attachments: { label: 'Bijlagen' },
            },
        },
        quotationSuccessRateModal: {
            title: 'Slagingskans',
            triggerButton: 'Slagingskans',
        },
        viewQuotationModal: {
            title: 'Bekijk offerte',
        },
        activityIds: 'Activiteit IDs',
        orderIds: 'Order IDs',
        field: {
            id: {
                label: 'QID',
            },
            note: {
                label: 'Notitie',
            },
            extraDesigns: {
                label: 'Extra designs',
            },
            linkedDesigns: {
                label: 'Gekoppelde designs',
            },
            daysValid: {
                label: 'Dagen geldig',
            },
            emailTo: {
                label: 'E-mail naar',
            },
            emailCc: {
                label: 'E-mail CC',
            },
            emailSubject: {
                label: 'E-mail Titel',
            },
            successRate: {
                label: 'Slagingskans',
            },
            emailBody: {
                label: 'E-mail Tekst',
            },
            salutation: {
                label: 'Aanhef',
            },
            greeting: {
                label: 'Groet',
            },
            preface: {
                label: 'Voorwoord',
            },
            language: {
                label: 'Taal',
                value: {
                    nl: 'Nederlands',
                    fr: 'Frans',
                    uk: 'Engels',
                    de: 'Duits',
                },
            },
            status: {
                label: 'Status',
                value: {
                    draft: 'Concept',
                    sent: 'Verzonden',
                    approved: 'Goedgekeurd',
                    rejected: 'Afgewezen',
                    'needs approval': 'Heeft goedkeuring nodig',
                },
            },
            total: {
                label: 'Totaal',
            },
            sentAt: {
                label: 'Datum verzonden',
            },
            order: {
                label: 'Order',
            },
            project: {
                label: 'Project',
            },
            customer: {
                label: 'Klant',
            },
            accountManager: {
                label: 'Account Manager',
            },
            branch: {
                label: 'Branch',
            },
            items: {
                count: 'Aantal items',
            },
            expired: {
                label: 'Datum verlopen',
            },
            rejections: {
                label: 'Afwijzingen',
            },
            downPayment: {
                label: 'Aanbetaling',
                none: 'Geen aanbetaling',
                required: 'Aanbetaling vereist',
                someRequired: 'Aanbetaling vereist voor enkele opties',
                someRequired_plural: 'Aanbetaling vereist voor enkele opties',
            },
            customerSource: {
                label: 'Klantbron',
                value: {
                    project: 'Project',
                    invoice: 'Factuur',
                },
            }
        }
    },
    invoice: {
        activityIds: 'Activiteit IDs',
        orderIds: 'Order IDs',
        sendReminderModal: {
            title: 'Herinnering versturen',
            field: {
                to: { label: 'Aan' },
                cc: { label: 'CC' },
                subject: { label: 'Onderwerp' },
                body: { label: 'Inhoud' },
            },
        },
        creditButton: 'Creditfactuur',
        creditConfirm: 'Weet je zeker dat je deze factuur wilt crediteren?',
        overview: {
            title: 'Facturen',
        },
        viewPdf: 'PDF bekijken',
        downloadPdf: 'PDF downloaden',
        sendInvoiceModal: {
            title: 'Factuur bekijken en versturen',
            triggerButton: 'Bekijken & versturen',
            tab: {
                items: { label: 'Items' },
                details: { label: 'Details' },
                attachments: { label: 'Bijlagen' },
            },
            finalizeAndSendButton: 'Definitief maken en versturen',
            finalizeButton: 'Definitief maken',
        },
        sendPartialInvoiceModal: {
            title: 'Deelfactuur bekijken & versturen',
        },
        sendCreditInvoiceModal: {
            title: 'Creditfactuur bekijken & versturen',
        },
        partialInvoiceModal: {
            exactError: 'Exact synchronisatie fout',
            triggerButton: 'Deelfacturen',
            title: 'Deelfacturen',
            amountError: {
                aboveTitle: 'Totaal high',
                belowTitle: 'Totaal low',
                content: 'De deelfacturen hebben een totaalbedrag van {{actual}}, terwijl het totaalbedrag zou moeten zijn {{expected}}.',
            },
            recurring: {
                triggerButton: 'Periodieken inplannen',
                field: {
                    startDate: { label: 'Startdatum' },
                    unit: {
                        label: 'Eenheid',
                        value: {
                            week: 'Week',
                            month: 'Maand',
                            quarter: 'Kwartaal',
                            year: 'Jaar',
                        },
                    },
                    amount: { label: 'Bedrag' },
                },
            },
            schedule: 'Plannen',
            unschedule: 'Afbreken',
            scheduleAll: 'Plan allemaal',
            unscheduleAll: 'Alles uit plannings-schema halen',
        },
        viewPartialInvoiceModal: {
            title: 'Bekijk factuur',
            creditTitle: 'Bekijk creditfactuur',
        },
        viewInvoiceModal: {
            title: 'Bekijk factuur',
        },
        field: {
            id: {
                label: 'INID',
            },
            note: { label: 'Notitie'},
            emailFrom: { label: 'E-mail van' },
            emailTo: { label: 'E-mail naar' },
            emailCc: { label: 'E-mail CC' },
            emailSubject: {
                label: 'E-mail onderwerp',
            },
            emailBody: {
                label: 'E-mail inhoud',
            },
            salutation: {
                label: 'Aanhef',
            },
            greeting: {
                label: 'Begroeting',
            },
            language: {
                label: 'Taal',
                value: {
                    nl: 'Nederlands',
                    uk: 'Engels',
                    fr: 'Frans',
                    de: 'Duits',
                },
            },
            status: {
                label: 'Status',
                value: {
                    draft: 'Concept',
                    finalized: 'Definitief',
                    partially_sent: 'Gedeeltelijk verstuurd',
                    'needs approval': 'Goedkeuring nodig',
                    'needs approval send': 'Goedkeuring nodig (Verstuur)',
                    sent: 'Verstuurd',
                    credited: 'Gecrediteerd',
                    approved: 'Goedgekeurd',
                    rejected: 'Afgewezen',
                },
            },
            total: {
                label: 'Totaal',
            },
            sentAt: {
                label: 'Datum verstuurd',
            },
            order: {
                label: 'Order',
            },
            project: {
                label: 'Project',
            },
            customer: {
                label: 'Klant',
            },
            accountManager: {
                label: 'Account Manager',
            },
            branch: {
                label: 'Branch',
            },
            invoiceCustomers: { label: 'Factuurklanten' },
            invoiceNumbers: { label: 'Factuurnummers' },
            items: {
                count: 'Aantal items',
            },
            invoicePdfLayout: {
                label: 'Factuur PDF-indeling',
                value: {
                    project: 'Projectindeling',
                    original: 'Oorspronkelijke indeling (vereenvoudigd)',
                },
            },
        }
    },
    customer: {
        overview: {
            title: 'Klanten',
            add: 'Klant toevoegen',
            mainContacts: 'Belangrijkste contacten',
        },
        edit: {
            title: 'Klant {{id}}: {{name}}',
            kvkFill: 'KvK',
            basicInfo: 'Basisinfo',
            existing: 'Bestaand',
            new: 'Nieuw',
            selectContact: 'Contact selecteren',
            addProject: 'Project toevoegen',
        },
        add: {
            title: '[unsaved] {{name}}',
        },
        kvkModal: {
            title: 'Zoek KvK',
            kvkNumber: 'KVK-nummer',
            name: 'Naam',
            city: 'Plaats',
            noResults: 'Geen resultaten gevonden...',
        },
        customerStatusChange: {
            lastUpdated: 'Laatst geüpdatet',
            updated: 'Geüpdatet',
            statusUpdate: '{{update}}: {{date}}, {{statusFrom}} naar {{statusTo}} door {{user}}'
        },
        addContactModal: {
            title: 'Contact klant',
            addSelectedButton: 'Geselecteerde contacten ({{count}}) toevoegen',
            addNewButton: 'Opslaan',
            similarHeader: 'Vergelijkbare contacten',
            noSimilarContacts: 'Geen vergelijkbare contacten gevonden.',
            moreSimilarContacts: 'Toon {{count}} van {{total}} vergelijkbare contacten.',
            moreCustomers: ' en {{count}} meer',
        },
        addCustomerAddressModal: {
            title: 'Adres toevoegen',
            saveAndAddButton: 'Adres opslaan & toevoegen',
            saveButton: 'Adres opslaan',
        },
        mainContacts : {
            function: 'Functie',
            email: 'E-mail',
            phoneNumber: 'Telefoonnummer'
        },
        field: {
            id: {
                label: 'CID'
            },
            type: {
                label: 'Soort'
            },
            language: {
                label: 'Taal',
                value: {
                    nl: 'Nederlands',
                    fr: 'Frans',
                    uk: 'Engels',
                    de: 'Duits',
                },
            },
            name: {
                label: 'Naam'
            },
            description: {
                label: 'Beschrijving'
            },
            country: {
                label: 'Land'
            },
            freeField: {
                label: 'Vrij veld'
            },
            street: {
                label: 'Straat'
            },
            number: {
                label: 'Huisnummer'
            },
            suffix: {
                label: 'Toevoeging'
            },
            postalCode: {
                label: 'Postcode'
            },
            status: {
                label: 'Status',
                value: {
                    lead: 'Lead',
                    prospect: 'Prospect',
                    suspect: 'Suspect',
                    customer: 'Klant',
                    lost: 'Lost',
                    invoice_customer: 'Factuurklant',
                },
            },
            mainPhonenumber: {
                label: 'Telefoonnummer'
            },
            creationDate: {
                label: 'Aangemaakt op'
            },
            branch: {
                label: 'Branch'
            },
            tags: {
                label: 'Tags'
            },
            accountManager: {
                label: 'Account Manager'
            },
            entity: {
                label: 'Entiteit',
            },
            source: {
                label: 'Bron'
            },
            vatNumber: { label: 'BTW-nummer' },
            kvkNumber: { label: 'KvK-nummer' },
            vatShifted: { label: 'BTW verlegd' },
            amountDue: {label: 'Te betalen bedrag'},
            amountOverdue: {label: 'Achterstallig bedrag'},
            totalInvoiceAmountCurrentYear: {label: 'Totaal dit jaar'},
            downPaymentRequiredOverride: {
                label: 'Aanbetaling vereist boven',
                override: 'Overschrijven',
            },
            note: { label: 'Notities' },
        },
    },
    customerContact: {
        deleteButton: 'Contact verwijderen',
        selectButton: 'Contact selecteren',
        field: {
            id: {
                label: 'ID',
            },
            customer: {
                label: 'Klant',
            },
            type: {
                label: 'Soort',
                value: {
                    current: 'Huidig',
                    old: 'Oud',
                    main: 'Belangrijkste',
                },
            },
            isDecisionmaker: { label: 'Besluitvormer' },
            description: { label: 'Beschrijving' },
        },
    },
    tag: {
        overview: {
            title: 'Tags'
        }
    },
    contact: {
        add: {
            select: 'Contact selecteren',
            add: 'Contact toevoegen'
        },
        edit: {
            tile: 'Contact bewerken',
            showAddCustomerContactButton: 'Contactpersoon toevoegen',
            showAddAddressButton: 'Adres toevoegen',
        },
        overview: {
            title: 'Contacten',
            edit: 'Contact bewerken'
        },
        field:{
            id: {
                label: 'ID'
            },
            note: {
                label: 'Notitie',
            },
            name: {
                label: 'Naam'
            },
            function: {
                label: 'Functie'
            },
            email: {
                label: 'E-mail'
            },
            phonenumber: {
                label: 'Telefoonnummer'
            },
            company: {
                label: 'Bedrijf'
            },
            info: { label: 'Info' },
            initials: { label: 'Initialen' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            customers: { label: 'Klanten' },
        }
    },
    note: {
        sendButtonTitle: 'Shortcut: ctrl + enter',
        sendButton: 'Opslaan',
        addContactButton: 'Contact toevoegen',
        add: {
            add: 'Notitie toevoegen'
        },
        overview: {
            title: 'Notities'
        },
        field: {
            note: {
                label: 'Notitie',
                placeholder: 'Notities toevoegen',
            },
        },
    },
    design: {
        count: '{{count}} Design',
        count_plural: '{{count}} Designs',
        addButton: 'Design toevoegen',
        historyModal: {
            title: 'Design historie',
            noImage: 'Geen afbeeldingen',
            changes: {
                image: 'Afbeelding gewijzigd',
                status: 'Status gewijzigd',
            },
        },
        field: {
            id: { label: 'DID' },
            assignedTo: {
                label: 'Toegewezen aan',
            },
            status: {
                label: 'Status',
            },
            type: {
                label: 'Soort',
                value: {
                    internal: 'Intern',
                    external: 'Extern',
                },
            },
            priority: {
                label: 'Prioriteit',
                value: {
                    low: 'Laag',
                    normal: 'Normaal',
                    high: 'Hoog',
                },
            },
            description: {
                placeholder: 'Beschrijving invoeren...',
                label: 'Beschrijving',
            },
            width: { label: 'Breedte' },
            height: { label: 'Hoogte' },
        },
        overview: {
            title: 'Notities'
        }
    },
    branch: {
        addButton: 'Branch toevoegen',
        create: {
            title: 'Branch aanmaken',
        },
        edit: {
            title: 'Branch bewerken'
        },
        overview: {
            title: 'Branches',
            add: 'Branch aanmaken'
        },
        field: {
            name: {
                label: 'Branchnaam'
            },
            id: {
                label: 'ID'
            },
            defaultQuality: {
                label: 'Standaard kwaliteit',
                value: {
                    normal: 'Normaal',
                    premium: 'Premium',
                    premium_plus: 'Premium Plus',
                },
            },
            maximumQuotationDiscountAmount: {
                label: 'Maximaal kortingsbedrag offerte'
            },
            maximumQuotationDiscountPercentage: {
                label: 'Maximaal kortingspercentage offerte'
            },
            downPaymentRequired: {
                label: 'Aanbetaling vereist boven',
            },
        }
    },
    authentication: {
        field: {
            status: {
                label: 'Status',
                value: {
                    expired: 'Datum verlopen',
                    waiting: 'Wachten',
                    verified: 'Geverifieerd',
                },
            },
            userAgent: { label: 'Gebruikers-Agent' },
            ipAddress: { label: 'IP Address' },
            code: { label: 'Code' },
            expiresAt: { label: 'Vervalt op' },
        },
    },
    products: {
        overview: {
            header: {
                id: 'ID',
                name: 'Naam',
            },
        },
        edit: {
            header: {
                config: 'Configuratie',
                pricing: 'Prijsstelling',
                process: 'Proces',
                production: 'Productie',
                construction: 'Installatie',
                deconstruction: 'Deïnstallatie',
                disassembly: 'Disassemblage',
            },
            step: {
                header: {
                    configStep: 'Configuratiestap',
                    configCond: 'Voorwaarde configuratie',
                    consumes: 'Verbruikt',
                    produces: 'Produceert',
                },
                inventory: {
                    component: 'Component',
                    target: 'Doel',
                    quantity: '#',
                },
            },
        },
    },
    entity: {
        addButton: 'Entiteit toevoegen',
        field: {
            id: {label: 'ID'},
            name: {label: 'Naam'},
            logo: { label: 'Logo' },
            header: { label: 'Header' },
            footer: { label: 'Footer' },
            deleted: { label: 'Verwijderd' },
            translationOverrides: { label: 'Prioriteitsvertaling' },
            termsOfDeliveryNl: { label: 'Leveringsvoorwaarden NL' },
            termsOfDeliveryUk: { label: 'Leveringsvoorwaarden UK' },
            termsOfDeliveryFr: { label: 'Leveringsvoorwaarden FR' },
            termsOfDeliveryDe: { label: 'Leveringsvoorwaarden DE' },
            termsAndConditionsNl: { label: 'Voorwaarden en condities NL' },
            termsAndConditionsUk: { label: 'Voorwaarden en condities UK' },
            termsAndConditionsFr: { label: 'Voorwaarden en condities FR' },
            termsAndConditionsDe: { label: 'Voorwaarden en condities DE' },
            deliverySpecificationsNl: { label: 'Leveringsspecificaties NL' },
            deliverySpecificationsUk: { label: 'Leveringsspecificaties UK' },
            deliverySpecificationsFr: { label: 'Leveringsspecificaties FR' },
            deliverySpecificationsDe: { label: 'Leveringsspecificaties DE' },
            exactAccountCodeProd: {label: 'Exact Account code'},
            exactAccountCodeTest: {label: 'Exact Account code (test)'},
            vatCode: {label: 'BTW nummer'},
            vatPercent: {label: 'BTW Percentage'},
            integration: {label: 'Integratie'},
        },
        overview: {
            title: 'Entiteiten',
            add: 'Entiteit toevoegen',
        },
        edit: {
            title: 'Entiteit bewerken',
        },
        create: {
            title: 'Entiteit aanmaken',
        }
    },
    vehicle: {
        addButton: 'Voertuig toevoegen',
        overview: {
            title: 'Voertuigen'
        },
        edit: {
            title: 'Voertuig bewerken'
        },
        create: {
            title: 'Voertuig toevoegen'
        },
        field: {
            name: {
                label: 'Naam'
            },
            type: {
                label: 'Soort'
            },
            licence: {
                label: 'Kenteken'
            },
            deleted: { label: 'Verwijderd' },
        },
        type: {
            van: 'Van',
            truck: 'Vrachtwagen',
            'cherry picker': 'Hoogwerker',
            forklift: 'Vorkheftruck',
        }
    },
    productType: {
        addButton: 'Soort toevoegen',
        overview: {
            title: 'Productsoorten',
        },
        expr: {
            kind: {
                const: 'Constant',
                call: 'Call',
                prop: 'Eigenschap',
                func: 'Functie',
            },
            type: {
                text: 'Text',
                number: 'Huisnummer',
                bool: 'Bool',
                null: 'Null',
                config: 'Config',
                placeholder: 'Value Type'
            },
            params: 'Parameters',
            body: 'Inhoud',
        },
        edit: {
            title: 'Bewerken type',
            display: {
                label: 'Displays',
            },
            requirements: {
                label: 'Componenten',
            },
            constants: {
                label: 'Constanten',
            },
            props: {
                label: 'Laten zien',
            },
            computedValues: {
                label: 'Gecalculeerde waardes',
            },
            config: {
                allowOther: 'Andere toestaan',
                label: 'Config',
                kind: {
                    input: 'Input',
                    choice: 'Keuze',
                    const: 'Constant',
                },
                deleteProperty: 'Eigenschap verwijderen',
                unit: 'Eenheid',
                unitModal: {
                    title: 'Eenheid wijzigen',
                },
            },
            pricing: {
                label: 'Prijsstelling',
                type: 'Prijsstelling activiteit',
                types: {
                    placePricing: 'Prijsstelling plaatsing',
                    movePricing: 'Prijsstelling verplaatsing',
                    canvasSwapPricing: 'Prijsstelling doekwissel',
                    stickerPricing: 'Prijsstelling sticker',
                    extendPricing: 'Prijsstelling verlenging',
                },
            },
            steps: {
                label: 'Stappen',
                description: 'Beschrijving',
                batch: 'Groeperen',
                categories: {
                    canvas_prep: { label: 'Voorbereiding canvas' },
                    assembly: { label: 'Assemblage' },
                    construction: { label: 'Installatie' },
                    deconstruction: { label: 'Deïnstallatie' },
                    disassembly: { label: 'Disassemblage' },
                    canvas_swap: { label: 'Doekwissel' },
                    damage_site_survey: { label: 'Schade-bezichtiging' },
                    measure_site_survey: { label: 'Opmeet-bezichtiging' },
                    technical_site_survey: { label: 'Technische bezichtiging' },
                    commercial_site_survey: { label: 'CommerciÃ«le bezichtiging' },
                    repair: { label: 'Reparatie' },
                    repair_workshop: { label: 'Reparatie werkplaats' },
                    sticker: { label: 'Sticker' },
                    planning: { label: 'Planning' },
                    sales: { label: 'Verkoop' },
                },
                add: 'Stap toevoegen',
            },
        },
        add: {
            title: 'Soort toevoegen',
        },
        field: {
            id: { label: 'ID' },
            name: { label: 'Naam' },
            canvasPrepBeforeAssembly: { label: 'Canvas voorbereiding voorafgaand aan assemblage' },
            assemblyDuration: { label: 'Benodigde tijd voor de assemblage' },
            constructionDuration: { label: 'Benodigde tijd voor de installatie terplaatse' },
            deconstructionDuration: { label: 'Benodigde tijd voor deïnstallatie terplaatse' },
            canvasSwapDuration: { label: 'Benodigde tijd voor uitvoeren doekwissel terplaatse' },
            damageSiteSurveyDuration: { label: 'Benodigde tijd voor schade-bezichtiging' },
            measureSiteSurveyDuration: { label: 'Benodigde tijd voor opmeet-bezichtiging' },
            technicalSiteSurveyDuration: { label: 'Benodigde tijd voor techniche bezichtiging' },
            commercialSiteSurveyDuration: { label: 'Benodigde tijd voor commerciÃ«le bezichtiging' },
            stickerDuration: { label: 'Benodigde tijd voor plaatsing sticker' },
            material: { label: 'Materiaal' },
            commercialName: { label: 'CommerciÃ«le naam' },
            options: { label: 'Options' },
            durationSuffix: { label: 'Achtervoegsel looptijd' },
            workshop: {
                label: 'Werkplaats',
                trotter: 'Trotter Werkplaats',
                frame: 'Frame Werkplaats',
            },
            placeLedger: { label: 'Plaatsing grootboek' },
            moveLedger: { label: 'Verplaatsing grootboek' },
            canvasSwapLedger: { label: 'Doekwissel grootboek' },
            repairLedger: { label: 'Reparatie grootboek' },
            stickerLedger: { label: 'Sticker grootboek' },
            chargeLedger: { label: 'Kosten grootboek' },
            extendLedger: { label: 'Verlenging grootboek' },
        },
    },
    requirementType: {
        field: {
            componentType: { label: 'Componentsoort' },
            quantity: { label: 'Hoeveelheid' },
        },
    },
    constant: {
        addButton: 'Toevoegen constant',
        overview: {
            title: 'Constanten',
        },
        field: {
            name: { label: 'Naam' },
            value: { label: 'Waarde' },
            locked: { label: 'Vergrendeld' },
        },
    },
    displayType: {
        field: {
            width: { label: 'Breedte' },
            height: { label: 'Hoogte' },
            quantity: { label: 'Hoeveelheid' },
            must_split: { label: 'Splitsing vereist' },
        },
    },
    product: {
        deleteProtected: 'Kan niet worden verwijderd, aangezien sommige activiteiten zijn geoffreerd',
        field: {
            id: { label: 'PDID' },
            name: { label: 'Naam' },
            productType: { label: 'Soort' },
            config: { label: 'Configuratie' },
            order: { label: 'Order'},
            material: { label: 'Materiaal' },
            quality: { label: 'Kwaliteit' },
            size: { label: 'Afmeting' },
            placedAt: { label: 'Geplaatst op' },
            instructions: { label: 'Product instructies' },
            durationSuffixNl: { label: 'Looptijd NL' },
            durationSuffixUk: { label: 'Looptijd UK' },
            durationSuffixFr: { label: 'Looptijd FR' },
            durationSuffixDe: { label: 'Looptijd DE' },
            optionsNl: { label: 'Opties NL' },
            optionsUk: { label: 'Opties UK' },
            optionsFr: { label: 'Opties FR' },
            optionsDe: { label: 'Opties DE' },
        },
        add: {
            other: 'Anders...',
        },
        historyModal: {
            title: 'Historie',
        },
        copyModal: {
            title: 'Kopieer product',
            quantity: 'Aantal',
            copyDoneMessage: 'KopieÃ«n toegevoegd',
        },
        textsModal: {
            title: 'Product Teksten',
        },
        overview: {
            title: 'Producten',
            date: 'Datum',
            map: {
                loading: 'Laden',
            },
        },
    },
    instruction: {
        historyModal: {
            title: 'Instructie historie',
            noImage: 'Geen afbeeldingen',
            changes: {
                description: 'Beschrijving gewijzigd',
                image: 'Afbeelding gewijzigd',
                important: 'Markeer status gewijzigd',
                categories: 'CategorieÃ«n gewijzigd',
            },
        },
        sharedWarning: 'Deze instructie wordt gedeeld met {{count}} andere activiteit.',
        sharedWarning_plural: 'Deze instructie wordt gedeeld met {{count}} andere activiteiten.',
        historyButton: 'Bekijk historie',
        pinButton: 'op product',
        unpinButton: 'van product',
        linkButton: 'Selecteer koppelen',
        unlinkButton: 'Ontkoppel instructie',
        deleteButton: 'Verwijder instructie',
        cards: {
            linkButton: 'aan activiteit',
            addButton: 'aan activiteit',
        },
        chooseInstructionModal: {
            title: 'Instructielink kiezen',
            linkButton: 'Koppelen'
        },
        chooseProductsModal: {
            title: 'Kies producten',
            linkButton: 'Koppel',
        },
        field: {
            id: { label: 'ID' },
            description: { label: 'Beschrijving' },
            important: { label: 'Markeer (voor monteur)' },
        },
    },
    instructionChange: {
        field: {
            ordering: { label: '#' },
            createdBy: { label: 'Auteur' },
            createdAt: { label: 'Veranderd op' },
        },
    },
    designChange: {
        field: {
            ordering: { label: '#' },
            createdBy: { label: 'Auteur' },
            createdAt: { label: 'Veranderd op' },
        },
    },
    designStatusChange: {
        field: {
            newStatus: { history: 'Status veranderd in "{{status}}"' },
        },
    },
    activities: {
        activityExportWeekly: {
            title: 'Wekelijkse activiteiten export'
        },
        activityExport: {
            title: 'Activiteiten export'
        },
        activityExportAll: {
            title: 'Activiteiten exporteren'
        }
    },
    activity: {
        incompatibleDisplays: 'The displays on the activities you are trying to copy are not compatible with P{{product.id}}.',
        replacePlace: 'Weet je zeker dat je de plaatsingsactiviteit van P{{product.id}} wilt vervangen?',
        replaceRemove: 'Weet je zeker dat je de verwijderingsactiviteit van P{{product.id}} wilt vervangen?',
        info: 'Activiteit {{index}}',
        cancel: {
            includesFinalizedRoutesPlanner: 'EÃ©n of meerdere van de geselecteerde activiteiten bevinden zich in een route die al definitief gemaakt is. Weet je het zeker?',
            includesFinalizedRoutesAccountManager: 'EÃ©n of meerdere van de geselecteerde activiteiten bevinden zich in een route die al definitief gemaakt is. Schakel iemand met planner-rechten in om te annuleren!',
            includesRoutes: 'EÃ©n of meerdere van de geselecteerde activiteiten bevinden zich in een route die nog niet defitief is. Weet je het zeker?',
        },
        edit: {
            confirmChangeLocation: 'Wil je echt de locatie van deze activiteit veranderen?',
        },
        overview: {
            title: 'Activiteiten',
            product: 'Product',
            order: 'Order',
            contact: 'Contactpersoon factuur',
            project: 'Project',
            customer: 'Klant',
        },
        design: {
            worker: 'Platform worker',
            bulldozer: 'Bulldozer'
        },
        field: {
            orderProcessor: {
                label: 'OP',
            },
            id: {
                label: 'AID'
            },
            hasDefaultTimeWindows: {
                label: 'Tijdsblok?'
            },
            orderReference: {
                label: 'Orderreferentie'
            },
            createdAt: {
                label: 'Aangemaakt'
            },
            customer: {
                label: 'Klant'
            },
            minTimeWindow: {
                label: 'Vanaf tijd'
            },
            maxTimeWindow: {
                label: 'Tot tijd'
            },
            minDate: {
                label: 'Minimale datum'
            },
            maxDate: {
                label: 'Maximale datum'
            },
            approvedWindow: { label: 'Goedgekeurd door planner' },
            hardDeadline: {
                label: 'Deadline'
            },
            constructionDuration: {
                label: 'Benodigde tijd voor de installatie terplaatse',
            },
            finalized: {
                label: 'Definitief'
            },
            document: {
                label: 'Document'
            },
            deconstructionDuration: {
                label: 'Benodigde tijd voor deïnstallatie terplaatse',
            },
            canvasSwapDuration: {
                label: 'Benodigde tijd voor uitvoeren doekwissel terplaatse',
            },
            stickerDuration: {
                label: 'Benodigde tijd voor plaatsing sticker',
            },
            repairDuration: {
                label: 'Benodigde tijd voor reparatie terplaatse',
            },
            damageSiteSurveyDuration: {
                label: 'Benodigde tijd voor de schade-bezichtiging',
            },
            measureSiteSurveyDuration: {
                label: 'Benodigde tijd voor de opmeet-bezichtiging',
            },
            technicalSiteSurveyDuration: {
                label: 'Benodigde tijd voor de technische bezichtiging',
            },
            commercialSiteSurveyDuration: {
                label: 'Benodigde tijd voor de commerciÃ«le bezichtiging',
            },
            status: {
                label: 'Status',
                prefixedLabel: 'Status activiteit',
                value: {
                    draft: 'Concept',
                    quoted: 'Geoffreerd',
                    quoted_sent: 'Geoffreerd (verstuurd)',
                    to_be_planned: 'Te plannen',
                    to_be_invoiced: 'Te factureren',
                    invoiced: 'Gefactureerd',
                    invoiced_sent: 'Gefactureerd (verstuurd)',
                    finalized: 'Definitief',
                    planned: 'Gepland',
                    executed: 'Uitgevoerd',
                    approved: 'Goedgekeurd',
                    cancelled: 'Geannuleerd',
                    done: 'Klaar',
                    planned_finalized: 'Gepland (definitief)',
                    invoiced_cancelled: 'Geannuleerd (gefactureerd)',
                    invoiced_sent_cancelled: 'Geannuleerd (gefactureerd & verstuurd)',
                },
            },
            type: {
                label: 'Soort',
                value: {
                    place: 'Plaatsing',
                    move: 'Verplaatsing',
                    remove: 'Verwijdering',
                    site_survey: 'Site Survey',
                    canvas_swap: 'Doekwissel',
                    repair: 'Reparatie',
                    extend: 'Verlenging',
                    sticker: 'Sticker',
                    import: 'Importeren',
                    charge: 'Kosten',
                    prepaid_canvas_swap: 'Vooraf betaalde doekwissel',
                    prepaid_move: 'Vooraf betaalde verplaatsing',
                    export: 'Exporteren',
                },
            },
            manHours: {
                label: "Manuren"
            },
            instructions: {
                label: 'instructies',
            },
            requestedPerformAt: {
                label: 'Gevraagd uit te voeren op',
            },
            accountManager: {
                label: 'AM'
            },
            placeAt: {
                label: 'Plaatsing op',
                lat: 'Lat',
                lng: 'Lng',
            },
            invoiced: {
                label: 'Gefactureerd'
            },
            duration: {
                label: 'Plaatsingsperiode'
            },
            window: {
                label: 'Datumvenster'
            },
            remarks: {
                label: 'Opmerkingen'
            },
            account_manager:{
                label: 'AM'
            },
            order_processor:{
                label: 'OP'
            },
            route: {
                label: 'Route'
            },
            eta: {
                label: 'ETA',
            },
            project: {
                label: 'Project'
            },
            product: {
                label: 'Product'
            },
            location: {
                label: 'Locatie'
            },
            extras: {
                label: 'Extra\'s',
                values: {
                    internal_platform: 'Eigen hoogwerker',
                    external_platform: 'Externe hoogwerker',
                    internal_crane: 'Interne kraan',
                    external_crane: 'Externe kraan',
                    internal_forklift: 'Interne vorkheftruck',
                    external_forklift: 'Externe vorkheftruck',
                    internal_rolling_scaffold: 'Interne rolsteiger',
                    external_rolling_scaffold: 'Externe rolsteiger',
                    ten_meter_ladder: '10m ladder',
                    pile_driver: 'Heimachine',
                    trotter_lift: 'Trotter lift',
                    road_plates: 'Rijplaten',
                    facade_installation: 'Gevelinstallatie',
                    viewing: 'Bekijken',
                    dtp_work: 'DTP Werk',
                    stock_photo: 'Stockfoto',
                },
            },
            hasInstructions: { label: 'Heeft instructies' },
            hasPrepressedArtwork: { label: 'Grafisch bestand aanwezig' },
            productionFinished: { label: 'Productie afgerond' },
        },
    },
    repairActivity: {
        field: {
            workshopRequired: { label: 'Actie werkplaats vereist' },
            onSiteRepair: { label: 'Reparatie terplaatse' },
            canvasRepair: { label: 'Canvas Reparatie' },
            stormDamage: { label: 'Stormschade' },
            invoiceExempt: {
                label: 'Factuur vrijgesteld',
                tooltip: 'Deze activiteit is intern goedgekeurd en kan dus niet worden gefactureerd.',
            },
        },
    },
    placeActivity: {
        field: {
            city: {
                label: 'Plaats',
            },
            street: {
                label: 'Straat',
            },
            streetNumber: {
                label: 'Huisnummer',
            },
            durationUnit: {
                label: 'Eenheid',
                value: {
                    months: 'Maanden',
                    days: 'Dagen',
                },
            },
            duration: {
                label: 'Looptijd',
            },
            manualWindow: {
                label: 'Handmatig datumvenster',
                manual: 'Handmatig',
                automatic: 'Automatisch',
            },
            preInvoice: { label: 'Voorfactureren' },
        },
    },
    canvasSwapActivity: {
        field: {
            manualWindow: { label: 'Handmatig datumvenster' },
        },
    },
    importActivity: {
        field: {
            city: {
                label: 'Plaats',
            },
            street: {
                label: 'Straat',
            },
            streetNumber: {
                label: 'Huisnummer',
            },
            durationUnit: {
                label: 'Eenheid',
                value: {
                    months: 'Maanden',
                    days: 'Dagen',
                },
            },
            duration: {
                label: 'Looptijd',
            },
            exportActivity: { label: 'Activiteit exporteren' },
        },
    },
    moveActivity: {
        field: {
            city: {
                label: 'Plaats',
            },
            street: {
                label: 'Straat',
            },
            streetNumber: {
                label: 'Huisnummer',
            },
        },
    },
    extendActivity: {
        field: {
            durationUnit: {
                label: 'Looptijd eenheid',
            },
            duration: {
                label: 'Looptijd',
            },
            invoiceExempt: {
                label: 'Factuur vrijgesteld',
                tooltip: 'Deze reparatie werd handmatig goedgekeurd en kan dus niet worden gefactureerd.',
            },
        },
    },
    removeActivity: {
        field: {
            earlyRemove: { label: 'Eerder' },
        },
    },
    siteSurveyActivity: {
        field: {
            kind: {
                label: 'Soort',
                value: {
                    damage: 'Schade',
                    measure: 'Opmeten',
                    technical: 'Technisch',
                    commercial: 'Commercieel',
                },
            },
            invoiceExempt: {
                label: 'Factuur vrijgesteld',
                tooltip: 'Deze bezichtiging werd handmatig goedgekeurd en kan dus niet worden gefactureerd.',
            },
        },
    },
    stickerActivity: {
        panelName: 'Sticker',
        field: {
            width: { label: 'Breedte' },
            height: { label: 'Hoogte' },
            manualWindow: { label: 'Handmatig datumvenster' },
            invoiceExempt: {
                label: 'Factuur vrijgesteld',
                tooltip: 'Deze sticker-activiteit werd handmatig goedgekeurd en kan dus niet worden gefactureerd.',
            },
        },
    },
    step: {
        field: {
            category: {
                label: 'Categorie',
            },
        },
    },
    instructionStep: {
        addButton: 'Instructie stap',
        edit: {
            title: 'Stap bewerken'
        },
        field: {
            description: {
                label: 'Beschrijving'
            }
        }
    },
    conditionStep: {
        addButton: 'Conditie stap',
        edit: {
            title: 'Bewerk stap'
        }
    },
    loadStep: {
        addButton: 'Laad stap',
        edit: {
            title: 'Bewerk stap'
        }
    },
    unloadStep: {
        addButton: 'Unload stap',
    },
    work: {
        overview: {
            title: 'Opdrachten',
        },
        details: {
            title: 'R{{route.id}} A{{work.activity.id}} J{{work.id}}',
            navigateButton: 'Start navigatie: {{city}}',
            startButton: 'Start klus',
            finishButton: 'Klaar!',
            footer: {
                workDetails: 'Details',
                workMap: 'Locatie',
                workComments: 'Opmerkingen',
                workResults: 'Resultaten',
            },
        },
        activity: {
            earlyRemove: 'Eerdere verwijdering'
        },
        field: {
            id: {
                label: 'JID'
            },
            category: {
                label: 'Categorie'
            },
            region: {
                label: 'Regio',
                value: 'Region {{region}}',
            },
            dimensions: {
                label: 'Afmetingen'
            },
            quality: {
                label: 'Print kwaliteit'
            },
            print_remarks: {
                label: 'Opmerkingen print & finish'
            },
            workshop_remarks: {
                label: "Opmerkingen werkplaats"
            },
            lighting: {
                label: 'Verlichting'
            },
            steps: {
                label: 'Stappen'
            },
            city: {
                label: 'Plaats'
            },
            duration: {
                label: 'Werk'
            },
            activity: {
                label: 'Activiteit',
            },
            activityType: {
                label: 'Activiteit soort',
            },
            route: {
                label: 'Route',
            },
            hasInstructions: {
                no: 'Instructies activiteit'
            },
            isArtworkDone: {
                no: 'nee'
            },
            hasOpenDownPayment: {
                label: 'Heeft openstaande aanbetaling',
                yes: 'Aanbetaling ja',
            },
        },
        stats: {
            todo: 'Aantal m2 week',
            done: 'Aantal m2 week',
        },
    },
    order: {
        addButton: 'Order toevoegen',
        addProductButton: 'Product toevoegen',
        addResultButton: 'Resultaat toevoegen',
        field: {
            id: { label: 'OID' },
            orderProcessorOverride: {
                label: 'Order Processor',
                inherit: 'Overerven van project',
            },
            products: {
                label: 'Producten',
            },
            reference: {
                label: 'Referentie',
            },
            poNumber: {
                label: 'PO-nummer',
            },
            activities: {
                label: 'Activiteiten',
            },
            todos: {
                label: 'To do\'s',
            },
            project: {
                label: 'Project',
                warningNew: 'Dit is een nieuw project.',
            },
            permitPerson: {
                label: 'Aanvrager van de vergunning',
            },
            projectContactOverride: {
                label: 'Contactpersoon offerte',
                inherit: 'Overerven van project',
                warning: 'Deze order overschrijft het projectcontact.'
            },
            invoiceCustomerOverride: {
                label: 'Factuurklant',
                inherit: 'Overerven van project',
                warning: 'Deze order overschrijft de projectklant.'
            },
            invoiceContactOverride: {
                label: 'Factuurcontact',
                inherit: 'Overerven van project',
                warning: 'Deze order overschrijft het projectcontact.'
            },
            permitContact: { label: 'Vergunningscontact' },
            permitUser: { label: 'Vergunninghouder' },
        },
        overview: {
            title: 'Orders',
        },
        edit: {
            uploadLocations: 'Locaties CSV',
            attachments: {
                add: 'Bijlage toevoegen',
            },
            options: {
                label: 'Optie {{reference}}',
                items: '{{count}} item',
                items_plural: '{{count}} items',
                placeholder: 'Beschrijving...',
                add: 'Optie toevoegen',
            },
            filter: {
                activityId: 'Activiteit ID',
                productId: 'Product ID',
                minDate: 'Minimale datum',
                maxDate: 'Maximale datum',
            },
            searchPermitRequester: 'Vergunningaanvrager zoeken',
            copyActivitiesFromProductButton: 'Kopieer actitiviteiten naar {{count}} geselecteerde producten',
            bulkActionButton: {
                choose: 'Kies bulkactie ({{count}})',

                copyActivities: 'Kopieer activiteiten ({{count}})',

                addToOrderQuotation: 'Toevoegen aan offerte ({{count}})',
                removeFromOrderQuotation: 'Verwijderen van offerte ({{count}})',

                addToProjectQuotation: 'Toevoegen aan projectofferte ({{count}})',
                removeFromProjectQuotation: 'Verwijderen van projectofferte ({{count}})',

                addToOrderInvoice: 'Toevoegen aan factuur ({{count}})',
                removeFromOrderInvoice: 'Verwijderen van factuur ({{count}})',

                addToProjectInvoice: 'Toevoegen aan projectfactuur ({{count}})',
                removeFromProjectInvoice: 'Verwijderen van projectfactuur ({{count}})',

                approveActivities: 'Activiteiten goedkeuren ({{count}})',
                cancelActivities: 'Activiteiten annuleren ({{count}})',

                linkInstructions: 'Koppel instructies ({{count}})',
                finalize: 'Definitief maken',
            },
            selectButton: {
                text: 'Selecteer activiteiten',
                select: 'Selecteer {{name}} ({{count}})',
                unselect: 'Deselecteer {{name}} ({{count}})',
                item: {
                    all: 'Alles',
                    draft: 'Concept',
                    toBeInvoiced: 'Te factureren',
                },
            },
            quotation: {
                rejected: 'Afgewezen',
                rejectAndDraft: 'Afwijzen en concept',
                accepted: 'Geaccepteerd',
                approveDiscount: 'Korting goedkeuren',
                rejectDiscount: 'Korting afwijzen',
                needsReview: 'Moet opnieuw bekeken worden',
            },
            invoice: {
                accepted: 'Geaccepteerd',
                approveDiscount: 'Korting goedkeuren',
                rejectDiscount: 'Korting afwijzen',
                needsReview: 'Moet opnieuw bekeken worden',
            },
            categoryOrder: 'Order',
            title: 'Project {{project.id}}: {{project.name}} - Order {{order.id}}',
            addProductButton: 'Product toevoegen',
            addStickerButton: 'Sticker toevoegen',
            addModifierButton: 'Modificator toevoegen',
            addExtraDesignButton: 'Design toevoegen',
            addProduct: {
                title: 'Toevoegen {{name}}',
                nextButton: 'Volgende',
                step1: {
                    title: '1. Product kiezen',
                },
                step2: {
                    title: '2. Configureren',
                },
                genericError: 'Er is iets mis gegaan.',
            },
            addResult: {
                title: 'Resultaat toevoegen',
                text: 'Selecteer (meerdere) foto\'s',
                image: 'Foto toevoegen',
            },
            productTab: 'Producten',
            inactiveproductTab: 'Inactieve Products',
            quotationTab: 'Offertes',
            invoiceTab: 'Facturen',
            resultTab: 'Resultaten',
            productSegment: {
                active: 'Actieve producten ({{count}})',
                done: 'Afgeronde producten ({{count}})',
                cancelled: 'Geannuleerde producten ({{count}})',
            },
        },
        add: {
            title: 'Project {{project.id}}: {{project.name}} - Nieuwe order',
        },
        searchProject: {
            title: 'Project zoeken',
        },
    },
    route: {
        add: {
            title: 'Toevoegen Route',
            aid: 'AID',
            jid: 'JID',
            start: 'Start',
            end: 'Eind'
        },
        details: {
            title: 'Route {{id}} details'
        },
        addWorks: {
            addSelectedButton: 'Geselecteerde klussen ({{count}}) toevoegen',
            jobId: 'JID',
            activityId: 'AID',
        },
        edit: {
            title: 'Route bewerken {{id}}',
            emptyWorks: 'Er zijn nog geen klussen toegevoegd...',
            addWorkButton: 'Klussen toevoegen',
            finalizeButton: 'Definitief maken',
            onlyAvailable: 'Alleen beschikbare',
            onlyRouteDate: 'Alleen voor deze routedatum',
            googleApiLimitHeader: 'Route uitgeschakeld',
            googleApiLimitContent: 'Deze route bevat te veel klussen om te worden berekend.',
            optimizeButton: 'Optimaliseren',
            optimizeInfeasible: 'Route-optimalisatie niet mogelijk met aangegeven beperkingen',
            showOtherRoutes: 'Toon andere routes',
            addWorkModal: {
                title: 'Klussen toevoegen',
            },
        },
        statistic: {
            km: 'KM',
            workday: 'Totale werkdag',
            travelTime: 'Reistijd',
            workTime: 'Werktijd'
        },
        overview: {
            title: 'Routes',
            time: 'Tijd',
            distance: 'Afstand',
            filter: {
                activityId: 'Activiteit ID',
                productId: 'Product ID',
                customerName: 'Klantnaam',
            },
        },
        field: {
            date: {
                label: 'Datum',
                prefixedLabel: 'Routedatum',
            },
            mainEngineer: {
                label: 'Hoofdmonteur'
            },
            otherEngineers: {
                label: 'Andere monteurs'
            },
            mainVehicle: {
                label: 'Hoofdvoertuig'
            },
            otherVehicles: {
                label: 'Andere voertuigen'
            },
            id: {
                label: 'RID'
            },
            remaining: {
                label: 'Resterend'
            },
            time: {
                label: 'Tijd'
            },
            completed: {
                label: 'Afgerond'
            },
            status: {
                label: 'Status',
                value: {
                    waiting: 'Wachtend',
                    ready_to_go: 'Klaar om te gaan',
                    in_progress: 'In uitvoering',
                    done: 'Klaar',
                },
                waitingReason: {
                    dep: 'J{{work.id}} wacht nog op J{{dep.id}}.',
                    noInstructions: 'J{{work.id}} heeft geen instructies.',
                    constraint: 'J{{work.id}}: {{constraint}}',
                    openDownPayment: 'J{{work.id}}: de aanbetaling is nog niet gedaan.',
                },
            },
            project: {
                label: 'Project'
            },
            activity: {
                label: 'Activiteit'
            },
            finalized: {
                label: 'Definitief'
            },
            startLocation: {
                label: 'Startlocatie',
            },
        }
    },
    project: {
        overview: {
            title: 'Projecten',
            filter: {
                activityStatus: 'Status activiteit',
                activityType: 'Soort activiteit',
                accountManager: 'Account Manager',
                orderProcessor: 'Order Processor',
                canvasSwapTotalBalance: { label: 'Doekwissel-tegoed' },
                moveTotalBalance: { label: 'Verplaatsing-tegoed' },
            },
            merge: {
                projects: '{{count}} project',
                projects_plural: '{{count}} projecten',
                orders: '{{count}} order',
                orders_plural: '{{count}} orders',
                mergeButton: {
                    start: 'Samenvoegen',
                    done: 'Samenvoegen',
                    cancel: 'Annuleren',
                },
                mergeModal: {
                    title: 'Doel selecteren',
                    error: 'Er zijn enkele fouten opgetreden tijdens de samenvoeging.',
                    newProjectButton: 'Nieuw doel',
                },
                newProjectModal: {
                    title: 'Nieuw doel',
                },
            },
        },
        edit: {
            title: 'Project {{id}}: {{name}}',
        },
        add: {
            title: '[unsaved] {{name}}',
        },
        field: {
            id: { label: 'PJID' },
            name: { label: 'Naam' },
            customer: { label: 'Klant' },
            branchOverride: {
                label: 'Branch',
                inherit: 'Overerf branch',
                warning: 'Dit project overschrijft de branch die bij deze klant is geselecteerd.',
            },
            accountManagerOverride: {
                label: 'Account Manager',
                inherit: 'Overerf account manager',
                warning: 'Dit project overschrijft de account manager die bij deze klant is geselecteerd.',
            },
            source: { label: 'Bron' },
            orders: {
                label: 'Orders',
            },
            design: {
                label: 'Design',
            },
            designs: {
                label: 'Designs',
            },
            contact: {
                label: 'Contact'
            },
            poNumber: {
                label: 'PO-nummer',
            },
            invoiceCustomerOverride: {
                label: 'Factuurklant',
                inherit: 'Overerf van project',
                warning: 'Deze order overschrijft de projectklant.'
            },
            invoiceContactOverride: {
                label: 'Contactpersoon factuur',
                inherit: 'Overerven van project',
                warning: 'Deze order overerft het projectcontact.'
            },
            activity: {
                requirements: 'Voorwaarden'
            },
            branch: { label: 'Branch' },
            entity: { label: 'Entiteit' },
            quotations: { label: 'Offertes' },
            invoices: { label: 'Facturen' },
            canvasSwapTotalBalance: { label: 'Doekwissel-tegoed' },
            moveTotalBalance: { label: 'Verplaatsings-tegoed' },
        },
    },
    noteType: {
        addButton: 'Notitie soort toevoegen',
        field: {
            name: { label: 'Naam' },
            statusOptions: { label: 'Status Opties' },
            addStatus: { label: 'Stati toevoegen?'},
            statusName: { label: 'Status Naam' },
        },
        overview: {
            title: 'Notitie Soorten',
        },
        edit: {
            title: 'Notitie soort aanpassen',
        },
        create: {
            title: 'Notitie Soort aanmaken',
        },
    },
    componentType: {
        addButton: 'Soort toevoegen',
        field: {
            name: { label: 'Naam' },
            managed: { label: 'Beheerd' },
            availability: {
                available: { label: 'Beschikbaar' },
                expected_return: { label: 'Verwachte terugkomst' },
                draft: { label: 'Concept' },
                quoted: { label: 'Geoffreerd' },
                approved: { label: 'Goedgekeurd' },
                placed: { label: 'Geplaatst' },
                scrapped: { label: 'Afgeschreven' },
                sold: { label: 'Verkocht' },
                defective: { label: 'Defect' },
            },
        },
        overview: {
            title: 'Componentsoorten',
        },
        edit: {
            title: 'Soort bewerken',
        },
        create: {
            title: 'Componentsoort aanmaken',
        },
        availabilityModal: {
            title: 'Beschikbaarheid componenten',
        },
        availabilityButton: 'Beschikbaarheid',
    },
    componentOrder: {
        addButton: 'Toevoegen order',
        field: {
            componentType: { label: 'Componentsoort' },
            quantity: { label: 'Hoeveelheid' },
            startDate: { label: 'Startdatum' },
        },
        overview: {
            title: 'Componenten vrijgave',
        },
        edit: {
            title: 'Order bewerken',
        },
        create: {
            title: 'Aanmaken van een componenten vrijgave',
        },
        fillModal: {
            title: 'Componenten vrijgave vullen',
            description: 'Gelieve Ã©Ã©n identificatiecode per regel in te voeren.',
            fillNotification: 'Aangemaakte {{n}} componenten.',
            fillDone: 'Bestelling volledig gevuld.',
        },
    },
    component: {
        addButton: 'Component toevoegen',
        field: {
            componentType: { label: 'Componentsoort' },
            identifier: { label: 'Identifier' },
            startDate: { label: 'Startdatum' },
        },
        overview: {
            title: 'Componenten',
        },
        edit: {
            title: 'Component bewerken',
        },
        create: {
            title: 'Component aanmaken',
        },
    },
    plannedStep: {
        type: {
            batch: { label: 'Groeperen' },
            rip: { label: 'Rippen' },
            print: { label: 'Print' },
            finish: { label: 'Afwerking' },
            load: { label: 'Load' },
            unload: { label: 'Unload' },
            instruction: { label: 'Instructie' },
            scan: { label: 'Scan' },
        },
        perform: {
            title: 'Uitvoering stap',
            engineer: 'Engineer...',
        },
    },
    crash: {
        heading: 'applicatie gecrasht',
        subHeading1: "Het spijt ons zeer, maar de applicatie is gecrasht.",
        subHeading2: 'Ons team is op de hoogte gebracht.',
        reload: 'Reload page'
    },
    startError: {
        maintenance: 'Er wordt op dit moment onderhoud gepleegd aan de server.',
        line1: 'Onze server heeft ons in de steek gelaten. Het spijt ons zeer.',
        line2: 'Er is ons een bericht gestuurd met de details van deze kwestie.'
    },
    display: {
        field: {
            name: { label: 'Naam' },
            width: { label: 'Breedte' },
            height: { label: 'Hoogte' },
        },
        splitDisplays: {
            title: 'Opsplitsen displays',
        },
        splitDisplay: {
            title: 'Opsplitsen displays',
            panel: '{{display.name}} - paneel {{number}}',
        },
    },
    panel: {
        chooseDesignModal: {
            title: 'Kies een design voor display ({{project.customer.name}} / {{project.name}})',
        },
        field: {
            display: { label: 'Display' },
            name: { label: 'Naam' },
            width: { label: 'Breedte' },
            height: { label: 'Hoogte' },
        },
    },
    tooltip: {
        delete: 'Verwijderen',
        reset: 'Herstellen',
        hardDeadline: 'Harde deadline',
        select: 'Selecteren',
    },
    panelAllocation: {
        field: {
            id: {
                label: 'ID',
            },
            downloadLink: {
                label: 'Download Link',
            },
        },
    },
    workBatch: {
        field: {
            area: { label: 'Aantal m2' },
        },
        download: {
            title: 'Download printbestanden',
            printForm: 'Printformulier',
        },
    },
    constantOverride: {
        overview: {
            title: 'Constante overschrijven',
        },
        field: {
            constant: { label: 'Constante' },
            value: { label: 'Waarde' },
        },
    },

    user: {
        twoFactorAuth: {
            title: 'Bevestigen',
            code: 'Code',
            resend: 'Opnieuw versturen',
            explanation: 'Er is een bevestigingscode naar jouw geregistreerde telefoonnummer gestuurd.',
            explanationFailed: 'Er is geprobeerd een bevestigingscode naar jouw geregistreerde telefoonnummer te sturen, maar dat is mislukt.',
            expiring: 'Jouw authenticatie zal over {{ minutes }} minuten verlopen.',
        },
        overview: {
            title: 'Gebruikers',
            fullName: 'Naam',
            id: 'ID',
            addButton: 'Medewerker toevoegen',
        },
        create: {
            title: 'Gebruiker aanmaken',
        },
        edit: {
            title: 'Gebruiker bewerken',
        },
        field: {
            signature: { label: 'Handtekening' },
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: {label: 'Taal'},
            phoneNumber: { label: 'Telefoonnummer', },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd op' },
            isActive: {
                label: 'Is Active User',
                options: {
                    yes: 'True',
                    no: 'False',
                    all: 'Both',
                }
            },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Super gebruiker',
                    admin: 'Admin',
                    account_manager: 'Account Manager',
                    order_processor: 'Order Processor',
                    junior_engineer: 'Junior Engineer',
                    engineer: 'Engineer',
                    senior_engineer: 'Senior Engineer',
                    designer: 'Designer',
                    planner: 'Planner',
                    empty: 'Geen rollen',
                    printer_operator: 'Printer operator',
                    production_employee: 'Productiemedewerker',
                    production_view: 'Productie scherm',
                    vehicle: 'Voertuig',
                    sales_manager: 'Sales Manager',
                },
                empty: 'Geen groepen toegewezen',
            },
            entity: {
                label: 'Entiteit',
            },
            authentications: {
                label: 'Authenticaties',
            },
            deleted: { label: 'Verwijderd' },
            linkedSalesManager: { label: 'Sales Manager' },
            maximumQuotationDiscountAmount: {
                label: 'Maximaal kortingsbedrag offerte'
            },
            maximumQuotationDiscountPermille: {
                label: 'Maximaal kortingspercentage offerte'
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'ongeldige gegevens',
                unknown: 'Onbekende fout, status code: {{status}}', },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord vergeten',
            requestButton: 'E-mail versturen',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord wijzigen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Account details',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Wachtwoord wijzigen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet je zeker dat je wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Aangepast',
        action: {
            unsetDefault: 'Reset standaard',
            setDefault: 'Stel in als standaard',
            setCurrent: 'Filters opslaan',
            delete: 'Verwijderen',
        },
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        search: 'Zoeken',
        saveButton: 'Opslaan',
        multiPick: {
            searchPlaceholder: 'Selecteerâ€¦',
            selectedText: '$1 van $2 geselecteerd',
            noneSelectedText: 'Niets geselecteerd',
            selectAllButton: 'Alles',
            selectNoneButton: 'Geen',
        },
        idSearch: 'Zoeken op ID',
        pdfLoading: 'PDF laden',
        pdfError: 'PDF laden mislukt',
        discardChanges: 'Je hebt niet opgeslagen wijzigingen. Als je doorgaat worden jouw wijzigingen ongedaan gemaakt.',
        all: 'Alles',
        none: 'Geen',
        fillButton: 'Invullen',
        backButton: 'Terug',
        resetButton: 'Herstel',
        updateButton: 'Update',
        viewButton: 'Bekijk',
        uploadButton: 'Upload',
        yes: 'Ja',
        no: 'Nee',
        either: 'of',
        up: 'Omhoog',
        down: 'Omlaag',
        hide: 'Verberg',
        show: 'Toon',
        edit: 'Bewerken',
        delete: 'Verwijderen',
        confirmButton: 'Bevestigen',
        refreshButton: 'Refresh',
        nextButton: 'Volgende',
        doneButton: 'Klaar',
        selectButton: 'Selecteren',
        enabledWhenExisiting: 'Alleen beschikbaar na opslaan',
        copyButton: 'KopiÃ«ren',
        pasteButton: 'Plakken',
        zipButton: 'Zip',
        saveAndNewButton: 'Opslaan & nieuw',
        saveLoadingButton: 'Opslaanâ€¦',
        sendButton: 'Verzenden',
        csvButton: 'CSV',
        editButton: 'Bewerken',
        selectAllButton: 'Alles selecteren',
        addButton: 'Toevoegen',
        newButton: 'Nieuw',
        searchModalButton: 'Kies',
        deleteButton: 'Verwijderen',
        clearButton: 'Leegmaken',
        cancelButton: 'Annuleren',
        deletedWaivers: 'Verwijderde ontheffingen:',
        closeButton: 'Sluiten',
        unfinalizeButton: 'Definitief status ongedaan maken',
        searchLabel: 'Zoeken',
        applyButton: 'Toepassen',
        continueButton: 'Verder gaan',
        confirmQuestion: 'Weet je het zeker?',
        selectPlaceholder: 'Selecteerâ€¦',
        dateRangePlaceholder: 'Selecteer datumâ€¦',
        unfinalizeConfirmation: 'Weet je zeker dat je {{name}} niet wilt afmaken?',
        deleteConfirmation: 'Weet je zeker dat je {{name}} wilt verwijderen?',
        restoreConfirmation: 'Weet je zeker dat je de {{name}} wilt herstellen?',
        typeToSearchText: 'Typ om te zoekenâ€¦',
        copyToClipboardTooltip: 'Kopieer naar klembord',
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Ongeldige gegevens',
            requestScanSuccess: 'Verzoek goed ontvangen',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina zo snel mogelijk te herladen',
            mailUpdateSentNotification:
                'E-mail update succesvol verzonden naar klant',
            mailUpdateNoTemplateNotification:
                "Kan geen update e-mail versturen zonder een sjabloon voor deze klant",
        },
        validationErrors: {
            blank: 'Veld is verplicht',
            null: 'Veld is verplicht',
            max_digits: 'Maximum veldwaarde overschreden',
            max_whole_digits: 'Maximum veldwaarde overschreden',
            max_value: 'Maximum waarde overschreden',
            invalid: 'Ongeldige waarde',
            reset_code_invalid: 'Ongeldige reset-code',
            password_too_common: 'Wachtwoord is veelvoorkomend',
            invalid_token: 'Ongeldige token',
            existing_invoices: 'Er zijn bestaande facturen met deze instellingen',
            invoice_number_duplicate: 'Er bestaat al een factuur met dit factuurnummer. Het factuurnummer is automatisch gewijzigd en opnieuw opgeslagen.',
            unique: 'is niet uniek',
            unique_together: 'is niet uniek',
            unknown: 'Onbekende fout in veld', // Fallback translation, this should never happen.
            date_mismatch: 'datum komt niet overeen',
            routing_error:
                'Eerste activiteit',
            geo: {
                'failed-error': 'Onbekende fout bij het vinden van de locatie',
                'failed-no-results': 'Locatie kan niet worden gevonden',
                'failed-multiple-results': 'Te veel locaties gevonden',
                'failed-no-housenumber': 'Geen huisnummer gevonden',
                not_precise: 'Locatie gevonden, maar niet exact',
            },
        },
        submitButton: 'Verstuur',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
    },
    docs: {
        button: 'Docs',
        title: 'Documentatie',
        func: 'Functies',
        search: 'Zoeken',
    },

    home: {
        title: 'Dashboard',
        works: 'Opdrachten',
        extras: 'Extra\'s',
        requirements: 'Componenten',
    },
    artwork: {
        detailsModal: {
            title: 'Details ({{project.customer.name}} / {{project.name}})',
        },
        board: {
            title: 'DTP',
            filter: {
                customer: 'Klant',
                taskType: 'Taaksoort',
                hideStatuses: 'Verborgen statussen',
                taskCreator: 'Taak gemaakt door',
            },
            basicInfo: 'Basisinfo',
            comments: 'Opmerkingen',
            column: {
                todo: { label: 'Te doen' },
                in_progress: { label: 'In uitvoering' },
                validation: { label: 'Validatie' },
                on_hold: { label: 'On Hold' },
                done: { label: 'Klaar' },
            },
            label: {
                prepress: 'Prepress',
                design: 'Ontwerp',
                check: 'Check',
                rework: 'Correctie',
            },
            invalidStatusMessage: 'Deze status is niet toegestaan voor dit bestand.',
            groupButton: 'Designs groeperen ({{count}})',
        },
    },
    source: {
        overview: {
            title: 'Bron'
        },
        create: {
            title: 'Bron aanmaken'
        },
        edit: {
            title: 'Source bewerken'
        },
        field: {
            id: {
                label: 'id'
            },
            name: {
                label: 'Naam'
            }
        }
    },
    activeUsers: {
        chat: {
            newMessage: 'Nieuw bericht',
            sendMessage: 'Bericht versturen (CTRL + ENTER)',
            noMessages: 'Nog geen berichten verstuurd.',
            unavailable: '{{name}} is momenteel niet beschikbaar.',
            notRead: 'Nog niet gelezen.',
        },
        search: {
            placeHolder: 'Op naam',
        },
    },
    kvk: {
        field: {
            name: { label: 'Naam' },
            kvkNumber: { label: 'KvK nummer' },
            vatNumber: { label: 'BTW nummer' },
            websites: { label: 'Websites' },
        },
    },
    translation: {
        overview: {
            title: 'Vertalingen',
        },
        field: {
            key: { label: 'Sleutel' },
            textNl: { label: 'Tekst NL' },
            textFr: { label: 'Tekst FR' },
            textDe: { label: 'Tekst DE' },
            textUk: { label: 'Tekst UK' },
        },
    },
    translationOverride: {
        field: {
            translation: { label: 'Vertaling' },
            textNl: { label: 'Tekst NL' },
            textFr: { label: 'Tekst FR' },
            textDe: { label: 'Tekst DE' },
            textUk: { label: 'Tekst UK' },
        },
    },
    dashboard: {
        title: 'Dashboard',
        filter: {
            window: {
                label: 'Datumvenster',
                value: {
                    day: 'Dag',
                    week: 'Week',
                    month: 'Maand',
                    year: 'Jaar',
                    all: 'Alles',
                },
            },
            accountManager: { label: 'Account Manager' },
            customer: { label: 'Klant' },
        },
    },
    dates: {
        month: {
            '0': 'Januari',
            '1': 'Februari',
            '2': 'Maart',
            '3': 'April',
            '4': 'Mei',
            '5': 'Juni',
            '6': 'Juli',
            '7': 'Augustus',
            '8': 'September',
            '9': 'Oktober',
            '10': 'November',
            '11': 'December',
        },
        weekDayShort: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        minDate: {
            placeholder: 'Minimale datum',
        },
        maxDate: {
            placeholder: 'Maximale datum',
        },
    },
    componentAvailability: {
        overview: {
            title: 'Beschikbaarheid componenten',
            window: 'Datumvenster',
        },
    },
    daycy: {
        week: {
            label: 'Week',
            number: '{{weekNumber}}',
        },
        period: {
            pm: 'PM',
            am: 'AM',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    extra: {
        field: {
            nameNl: { label: 'Naam NL' },
            nameUk: { label: 'Naam UK' },
            nameFr: { label: 'Naam FR' },
            nameDe: { label: 'Naam DE' },
            defaultPrice: { label: 'Standaard prijs' },
            ledger: { label: 'Grootboek' },
        },
        overview: {
            title: 'Extra\'s',
        },
        addButton: 'Extra toevoegen',
    },
    partialInvoice: {
        creditButton: 'Crediteer deelfactuur',
        creditConfirm: 'Weet je zeker dat je deze deelfactuur wilt crediteren?',
        reminderButton: 'Factuur versturen',
        field: {
            status: {
                label: 'Status',
                value: {
                    draft: 'Concept',
                    scheduled: 'Gepland',
                    send: 'Versturen',
                    credit: 'Crediteren',
                },
            },
            customerOverride: {
                label: 'Klant',
                warning: 'Deze deelfactuur overschrijft de klant.',
                inherit: 'Overerven van order',
            },
            amount: { label: 'Bedrag' },
            scheduledDate: { label: 'Verzenddatum', },
            exactInvoiceNumber: { label: 'Factuurnummer' },
            creditExactInvoiceNumber: { label: 'Creditfactuurnummer' },
            emailFrom: { label: 'E-mail van' },
            emailTo: { label: 'E-mail naar' },
            emailCc: { label: 'E-mail CC' },
            creditEmailFrom: { label: 'E-mail van' },
            creditEmailTo: { label: 'E-mail naar' },
            creditEmailCc: { label: 'E-mail CC' },
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    chargeActivity: {
        field: {
            price: { label: 'Prijs' },
        },
    },
    startLocation: {
        field: {
            name: { label: 'Naam' },
            default: { label: 'Standaard' },
            location: { label: 'Locatie' },
        },
        create: {
            title: 'Maak startlocatie aan',
        },
        edit: {
            title: 'Bewerk locatie',
        },
        overview: {
            title: 'Startlocaties',
            addButton: 'Locatie toevoegen',
        },
    },
    downPayment: {
        add: {
            title: 'Aanbetaling toevoegen',
        },
        edit: {
            title: 'Aanbetaling bewerken',
            markPaid: 'Betaald',
        },
        view: {
            title: 'Aanbetaling bekijken',
        },
        overview: {
            title: 'Aanbetalingen',
        },
        field: {
            status: {
                label: 'Status',
                value: {
                    draft: 'Concept',
                    sent: 'Verstuurd',
                    paid: 'Betaald',
                },
            },
            emailFrom: { label: 'E-mail van' },
            emailTo: { label: 'E-mail naar' },
            emailCc: { label: 'E-mail CC' },
            emailSubject: { label: 'E-mail onderwerp' },
            emailBody: { label: 'E-mail tekst' },
            salutation: { label: 'Aanhef' },
            greeting: { label: 'Groet' },
            total: { label: 'Totaal' },
            quotation: { label: 'Offerte' },
            amount: { label: 'Amount' },
            exactInvoiceNumber: { label: 'Factuurnummer Exact' },
            sentAt: { label: 'Datum verstuurd' },
            invoicePdfLayout: {
                label: 'Aanbetaling PDF layout',
                value: {
                    project: 'Project layout',
                    original: 'Original layout (simplified)',
                },
            },
        },
    },
    batchStep: {
        addButton: 'Groepeerstap',
    },
    prepaidCanvasSwapActivity: {
        field: {
            quantity: { label: 'Hoeveelheid' },
            balance: { value: '{{balance}}/{{quantity}} over' },
        },
    },
    prepaidMoveActivity: {
        field: {
            quantity: { label: 'Hoeveelheid' },
            balance: { value: '{{balance}}/{{quantity}} over' },
        },
    },
    exportActivity: {
        field: {
            order: { label: 'Order' },
            importActivity: { label: 'Activiteit importeren' },
        },
    },
    prepress: {
        field: {
            assignedTo: { label: 'Toegewezen aan' },
            downloadLink: { label: 'Download Link', },
            priority: { label: 'Prioriteit' },
            status: { label: 'Status' },
            activities: {
                value: '{{count}} activiteit',
                value_plural: '{{count}} activiteiten',
                more: ' and {{count}} meer',
            },
        },
    },
};
